import { Initiative } from '../../api/initiative-client/initiative-client.type';

/**
 * Group custom fields by keys.
 * @param acc - The accumulator object.
 * @param initiative - The initiative to process.
 * @returns Custom fields grouped by keys.
 */
function groupCustomFieldsByKeys(acc: { [key: string]: Set<string> }, initiative: Initiative) {
  if (initiative.custom_fields && Object.keys(initiative.custom_fields).length) {
    Object.entries(initiative.custom_fields).forEach(([key, value]) => {
      if (!acc[key]) {
        acc[key] = new Set();
      }
      acc[key].add(value);
    });
  }
  return acc;
}

/**
 * Filter initiatives by custom field.
 * @param initiatives - The initiatives to filter.
 * @param key - The custom field key.
 * @param value - The custom field value.
 * @returns Filtered initiatives.
 */
function maybeFilterInitiativesByCustomField(initiatives: Initiative[], key: string | null, value: string | null) {
  if (!key || !value) {
    return initiatives;
  }
  return initiatives.filter((initiative) => {
    return (
      initiative.custom_fields &&
      Object.keys(initiative.custom_fields).includes(key) &&
      initiative.custom_fields[key] === value
    );
  });
}

export { groupCustomFieldsByKeys, maybeFilterInitiativesByCustomField };
