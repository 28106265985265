import { Flex, Select, TextInput } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createInitiative, searchInitiatives } from '../../api/initiative-client/initiative-client';
import { useInitiatives } from '../../api/initiative-client/initiative-client.hooks';
import { Initiative, PreSaveInitiative } from '../../api/initiative-client/initiative-client.type';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import { StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { H2 } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { GeneralViewNames } from '../side-bar/side-bar.type';
import { CreateNewInitiativeExplainer } from './create-new-initiative-explainer';
import { InitiativeModal } from './initiative-modal/initiative-modal';
import { InitiativesList } from './initiatives-list/initiatives-list';
import { groupCustomFieldsByKeys, maybeFilterInitiativesByCustomField } from './strategy.helpers';

export function Strategy() {
  useDocumentTitle('Strategy - Bloomfilter');
  const portfolio = useGlobalStore((state) => state.portfolio);
  const [customFieldKeyValue, setCustomFieldKeyValue] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Initiative[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState(location.state?.message || '');
  const ref = useClickOutside(() => {
    setMessage('');
    navigate('.', { replace: true });
  }, ['mouseup', 'touchend']);

  const { query: initiativesQuery, initiatives = [] } = useInitiatives(portfolio?.id, {
    enabled: !!portfolio?.id,
  });

  const searchFn = useCallback(
    (query: string) => {
      if (!query || !portfolio?.id) {
        setSearchResults([]);
        return;
      }
      searchInitiatives(portfolio.id, query)
        .then((results) => setSearchResults(results))
        .catch(() => setSearchResults([]));
    },
    [portfolio?.id, setSearchResults]
  );

  const debouncedSearch = useMemo(() => debounce(searchFn, 200), [searchFn]);

  const createInitiativeMutation = useMutation(
    ['createInitiative', portfolio],
    (initiative: PreSaveInitiative) =>
      portfolio ? createInitiative(portfolio.id, initiative) : Promise.reject('No portfolio selected'),
    {
      onSuccess: () => initiativesQuery.refetch(),
    }
  );

  const hasInitiatives = initiativesQuery.isSuccess && !!initiatives.length;
  const groupedCustomFieldsByKeys = hasInitiatives ? initiatives.reduce(groupCustomFieldsByKeys, {}) : {};

  const header = (
    <Flex justify="space-between" align="center" w="100%">
      <Flex align="center" gap={16}>
        <H2>{GeneralViewNames.Strategy}</H2>
        {hasInitiatives ? (
          <StyledButton
            size={'medium'}
            type={'primary'}
            firstColor={newCOLORS.indigo}
            secondColor={newCOLORS.white}
            disable={false}
            onClick={open}
          >
            Create initiative
          </StyledButton>
        ) : null}
      </Flex>
      <Flex gap={16} align="center">
        <TextInput
          placeholder="Search initiatives..."
          value={searchQuery}
          onChange={(event) => {
            const query = event.currentTarget.value;
            setSearchQuery(query);
            debouncedSearch(query);
          }}
          style={{ width: '300px' }}
        />
        {Object.keys(groupedCustomFieldsByKeys).length ? (
          <CustomFieldFilter
            groupedCustomFieldsByKeys={groupedCustomFieldsByKeys}
            customFieldKeyValue={customFieldKeyValue}
            setCustomFieldKeyValue={setCustomFieldKeyValue}
          />
        ) : null}
      </Flex>
    </Flex>
  );

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay
        visible={initiativesQuery.isFetching || createInitiativeMutation.isLoading}
        variant="colored"
      />
      <div ref={ref}>{message ? <PageHeaderMessage message={message} color="green" /> : null}</div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        {!initiativesQuery.isLoading &&
          portfolio &&
          (hasInitiatives ? (
            <InitiativesList
              initiatives={(() => {
                try {
                  if (searchQuery) {
                    return searchResults;
                  }
                  const { key, value } = JSON.parse(customFieldKeyValue || '{}');
                  return maybeFilterInitiativesByCustomField(initiatives, key, value);
                } catch (e) {
                  return initiatives;
                }
              })()}
              portfolioId={portfolio.id}
            />
          ) : (
            <Flex justify="center" w="100%">
              <CreateNewInitiativeExplainer handleCreateInitiative={open} />
            </Flex>
          ))}
      </div>
      {portfolio && (
        <InitiativeModal
          opened={opened}
          title="Create Initiative"
          portfolioId={portfolio.id}
          handleClose={close}
          handleSubmit={createInitiativeMutation.mutateAsync}
          setMessage={setMessage}
        />
      )}
    </MenuDrivenContainer>
  );
}

export function CustomFieldFilter({
  groupedCustomFieldsByKeys,
  customFieldKeyValue,
  setCustomFieldKeyValue,
}: {
  groupedCustomFieldsByKeys: { [key: string]: Set<string> };
  customFieldKeyValue: string | null;
  setCustomFieldKeyValue: (v: string | null) => void;
}) {
  return (
    <Select
      placeholder="Filter by additional fields"
      value={customFieldKeyValue}
      clearable
      onChange={(v: string | null) => {
        setCustomFieldKeyValue(v);
      }}
      data={Object.entries(groupedCustomFieldsByKeys).map(([key, values]) => ({
        group: key,
        items: Array.from(values).map((value) => ({
          label: value,
          value: JSON.stringify({ key, value }),
        })),
      }))}
      aria-label="Filter initiatives by custom fields"
      w={200}
    />
  );
}
