import { Group, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { inkLight, primaryBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { H3, Text } from '../../../ui-library/typography/typography';
import { Layout } from '../layout';

export const CreatePasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Stack gap={32}>
        <Group gap={16}>
          <Icon name="check_circle" size={32} color={primaryBase} variant="filled" />
          <H3>Password Updated Successfully!</H3>
        </Group>
        <Text>Your new password is now active. You can use it to log in.</Text>
        <Divider />
        <Button
          variant="outline"
          onClick={() => navigate('/')}
          w="100%"
          radius="xl"
          rightSection={<Icon name="login" size={18} color={inkLight} />}
        >
          Log in to Bloomfilter
        </Button>
      </Stack>
    </Layout>
  );
};
