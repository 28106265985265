import { Divider } from '@mantine/core';
import { useContext } from 'react';
import { SidebarContext } from '../../containers/side-bar/side-bar.context';
import { DropShadowCard } from '../drop-shadow-card/drop-shadow-card';
import { HealthGauge, HealthGaugeProps } from '../health-gauge/health-gauge';
import { IssueList, IssueListProps } from '../issue-list-card/issue-list-card';

export type HealthGaugeCardProps = HealthGaugeProps & IssueListProps & { context: 'portfolio' | 'project' | 'sprint' };

export function HealthGaugeCard({
  heading,
  tooltip,
  health,
  shouldAnimate,
  status = 'loading',
  delay,
  issues,
  ratio = 1,
}: HealthGaugeCardProps) {
  const { viewAreaWidth } = useContext(SidebarContext);

  return (
    <DropShadowCard style={{ padding: 0 }}>
      <div style={{ display: 'flex', flexDirection: viewAreaWidth >= 992 ? 'row' : 'column' }}>
        <span style={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: 400, padding: '1.5em' }}>
          <HealthGauge
            heading={heading}
            tooltip={tooltip}
            health={health}
            shouldAnimate={shouldAnimate}
            shouldFade={true}
            status={status}
            delay={delay}
            ratio={ratio}
          />
        </span>
        <Divider
          orientation={viewAreaWidth >= 992 ? 'vertical' : 'horizontal'}
          size={issues?.length ? 3 : 0}
          style={{ margin: viewAreaWidth >= 992 ? '1em 0' : '0 1em' }}
        />
        {issues?.length ? (
          <span
            style={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: 'max-content', padding: '1.5em' }}
          >
            <span style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
              <IssueList issues={issues} maxToDisplay={10} />
            </span>
          </span>
        ) : null}
      </div>
    </DropShadowCard>
  );
}
