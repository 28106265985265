import { Group, Stack } from '@mantine/core';
import { primaryBase } from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { H3, Text } from '../../../ui-library/typography/typography';
import { Layout } from '../layout';

export const ResetPasswordSuccess = () => {
  return (
    <Layout>
      <Stack gap={32}>
        <Group gap={16}>
          <Icon name="check_circle" size={32} color={primaryBase} variant="filled" />
          <H3>Check Your Email</H3>
        </Group>
        <Text>
          We've sent instructions to your email on how to reset your password. Please check your inbox (and spam folder)
          for further steps.
          <br />
          <br />
          You can safely close this page.
        </Text>
      </Stack>
    </Layout>
  );
};
