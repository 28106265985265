import { styled } from '@linaria/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { bulkUpsertSpending, getSpending } from '../../api/financials-client/financials-client';
import { Spending } from '../../api/financials-client/financials-client.type';
import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { fetchPortfolioProjects } from '../../api/summary-client/summary-client';
import { EditFinancialDataForm } from '../../components/edit-financial-data/edit-financial-data-form';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { TeamContext } from '../../contexts';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { newCOLORS } from '../../styles/colors';
import { StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { H2 } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const AddEditFinancials = () => {
  useDocumentTitle('Financials - Bloomfilter');
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const [projects, setProjects] = useState([] as ProjectsResponse[]);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const [spending, setSpending] = useState<Spending[]>([]);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const { team, setTeam } = useContext(TeamContext);
  const [initialTeam] = useState(team);

  const getSpendingQuery = useQuery(
    ['getSpending', portfolioId, year],
    () => getSpending(portfolioId as string, year.toString()),
    {
      onSuccess: (data: Spending[]) => {
        setSpending(data);
      },
    }
  );

  useEffect(() => {
    if (spending && spending.length > 0 && spending !== getSpendingQuery.data) {
      setTeam(null);
      setEnableSave(true);
    }
  }, [getSpendingQuery.data, spending, setTeam]);

  const bulkUpsertSpendingQuery = useMutation(
    ['bulkUpsertSpending'],
    (postBody: { spending: Spending[] }) => bulkUpsertSpending(postBody.spending),
    {
      onSuccess: () => {
        let route = `/application/financials/portfolio/${portfolioId}`;
        const nextTeam = getNextContextTeam();
        if (nextTeam) {
          route += `/team/${nextTeam.id}`;
        }
        navigate(`${route}`);
      },
    }
  );

  const getNextContextTeam = useCallback((): ProjectsResponse | null => {
    const existingTeamIds = (getSpendingQuery.data as Spending[]).map((datum) => datum.project_id);
    const newTeamIds = spending.map((datum) => datum.project_id);
    const addedTeams = new Array(...new Set(newTeamIds.filter((id) => !existingTeamIds.includes(id))));
    let addedTeam = null;
    if (addedTeams.length === 1) {
      addedTeam = projects.find((project) => project.id === addedTeams[0]);
    }
    return addedTeam || null;
  }, [getSpendingQuery.data, projects, spending]);

  const getProjectsQuery = useQuery(
    ['fetchPortfolioProjects', portfolioId],
    () => fetchPortfolioProjects(portfolioId as string),
    {
      enabled: portfolioId !== '',
      onSuccess: setProjects,
    }
  );

  const handleCancel = () => {
    setTeam(null);
    let route = `/application/financials/portfolio/${portfolioId}`;
    if (
      initialTeam &&
      getSpendingQuery.data &&
      getSpendingQuery.data.some((item) => item.project_id === initialTeam.id)
    ) {
      route += `/team/${initialTeam.id}`;
    }
    navigate(`${route}`);
  };

  const shouldShowMessage = (): boolean => {
    // show the message when a team is selected in the context
    // and we do not have spending data for that team
    if (
      team &&
      team.id !== 'aggregate' &&
      !getSpendingQuery.isLoading &&
      !spending.some((datum) => datum.project_id === team.id && datum.budget)
    ) {
      return true;
    }
    return false;
  };

  const getMessageText = (): string => {
    if (team && team.id !== 'aggregate') {
      return `The ${team.name} team does not have financial data created. Please enter the monthly budgets below to continue.`;
    }
    return '';
  };

  const header = (
    <FinancialsHeader>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Fragment>
          <H2>{!getSpendingQuery.isLoading && spending.length > 0 ? 'Edit' : 'Add'} Financial Data</H2>
          <StyledButton
            size={'medium'}
            type={'primary'}
            firstColor={newCOLORS.indigo}
            secondColor={newCOLORS.white}
            disable={!enableSave}
            style={{ marginLeft: 16 }}
            onClick={() => enableSave && bulkUpsertSpendingQuery.mutate({ spending })}
          >
            Save
          </StyledButton>
          <StyledButton
            size={'medium'}
            type={'secondary'}
            firstColor={newCOLORS.indigo}
            secondColor={newCOLORS.white}
            disable={false}
            style={{ marginLeft: 16 }}
            onClick={handleCancel}
          >
            Cancel
          </StyledButton>
        </Fragment>
      </div>
    </FinancialsHeader>
  );

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay
        visible={getSpendingQuery.isLoading || getProjectsQuery.isLoading}
        transitionDuration={3}
        variant="colored"
      />
      <FinancialsContainer>
        {shouldShowMessage() ? <PageHeaderMessage message={getMessageText()} color="red"></PageHeaderMessage> : null}
        <EditFinancialDataForm
          key={`pf-${portfolioId}-financials`}
          spending={spending}
          setSpending={setSpending}
          persistedSpending={getSpendingQuery.data || []}
          projects={projects}
          year={year}
          setYear={setYear}
        />
      </FinancialsContainer>
    </MenuDrivenContainer>
  );
};

const FinancialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FinancialsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
