import { styled } from '@linaria/react';
import { Divider, PasswordInput, Stack, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { HEIGHT_BREAKPOINTS } from '../../../../styles/breakpoints';
import { CreateAccountForm as CreateAccountFormType } from './create-account.type';

type Props = {
  form: UseFormReturnType<CreateAccountFormType>;
};

export const CreateAccountForm = ({ form }: Props) => (
  <FormStack role="form" aria-label="Create Account Form">
    <Divider />
    <StyledInput
      label="Email"
      placeholder="Enter your email address"
      value={form.values.email}
      onChange={(event) => form.setFieldValue('email', event.currentTarget.value.trim())}
      error={form.errors.email}
      w="100%"
      autoComplete="email"
      required
    />
    <StyledInput
      label="Name"
      placeholder="Enter your full name"
      value={form.values.name}
      onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
      error={form.errors.name}
      w="100%"
      required
    />
    <StyledPasswordInput
      label="Password"
      placeholder="Enter your password"
      value={form.values.password}
      onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
      error={form.errors.password}
      w="100%"
      autoComplete="new-password"
      required
    />
    <StyledPasswordInput
      label="Confirm Password"
      placeholder="Confirm your password"
      value={form.values.confirmPassword}
      onChange={(event) => form.setFieldValue('confirmPassword', event.currentTarget.value)}
      error={form.errors.confirmPassword}
      w="100%"
      autoComplete="new-password"
      required
    />
  </FormStack>
);

const FormStack = styled(Stack)`
  gap: 24px;

  @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
    gap: 16px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    gap: 12px;
  }
`;

const baseInputStyles = `
  input {
    @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
      font-size: 14px;
      height: 36px;
    }

    @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
      font-size: 13px;
      height: 32px;
    }
  }

  label {
    @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
      font-size: 13px;
      margin-bottom: 4px;
    }
  }
`;

const StyledInput = styled(TextInput)`
  ${baseInputStyles}
`;

const StyledPasswordInput = styled(PasswordInput)`
  ${baseInputStyles}
`;
