import { useState } from 'react';
import { CreatePasswordForm } from './create-password-form';
import { CreatePasswordSuccess } from './create-password-success';
import { ResetPasswordView } from './reset-password.type';

export const CreatePassword = () => {
  const [view, setView] = useState<ResetPasswordView>(ResetPasswordView.Form);

  if (view === ResetPasswordView.Success) {
    return <CreatePasswordSuccess />;
  }

  return <CreatePasswordForm setView={setView} />;
};
