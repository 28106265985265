import { Divider, PasswordInput, Stack, TextInput } from '@mantine/core';
import { LoginForm as LoginFormType } from './login.type';

type Props = {
  form: LoginFormType;
  showPassword: boolean;
};

export const LoginForm = ({ form, showPassword }: Props) => (
  <Stack gap={24} role="form" aria-label="Login Form">
    <Divider />
    <TextInput
      label="Email"
      placeholder="Enter your email address"
      value={form.values.email}
      onChange={(event) => form.setFieldValue('email', event.currentTarget.value.trim())}
      error={form.errors.email}
      w="100%"
      autoComplete="email"
      required
    />
    {showPassword && (
      <PasswordInput
        label="Password"
        placeholder="Enter your password"
        value={form.values.password}
        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
        error={form.errors.password}
        w="100%"
        autoComplete="current-password"
        required
      />
    )}
  </Stack>
);
