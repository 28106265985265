import { useUIStore } from './ui-store';

/**
 * Sets isHoveredLogo to true or false in the UI store.
 *
 * @param {boolean} isHoveredLogo - Whether the logo is currently hovered
 * @return {void}
 */
const setIsHoveredLogo = (isHoveredLogo: boolean): void => {
  useUIStore.setState({ isHoveredLogo }, false, '[UI Store] setIsHoveredLogo');
};

export { setIsHoveredLogo };
