import { styled } from '@linaria/react';
import { QueryObserverResult } from '@tanstack/react-query';
import { Fragment, useContext } from 'react';
import { IntegrationType } from '../../api/integrations-client/integrations-client.type';
import { Project } from '../../api/projects-client/projects-client.type';
import { Sprint, SprintAssessmentData, SprintBurnsAndFlows } from '../../api/sprints-client/sprints-client.type';
import { DataFallbackMessage } from '../../components/data-fallback-message/data-fallback-message';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import { SidebarContext } from '../side-bar/side-bar.context';
import { FlowOfWorkByPhase } from './flow-of-work-by-phase/flow-of-work-by-phase';
import { FlowOfWorkToDeliveryConfidence } from './flow-of-work-delivery-confidence/flow-of-work-delivery-confidence';
import { FlowOfWorkToPaceNeeded } from './flow-of-work-to-pace-needed/flow-of-work-to-pace-needed';
import { ProcessPillarsContainer } from './process-pillars-container';
import { SprintGoalContainer } from './sprint-goal-container';
import { SprintPerformanceContainer } from './sprint-performance-container';
import { SprintReviewContainer } from './sprint-review-container';
import { SprintSummaryContainer } from './sprint-summary-container';

type Props = {
  sprint: Sprint | null;
  sprintQuery: QueryObserverResult<Sprint>;
  project: Project | null;
  assessmentData?: SprintAssessmentData | null;
  isLoadingAssessmentData?: boolean;
};

export const SprintAssessmentContent = ({
  sprint,
  sprintQuery,
  project,
  assessmentData,
  isLoadingAssessmentData,
}: Props) => {
  const { viewAreaWidth } = useContext(SidebarContext);
  const portfolio = useGlobalStore((state) => state.portfolio);

  return (
    <Fragment>
      {sprint && project ? (
        <Fragment>
          {sprint?.oversized ? (
            <DataFallbackMessage
              text={
                "This Sprint is over 60 days long, and can't be displayed in our Sprint Assessment page. \nContact Bloomfilter Customer Success to learn more. "
              }
            />
          ) : (
            <Fragment>
              {assessmentData?.metrics && (
                <SprintInfoContainer>
                  {project.integration_type !== IntegrationType.ADO ? <SprintGoalContainer sprint={sprint} /> : null}
                  {portfolio?.organization.allow_llm_use ? (
                    <SprintSummaryContainer sprintId={sprint?.id} sprint={sprint} />
                  ) : null}
                  <SprintReviewContainer sprint={sprint} />
                  <SprintOverViewBox viewAreaWidth={viewAreaWidth}>
                    <SprintPerformanceContainer
                      health={sprint.health}
                      status={sprintQuery.isFetching ? 'loading' : sprintQuery.status}
                    />

                    <ProcessPillarsContainer
                      viewAreaWidth={viewAreaWidth}
                      sprint={sprint}
                      project={project}
                      sprintMetrics={assessmentData?.metrics}
                    />
                  </SprintOverViewBox>
                  {assessmentData?.burns ? (
                    <FlowOfWorkByPhase
                      sprint={sprint}
                      sprintBurnsAndFlows={assessmentData?.burns as SprintBurnsAndFlows}
                      projections={assessmentData?.projections}
                      projectionsLoading={isLoadingAssessmentData}
                      sprintMetrics={assessmentData?.metrics}
                    />
                  ) : null}

                  {assessmentData?.burns ? (
                    <FlowOfWorkToPaceNeeded
                      key={sprint.id}
                      sprint={sprint}
                      sprintBurnsAndFlows={assessmentData?.burns as SprintBurnsAndFlows}
                      projections={assessmentData?.projections}
                      projectionsLoading={isLoadingAssessmentData}
                    />
                  ) : null}
                  {assessmentData?.burns ? (
                    <FlowOfWorkToDeliveryConfidence
                      sprint={sprint}
                      sprintBurnsAndFlows={assessmentData?.burns as SprintBurnsAndFlows}
                    />
                  ) : null}
                </SprintInfoContainer>
              )}
            </Fragment>
          )}{' '}
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const SprintInfoContainer = styled.div`
  > div {
    margin-bottom: 16px;
  }
`;

const SprintOverViewBox = styled.div<{ viewAreaWidth: number }>`
  background-color: ${newCOLORS.white};
  padding: 16px 32px;
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 1400 ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${(props) => (props.viewAreaWidth >= 1400 ? 'flex-start' : 'center')};
`;
