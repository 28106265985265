import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../../constants';
import { getRequestToken } from '../../../helpers/storage/storage';
import { useAuthStore } from '../../../store/auth-store/auth-store';
import { SignInStep } from '../../../store/auth-store/auth-store.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { Login } from './login/login';
import { SelectOrganization } from './select-organization/select-organization';

export const SignIn = () => {
  const navigate = useNavigate();
  const step = useAuthStore((state) => state.signInStep);
  const organization = useGlobalStore((state) => state.organization);
  const isAuthenticated = Boolean(getRequestToken());

  useEffect(() => {
    let mounted = true;

    if (isAuthenticated && organization && step !== SignInStep.SelectOrganization) {
      if (mounted) {
        navigate(DEFAULT_ROUTE, { replace: true });
      }
    }

    return () => {
      mounted = false;
    };
  }, [navigate, isAuthenticated, organization, step]);

  switch (step) {
    case SignInStep.Login:
      return <Login />;
    case SignInStep.SelectOrganization:
      return <SelectOrganization />;
    default:
      return null;
  }
};
