import { initialState, useAuthStore } from './auth-store';
import { SignInStep, SignUpStep } from './auth-store.type';

/**
 * Sets the sign up initialization state in the Auth Store.
 *
 * @param {boolean} signUpInitialized - Whether the sign up flow has been initialized
 * @return {void}
 */
const setSignUpInitialized = (signUpInitialized: boolean): void => {
  useAuthStore.setState({ signUpInitialized }, false, '[Auth Store] setSignUpInitialized');
};

/**
 * Sets the step in the Sign Up flow.
 *
 * @param {Step} step - The step to set in the Sign Up flow.
 * @return {void}
 */
const setSignUpStep = (step: SignUpStep): void => {
  useAuthStore.setState({ signUpStep: step }, false, '[Auth Store] setSignUpStep');
};

/**
 * Sets the step in the Sign In flow.
 *
 * @param {SignInStep} step - The step to set in the Sign In flow.
 * @return {void}
 */
const setSignInStep = (step: SignInStep): void => {
  useAuthStore.setState({ signInStep: step }, false, '[Auth Store] setSignInStep');
};

/**
 * Sets the email and password credentials in the Auth Store.
 *
 * @param {string} email - The user's email address
 * @param {string} password - The user's password
 * @return {void}
 */
const setCredentials = (email: string, password: string): void => {
  useAuthStore.setState({ email, password }, false, '[Auth Store] setCredentials');
};

/**
 * Sets the organization name in the Auth Store.
 *
 * @param {string} organizationName - The organization name
 * @return {void}
 */
const setOrganizationName = (organizationName: string): void => {
  useAuthStore.setState({ organizationName }, false, '[Auth Store] setOrganizationName');
};

/**
 * Resets the Auth Store.
 *
 * @return {void}
 */
const resetAuthStore = (): void => {
  useAuthStore.setState({ ...initialState }, false, '[Auth Store] reset');
};

export { resetAuthStore, setCredentials, setOrganizationName, setSignInStep, setSignUpInitialized, setSignUpStep };
