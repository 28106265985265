import { QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getAllocationOfCostsTaskDetails,
  getCostByInitiativeTaskDetails,
  getCostVsOutputTaskDetails,
} from '../../api/financials-client/financials-client';
import { MinifiedTask } from '../../api/tasks-client/task-client.type';
import { FinancialsTasksScope } from './financials-tasks.type';

/**
 * Query manager for fetching cost vs output financials tasks.
 *
 * @param {FinancialsTasksConfig} config - Configuration object for fetching financials tasks
 * @param {UseQueryOptions<MinifiedTask[]>} options - Options for the useQuery hook
 * @return {{ data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> }} Object containing data and query result
 */
const useCostVsOutputTasks = (
  { portfolioId, projectId, date, factor, startDate, endDate }: FinancialsTasksScope,
  options?: UseQueryOptions<MinifiedTask[]>
): { data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> } => {
  const query = useQuery({
    queryKey: ['financials-tasks', portfolioId, projectId, date, factor, startDate, endDate],
    queryFn: () =>
      portfolioId && startDate && endDate && date && factor
        ? getCostVsOutputTaskDetails(portfolioId, startDate, endDate, date, factor, projectId || undefined)
        : Promise.reject('Cannot get cost vs output tasks'),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching allocation of costs financials tasks.
 *
 * @param {FinancialsTasksScope} config - Configuration object for fetching financials tasks
 * @param {UseQueryOptions<MinifiedTask[]>} options - Options for the useQuery hook
 * @return {{ data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> }} Object containing data and query result
 */
const useAllocationOfCostsTasks = (
  { portfolioId, projectId, date, factor, factorOption, startDate, endDate }: FinancialsTasksScope,
  options?: UseQueryOptions<MinifiedTask[]>
): { data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> } => {
  const query = useQuery({
    queryKey: ['financials-tasks', portfolioId, projectId, date, factor, factorOption, startDate, endDate],
    queryFn: () =>
      portfolioId && startDate && endDate && date && factor && factorOption
        ? getAllocationOfCostsTaskDetails(
            portfolioId,
            startDate,
            endDate,
            date,
            factor,
            factorOption,
            projectId || undefined
          )
        : Promise.reject('Cannot get cost vs output tasks'),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching cost by initiative financials tasks.
 *
 * @param {FinancialsTasksScope} config - Configuration object for fetching financials tasks
 * @param {UseQueryOptions<MinifiedTask[]>} options - Options for the useQuery hook
 * @return {{ data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> }} Object containing data and query result
 */

const useCostByInitiativeTasks = (
  { portfolioId, projectId, factor, startDate, endDate }: FinancialsTasksScope,
  options?: UseQueryOptions<MinifiedTask[]>
): { data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> } => {
  const query = useQuery({
    queryKey: ['financials-tasks', portfolioId, projectId, factor, startDate, endDate],
    queryFn: () =>
      portfolioId && startDate && endDate && factor
        ? getCostByInitiativeTaskDetails(portfolioId, startDate, endDate, factor, projectId || undefined)
        : Promise.reject('Cannot get cost by initiative tasks'),
    ...options,
  });

  return { data: query.data, query };
};

export { useAllocationOfCostsTasks, useCostByInitiativeTasks, useCostVsOutputTasks };
