import * as Sentry from '@sentry/browser';
import { QueryObserverResult } from '@tanstack/react-query';
import { useContext, useEffect, useRef } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { useCheckDomain, useLogin, useRefreshToken } from '../../../../api/auth-client/auth-client.hooks';
import { BackendError, LoginMutation, LoginResponse } from '../../../../api/auth-client/auth-client.type';
import { useUser } from '../../../../api/user-client/user-client.hooks';
import { UserInfo } from '../../../../api/user-client/user-client.type';
import { DEFAULT_ROUTE } from '../../../../constants';
import { UserContext } from '../../../../contexts';
import { getEmailDomain } from '../../../../helpers';
import {
  clearAuthenticationStore,
  getRefreshToken,
  refreshAuthenticationStore,
  storeAuthenticationObject,
} from '../../../../helpers/storage/storage';
import {
  setCredentials,
  setSignInStep,
  setSignUpInitialized,
  setSignUpStep,
} from '../../../../store/auth-store/auth-store.actions';
import { SignInStep, SignUpStep } from '../../../../store/auth-store/auth-store.type';
import { ValidationError } from '../../sign-up/sign-up.type';
import { LoginForm } from './login.type';

/**
 * Hook to handle authentication token refresh flow
 *
 * This hook manages the token refresh process by:
 * 1. Getting the current refresh token
 * 2. Attempting to refresh it if present
 * 3. Storing new tokens and calling onSignedIn callback on success
 * 4. Clearing stored tokens on error
 *
 * @param {Function} onSignedIn - Callback function to execute when token refresh is successful
 * @returns {void}
 */
const useAuthTokenRefresh = (onSignedIn: () => void): void => {
  const token = getRefreshToken();
  const tokenProcessedRef = useRef(false);

  const { data: tokenData, error } = useRefreshToken(token || '', { enabled: !!token });

  useEffect(() => {
    if (tokenData && !tokenProcessedRef.current) {
      refreshAuthenticationStore(tokenData.access, tokenData.refresh);
      onSignedIn();
      tokenProcessedRef.current = true;
    }
  }, [tokenData, onSignedIn]);

  useEffect(() => {
    if (error) {
      clearAuthenticationStore();
    }
  }, [error]);
};

/**
 * Hook to handle authentication parameters from URL search params
 *
 * This hook manages the OAuth authentication flow by:
 * 1. Checking URL search params for refresh token, access token and errors
 * 2. Handling error cases by calling provided error callback
 * 3. On successful auth, storing tokens and redirecting to dashboard
 * 4. Cleaning up OAuth-related URL params
 *
 * @param {Function} onError - Callback function to handle authentication errors
 * @returns {void}
 */
const useAuthParams = (onError: (message: string) => void): void => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const refresh = searchParams.get('refresh');
    const access = searchParams.get('access');
    const error = searchParams.get('error');

    if (error) {
      const errorMessage =
        error === 'invalid_response' ? ValidationError.SSOInvalidResponse : ValidationError.SSOInvalidAuthentication;
      onError(errorMessage);
    } else if (refresh && access) {
      storeAuthenticationObject({ access, refresh });

      searchParams.delete('code');
      searchParams.delete('state');
      searchParams.delete('refresh');
      searchParams.delete('access');
      setSearchParams(searchParams);

      navigate(DEFAULT_ROUTE);
    }
  }, [searchParams, setSearchParams, navigate, onError]);
};

/**
 * Hook to check domain authentication method and handle SSO redirects
 *
 * This hook:
 * 1. Checks the authentication method configured for the email domain
 * 2. If SSO is configured, redirects to the SSO provider URL
 * 3. If password auth is configured, calls the provided callback
 *
 * @param {Object} params - Hook parameters
 * @param {string} params.email - User's email address to check domain configuration
 * @param {Function} params.onPasswordAuth - Callback function called when password authentication is required
 * @returns {Object} Object containing checkDomainRefetch function to trigger domain check
 */
const useAuthCheckDomain = ({ email, onPasswordAuth }: { email: string; onPasswordAuth: () => void }) => {
  const { data: checkDomainData, refetch: checkDomainRefetch } = useCheckDomain(getEmailDomain(email), {
    enabled: false,
  });

  useEffect(() => {
    if (checkDomainData) {
      const { url, authentication_method } = checkDomainData;

      if (url && url !== '' && authentication_method !== 'password') {
        window.location.href = url;
      } else {
        onPasswordAuth();
      }
    }
  }, [checkDomainData, onPasswordAuth]);

  return { checkDomainRefetch };
};

/**
 * Hook to handle login mutation and post-login actions
 *
 * This hook:
 * 1. Manages the login mutation and its success/error states
 * 2. On successful login, fetches user info and organization data
 * 3. Sets up Sentry context with user information
 * 4. Handles navigation based on number of available organizations
 * 5. Handles form error states for invalid credentials
 *
 * @param {LoginForm} form - The login form instance to handle error states
 * @returns {{ loginMutation: LoginMutation }} Object containing the login mutation
 */
const useLoginMutation = (form: LoginForm): { loginMutation: LoginMutation } => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const { query: userInfoQuery } = useUser({ enabled: false });
  const loginMutation = useLogin({
    onSuccess: async (data) => {
      if (data.access) {
        await handleLoginSuccess(userInfoQuery, navigate, setUser);
      } else {
        handleLoginFailure(data, form, navigate);
      }
    },
    onError: ({ error }: { error: { detail: string } }) => {
      form.setFieldError('email', error.detail);
      Sentry.captureException(error);
    },
  });

  return { loginMutation };
};

/**
 * Handles login failure scenarios by processing error responses and updating form errors
 *
 * @param {LoginResponse} data - The login response data containing error details
 * @param {LoginForm} form - The login form instance to set field errors on
 * @param {NavigateFunction} navigate - React Router navigation function for redirects
 * @returns {void}
 */
const handleLoginFailure = (data: LoginResponse, form: LoginForm, navigate: NavigateFunction): void => {
  if (data.detail === BackendError.OTPNotVerified) {
    handleOTPNotVerified(form, navigate);
  } else if (data.detail === BackendError.AuthenticationFailed || data.detail === BackendError.InvalidCredentials) {
    form.setFieldError('email', ValidationError.InvalidCredentials);
  } else {
    // Handle email errors
    if (data.email?.length) {
      form.setFieldError('email', data.email[data.email.length - 1] || BackendError.UnexpectedError);
    }
    // Handle password errors
    if (data.password?.length) {
      form.setFieldError('password', data.password[data.password.length - 1] || BackendError.UnexpectedError);
    }
    // Set a generic error if no specific errors found
    if (!data.email?.length && !data.password?.length) {
      form.setFieldError('email', BackendError.UnexpectedError);
    }
  }
};

/**
 * Handles successful login by fetching user info and managing organization navigation
 *
 * @param {QueryObserverResult<UserInfo>} userInfoQuery - Query to fetch user information
 * @param {NavigateFunction} navigate - React Router navigation function
 * @param {Function} setUser - Function to set user information
 * @returns {Promise<void>} Promise that resolves when login success is handled
 * @throws {Error} If there is an error fetching user data
 */
const handleLoginSuccess = async (
  userInfoQuery: QueryObserverResult<UserInfo>,
  navigate: NavigateFunction,
  setUser: (user: UserInfo) => void
): Promise<void> => {
  try {
    const { data: user } = await userInfoQuery.refetch();

    if (user) {
      trackUser(user);
      setUser(user);
      handleUserOnboarding(user, navigate);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

/**
 * Handles OTP not verified error by setting credentials and navigating to sign-up
 *
 * @param {LoginForm} form - The login form instance to set credentials
 * @param {NavigateFunction} navigate - React Router navigation function
 * @returns {void}
 */
const handleOTPNotVerified = (form: LoginForm, navigate: NavigateFunction): void => {
  setCredentials(form.values.email, form.values.password);
  setSignUpStep(SignUpStep.CheckYourEmail);
  navigate('/sign-up', { replace: true });
};

/**
 * Handles navigation and state management based on user's organization membership
 *
 * Algorithm:
 * 1. Check if user has accepted terms and conditions
 *    - If not: Set signup step to terms and conditions and redirect to signup
 * 2. Check if user has any organizations
 *    - If no organizations: Set signup step to create org and redirect to signup
 * 3. Check if user has multiple organizations
 *    - If multiple orgs: Set signin step to org selection and redirect to signin
 * 4. Default case (single organization)
 *    - Reset auth store and navigate to default route
 *
 * @param {UserInfo} user - The user information including organizations
 * @param {NavigateFunction} navigate - React Router navigation function
 * @returns {void}
 */
const handleUserOnboarding = (user: UserInfo, navigate: NavigateFunction): void => {
  if (!user.terms_and_conditions_accepted) {
    completeOnboarding(SignUpStep.TermsAndConditions, navigate);
  } else if (!user.organizations?.length) {
    completeOnboarding(SignUpStep.CreateOrganization, navigate);
  } else if (user.organizations.length > 1) {
    setSignInStep(SignInStep.SelectOrganization);
  } else {
    navigate(DEFAULT_ROUTE);
  }
};

/**
 * Completes the onboarding process by setting the signup step, initializing signup,
 * and navigating to the signup page
 *
 * @param {SignUpStep} step - The signup step to set
 * @param {NavigateFunction} navigate - React Router navigation function
 * @returns {void}
 */
const completeOnboarding = (step: SignUpStep, navigate: NavigateFunction): void => {
  setSignUpStep(step);
  setSignUpInitialized(true);
  navigate('/sign-up', { replace: true });
};

/**
 * Sets user information in Sentry for error tracking and monitoring
 *
 * @param {UserInfo} user - The user information object to track
 * @returns {void}
 */
const trackUser = (user: UserInfo): void => {
  Sentry.setUser(user);
  Sentry.setContext('user', user);
};

export { useAuthCheckDomain, useAuthParams, useAuthTokenRefresh, useLoginMutation };
