import { Divider, Group, Stack } from '@mantine/core';
import { OrganizationResponse } from '../../../../api/organization-client/organization-client.type';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';

type Props = {
  organizations: OrganizationResponse[];
  onSelect: (organizationId: string) => void;
};

export const OrganizationList = ({ organizations, onSelect }: Props) => {
  return (
    <Stack gap={24}>
      <Divider />
      {organizations?.map((org) => (
        <Stack key={org.id}>
          <Group justify="space-between">
            <Group gap={16}>
              <Icon name="domain" size={24} />
              <Text lineHeight="none" weight="bold">
                {org.name}
              </Text>
            </Group>
            <Button onClick={() => onSelect(org.id)} radius="xl">
              Select
            </Button>
          </Group>
          <Divider />
        </Stack>
      ))}
    </Stack>
  );
};
