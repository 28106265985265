import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProjectSprints } from '../../api/projects-client/projects-client';
import {
  ProjectSprintsResponse,
  Subproject,
  SubprojectMethodology,
} from '../../api/projects-client/projects-client.type';
import { SprintSummary } from '../../api/sprints-client/sprints-client.type';
import { DataFallbackMessage } from '../../components/data-fallback-message/data-fallback-message';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { SprintHistoryListItem } from '../../components/sprint-history-list-item/sprint-history-list-item';
import { SubprojectSelector } from '../../components/subproject-selector/subproject-selector';
import { ProjectContext, UserContext } from '../../contexts';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsDimensionsProps, AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { getSelectedSubprojectId } from '../../helpers/storage/storage';
import { newCOLORS } from '../../styles/colors';
import { TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const SprintHistory = () => {
  const { projectId = '' } = useParams<{ projectId: string }>();
  const { user } = useContext(UserContext);
  const { project, subprojects } = useContext(ProjectContext);
  const [selectedSubproject, setSelectedSubproject] = useState<Subproject>();
  const [sprintSummaries, setSprintSummaries] = useState<SprintSummary[]>([]);

  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent(
      AnalyticsEventType.ProjectHistoryViewed,
      project ? { userContext: user, project: project } : { userContext: user }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subprojects) {
      setSelectedSubproject(subprojects.find((x: Subproject) => x.id == getSelectedSubprojectId()) ?? subprojects[0]);
    }
  }, [subprojects]);

  const queryResponse = useQuery(
    ['projectSprints', project?.id],
    project && project?.id
      ? () => fetchProjectSprints(projectId)
      : () => Promise.reject('Could not fetch project sprints, no project selected'),
    {
      enabled: !!project && !!project?.id && !sprintSummaries.length,
      onSuccess: (data: ProjectSprintsResponse) => {
        const summaries: SprintSummary[] = data?.sprints ? data.sprints : [];
        setSprintSummaries(summaries);
      },
      onError: ({ error }: { error: any }) => {
        return Promise.reject(error?.response?.data || 'Error while fetching project sprints');
      },
    }
  );

  const fallbackMessage = queryResponse.isLoading
    ? 'Loading sprints...'
    : "This subproject doesn't have any sprints yet.";

  /** If any of the above api call fails the corresponding state objects are set to their default so that
   *  the entire page doesn't blow up. The below lines are a secondary check to ensure that incase of any
   *  uncaught failure we direct them blank page (Probably some error page in future) for now.
   */
  if (!project) {
    return <MenuDrivenContainer />;
  }

  const summaryClicked = (summary: SprintSummary) => {
    const props: AnalyticsDimensionsProps = { userContext: user, project: project };
    if (selectedSubproject?.methodology === SubprojectMethodology.Scrum) {
      trackEvent(AnalyticsEventType.HistoricalSprintTapped, props);
      navigate(`/application/project/${projectId}/sprint-assessment/${summary.id}`, {
        state: { selectedMenuItem: 'History', from: 'History' },
      });
    } else if (selectedSubproject?.methodology === SubprojectMethodology.Kanban) {
      trackEvent(AnalyticsEventType.HistoricalWIPTapped, props);
      navigate(`/application/project/${summary.id}/wip/`, {
        state: { project: project, selectedMenuItem: 'History' },
      });
    }
  };

  let subtitle = 'Historical Trends';
  if (selectedSubproject?.methodology === SubprojectMethodology.Kanban) {
    subtitle = 'Select a historical reporting interval from the list below to see WIP details';
  }

  const displayConcurrentSprintMessage = selectedSubproject?.concurrent_sprint_flag;

  return (
    <MenuDrivenContainer>
      <div ref={ref}>
        {displayConcurrentSprintMessage && (
          <PageHeaderMessage
            message="There is more than one sprint open for this team. Bloomfilter will not display information for concurrent sprints until they are closed."
            color="red"
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
          <>
            <SprintHistoryHeader>
              <div>
                <TitleHeadingTag>
                  {subprojects && selectedSubproject
                    ? `${subprojects.find((x: Subproject) => x.id == selectedSubproject.id)?.name} Sprints`
                    : 'Sprints'}
                </TitleHeadingTag>
              </div>
              {subprojects && selectedSubproject && (
                <SubprojectSelector subprojects={subprojects} setSelectedSubproject={setSelectedSubproject} />
              )}
            </SprintHistoryHeader>
            {sprintSummaries?.length ? (
              <div style={{ backgroundColor: newCOLORS.white, padding: '1em' }}>
                <SprintHeadingTag>{subtitle}</SprintHeadingTag>
                <HistoryGrid>
                  {sprintSummaries
                    .filter((sprint: SprintSummary) => sprint.subproject_id === selectedSubproject?.id)
                    .map((summary, idx) => {
                      return <SprintHistoryListItem summary={summary} clickHandler={summaryClicked} key={idx} />;
                    })}
                </HistoryGrid>
              </div>
            ) : (
              <DataFallbackMessage text={fallbackMessage} />
            )}
          </>
        </div>
      </div>
    </MenuDrivenContainer>
  );
};

const SprintHeadingTag = styled.div`
  font-size: x-large;
`;

const HistoryGrid = styled.div`
  display: grid;
  width: min(100%, 100vw);
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  column-gap: 1em;
`;

const SprintHistoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
