import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import { useContext, useState } from 'react';
import { useIntegrationEntries } from '../../../api/integrations-client/integrations-client.hooks';
import { IntegrationEntry } from '../../../api/integrations-client/integrations-client.type';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { GLOBAL_MAILTO_SUPPORT_LINK } from '../../../constants';
import { UserContext } from '../../../contexts';
import { useOrganizationId, useRoles } from '../../../helpers/auth-helpers/auth.hooks';
import { useDocumentTitle } from '../../../helpers/general-helpers';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { newCOLORS } from '../../../styles/colors';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { AvailableIntegrations } from './available-integrations';
import { Section } from './integrations-list.type';
import { UpcomingIntegrations } from './upcoming-integrations';

export function IntegrationsList() {
  useDocumentTitle('Integrations - Bloomfilter');
  const portfolio = useGlobalStore((state) => state.portfolio);
  const { user } = useContext(UserContext);
  const { isAdmin } = useRoles();
  const organizationId = useOrganizationId();

  const [showRefreshMessage, setShowRefreshMessage] = useState<boolean>(false);
  const [openedSection, setOpenedSection] = useState<Section | null>(Section.AvailableIntegrations);

  const { data: integrationEntries = [], query } = useIntegrationEntries(organizationId, {
    select: (data: IntegrationEntry[]) => data.filter((integration) => integration.projects.length > 0),
    enabled: !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  return (
    <MenuDrivenContainer header="Integrations">
      <BrandedLoadingOverlay visible={query.isFetching} transitionDuration={30} variant="colored" />
      <Flex justify="flex-end">
        <ContactLink href={GLOBAL_MAILTO_SUPPORT_LINK}>Contact Customer Success</ContactLink>
      </Flex>
      <PageContainer>
        {isAdmin && showRefreshMessage && <RefreshMessage onClose={() => setShowRefreshMessage(false)} />}
        {portfolio && user && !isAdmin && <NonAdminMessage />}
        <AvailableIntegrations
          integrationEntries={integrationEntries}
          isLoading={query.isFetching}
          openedSection={openedSection}
          setOpenedSection={setOpenedSection}
          setShowRefreshMessage={setShowRefreshMessage}
        />
        <UpcomingIntegrations openedSection={openedSection} setOpenedSection={setOpenedSection} />
      </PageContainer>
    </MenuDrivenContainer>
  );
}

const RefreshMessage = ({ onClose }: { onClose: () => void }) => {
  const supportLink = (
    <a href={GLOBAL_MAILTO_SUPPORT_LINK}>
      <LinkText>Customer Success</LinkText>
    </a>
  );

  const message = (
    <>Data refresh successfully initiated. Check back at this page for updates, or contact {supportLink}.</>
  );

  return <PageHeaderMessage message={message} color="green" onClose={onClose} />;
};

const NonAdminMessage = () => {
  const { admins } = useRoles();

  const adminName = admins[0]?.name;

  const defaultMessage = 'You are not listed as an admin on this account';

  const supportLink = (
    <a href={GLOBAL_MAILTO_SUPPORT_LINK}>
      <LinkText>Customer Success</LinkText>
    </a>
  );

  const getMessage = () => {
    if (adminName) {
      return (
        <>
          {defaultMessage}, for help please contact {adminName} or {supportLink} at Bloomfilter.
        </>
      );
    }

    return (
      <>
        {defaultMessage}, for help please contact {supportLink} at Bloomfilter.
      </>
    );
  };

  return (
    <div>
      <PageHeaderMessage message={getMessage()} color="yellow" />
    </div>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const ContactLink = styled.a`
  color: #5c3ecf;
  text-align: right;
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  height: fit-content;
  margin-bottom: 16px;
`;

const LinkText = styled.span`
  color: ${newCOLORS.indigo};
  cursor: pointer;
  text-decoration: underline;
`;
