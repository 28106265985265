import { useContext } from 'react';
import { UserContext } from '../../contexts';
import { useGlobalStore } from '../../store/global-store/global-store';
import { Roles, RolesData } from './auth.types';

/**
 * Returns the user's role and whether they are an admin based on the user's
 * organization memberships and the organization's portfolio.
 *
 * @returns {RolesData} Roles data object
 */
const useRoles = (): RolesData => {
  const { user } = useContext(UserContext);
  const portfolio = useGlobalStore((state) => state.portfolio);

  const organizationId = portfolio?.organization?.id;

  if (!organizationId) {
    return {
      role: Roles.USER,
      isAdmin: false,
      admins: [],
    };
  }

  const isAdmin = user?.organization_memberships[organizationId]?.is_admin || false;
  const admins = user?.organization_memberships[organizationId]?.admins || [];

  return {
    role: isAdmin ? Roles.ADMIN : Roles.USER,
    isAdmin,
    admins,
  };
};

/**
 * Returns the organization id associated with the user, or null if not found.
 *
 * @returns {string | null} The organization id, or null if not found
 */
const useOrganizationId = (): string | null => {
  const { user } = useContext(UserContext);
  const portfolio = useGlobalStore((state) => state.portfolio);
  // Having this as an if else clause is important in order to avoid
  // extra react query from being fired which causes projects/boards/...... to
  // not show up even after a successful external system authentication
  if (portfolio && user) {
    if (portfolio?.organization?.id) {
      return portfolio?.organization?.id;
    } else if (user?.organizations[0]) {
      return user?.organizations[0];
    }
    return null;
  } else {
    return null;
  }
};

export { useOrganizationId, useRoles };
