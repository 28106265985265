import { styled } from '@linaria/react';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSprint, useSprintAssessment } from '../../api/sprints-client/sprints-client.hooks';
import { DataFallbackMessage } from '../../components/data-fallback-message/data-fallback-message';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { ProjectContext } from '../../contexts';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { SprintAssessmentContent } from './sprint-assessment-content';
import { SprintAssessmentHeader } from './sprint-assessment-header';
import { DropDownData } from './sprint-assessment.type';

export const SprintAssessment = () => {
  const { project } = useContext(ProjectContext);
  const { sprintId = null } = useParams<{ sprintId: string }>();

  const { sprint, query: sprintQuery } = useSprint({ sprintId }, { enabled: !!sprintId });
  const { data, isFetching: isLoadingAssessmentData } = useSprintAssessment(
    { sprintId },
    { enabled: !!sprintId && !!sprint && !sprint.oversized }
  );

  const [sprints, setSprints] = useState<DropDownData[] | null>(null);
  const isLoading = sprintQuery.isFetching || isLoadingAssessmentData;

  return (
    <MenuDrivenContainer>
      <BrandedLoadingOverlay visible={isLoading && !sprint?.oversized} variant="colored" />
      <SprintAssessmentContainer>
        <SprintAssessmentHeader sprint={sprint || null} sprints={sprints || []} setSprints={setSprints} />
        {sprints?.length === 0 ? (
          <DataFallbackMessage text={`Selected board doesn't have any sprints`} />
        ) : (
          <SprintAssessmentContent
            sprint={sprint || null}
            sprintQuery={sprintQuery}
            project={project}
            assessmentData={data}
            isLoadingAssessmentData={isLoadingAssessmentData}
          />
        )}
      </SprintAssessmentContainer>
    </MenuDrivenContainer>
  );
};

const SprintAssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 16px;
  }
`;
