import { ReactNode, createContext, useEffect, useState } from 'react';
import { navItems } from './side-bar.data';
import { SidebarContextType } from './side-bar.type';

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType);

export function SidebarProvider({ children }: { children: ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [windowViewportWidth, setWindowViewportWidth] = useState<number>(window.innerWidth || 1200);
  const [viewAreaWidth, setViewAreaWidth] = useState<number>(window.innerWidth - 80 || 1120);

  useEffect(() => {
    const handleResize = () => {
      setWindowViewportWidth(window.innerWidth);
      sidebarOpen ? setViewAreaWidth(window.innerWidth - 280) : setViewAreaWidth(window.innerWidth - 70);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sidebarOpen]);

  useEffect(() => {
    sidebarOpen ? setViewAreaWidth(window.innerWidth - 280) : setViewAreaWidth(window.innerWidth - 70);
  }, [sidebarOpen]);

  return (
    <SidebarContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        navItems,
        viewAreaWidth,
        setViewAreaWidth,
        windowViewportWidth,
        setWindowViewportWidth,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}
