import { styled } from '@linaria/react';
import { AppShell } from '@mantine/core';
import { Fragment, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import failWhale from '../../assets/images/fail-whale.svg';
import { GLOBAL_MAILTO_SUPPORT_LINK } from '../../constants';
import { UserContext } from '../../contexts';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { logout } from '../../helpers/storage/storage';
import { newCOLORS } from '../../styles/colors';
import { Brand } from '../brand/brand';

export const OutOfBloom = () => {
  const baseURL = import.meta.env.VITE_APP_PUBLIC_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  trackEvent(AnalyticsEventType.OutOfBloomViewed, { userContext: user });

  return (
    <AppShell>
      <DivContainer>
        <AppShell.Header h={90} style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <Brand />
          <Container>
            <FlexItem>
              <ContentContainer>
                <ContentHeader>Something went wrong :&#40;</ContentHeader>
                {!location.state ? (
                  <Fragment>
                    <ErrorHeader>404 - Page not found</ErrorHeader>
                    <p style={{ marginTop: '10px', color: newCOLORS.darkGray }}>
                      <i>
                        A Bloomfilter is a data structure designed to tell you, rapidly and memory-efficiently, whether
                        an element is present in a set.
                      </i>
                    </p>
                    <p>This Bloomfilter page is sort of doing the same thing.</p>
                    <p>
                      <b>We were unable to find the page you are looking for.</b>
                    </p>
                    <p>We apologize for the inconvenience.</p>
                    <p>
                      Please return to your <a href={`${baseURL}/application/strategy`}>landing page</a> to continue.
                    </p>
                    <p>
                      You can also{' '}
                      <a
                        href={baseURL}
                        onClick={() => {
                          trackEvent(AnalyticsEventType.LogoutTapped, { userContext: user });
                          logout();
                        }}
                      >
                        sign out
                      </a>{' '}
                      of your current session.
                    </p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <ErrorHeader>500 - Server error</ErrorHeader>
                    <p style={{ marginTop: '10px', color: newCOLORS.darkGray }}>
                      <i>Oops. Something went wrong.</i>
                    </p>
                    <RefreshButton onClick={() => navigate(-2)}>RETURN</RefreshButton>
                    <p style={{ marginTop: '20px' }}>
                      If you’re consistently experiencing problems with the platform,
                      <br />
                      Please contact{' '}
                      <a
                        href={GLOBAL_MAILTO_SUPPORT_LINK}
                        style={{ color: newCOLORS.pink, textDecoration: 'underline' }}
                      >
                        support@bloomfilter.app
                      </a>
                      <br />
                      for assistance.
                    </p>
                    <p>
                      You can also{' '}
                      <a
                        href={baseURL}
                        onClick={() => {
                          trackEvent(AnalyticsEventType.LogoutTapped, { userContext: user });
                          logout();
                        }}
                      >
                        sign out
                      </a>{' '}
                      of your current session.
                    </p>
                  </Fragment>
                )}
              </ContentContainer>
            </FlexItem>
            <FlexItem>
              <img alt="Out of Bloom :(" src={failWhale} width="80%" style={{ marginTop: '20px' }} />
            </FlexItem>
          </Container>
        </AppShell.Header>
      </DivContainer>
    </AppShell>
  );
};

const DivContainer = styled.div`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 60%;
`;

const FlexItem = styled.div`
  width: 50%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 15%;
`;

const ErrorHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
`;

const RefreshButton = styled.button`
  background-color: ${newCOLORS.gray};
  outline: none;
  border: none;
  width: 50%;
  padding: 10px;
  border-radius: 5px;
`;
