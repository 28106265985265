import { styled } from '@linaria/react';
import { useContext } from 'react';
import { inkLight } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { SidebarContext } from './side-bar.context';

export const ActionButton = () => {
  const { sidebarOpen, setSidebarOpen } = useContext(SidebarContext);

  return (
    <ActionIcon
      onClick={() => setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen)}
      data-testid="sidebar-toggle"
      role="button"
      aria-label={`${sidebarOpen ? 'Collapse' : 'Expand'} sidebar`}
      aria-expanded={sidebarOpen}
    >
      <Icon
        name={sidebarOpen ? 'chevron_left' : 'chevron_right'}
        color={inkLight}
        size={16}
        style={{ cursor: 'pointer' }}
      />
    </ActionIcon>
  );
};

const ActionIcon = styled.div`
  position: absolute;
  bottom: 24px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 30px;
  border-radius: 32px;
  cursor: pointer;
  border: 1px solid var(--sky-base);

  &:hover {
    border: 1px solid var(--secondary-base);
  }
`;
