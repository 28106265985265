import { Flex } from '@mantine/core';
import { useCallback, useContext } from 'react';
import { SidebarContext } from '../../containers/side-bar/side-bar.context';
import { useGlobalStore } from '../../store/global-store/global-store';
import { setOrganization, setPortfolio } from '../../store/global-store/global-store.actions';
import { Select } from '../../ui-library/select/select';
import { icons } from './assets';

type Props = {
  enableTooltips: boolean;
};

export function GlobalScope({ enableTooltips }: Props) {
  const { sidebarOpen } = useContext(SidebarContext);

  const { organization, portfolio, organizations, portfolios } = useGlobalStore();

  const getOrganizationOptions = useCallback(
    () => organizations.map(({ id, name }) => ({ value: id, label: name })),
    [organizations]
  );

  const getPortfolioOptions = useCallback(() => {
    const filteredPortfolios = organization
      ? portfolios.filter((p) => p.organization.id === organization.id)
      : portfolios;
    return filteredPortfolios.map(({ id, name }) => ({ value: id, label: name }));
  }, [organization, portfolios]);

  return (
    <Flex direction="column" gap={16}>
      <Select
        options={getOrganizationOptions()}
        value={organization?.id || null}
        onChange={(value: string) => {
          setOrganization(organizations.find((o) => o.id === value) || null);
          setPortfolio(portfolios.find((p) => p.organization.id === value) || null);
        }}
        placeholder="Organization"
        enableTooltip={enableTooltips}
        searchable
        leftIcon={<img src={icons.iconOrganization} width={20} height={20} alt="Organization" />}
        disableChevron={!sidebarOpen}
      />
      <Select
        options={getPortfolioOptions()}
        value={portfolio?.id || null}
        onChange={(value: string) => {
          const newPortfolio = portfolios.find((p) => p.id === value) || null;
          setPortfolio(newPortfolio);
        }}
        placeholder="Portfolio"
        enableTooltip={enableTooltips}
        searchable
        leftIcon={<img src={icons.iconPortfolio} width={20} height={20} alt="Portfolio" />}
        disableChevron={!sidebarOpen}
      />
    </Flex>
  );
}
