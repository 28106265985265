import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { isLoggingEnabled, logger } from '../logger/logger';
import { SignInStep, SignUpStep, State } from './auth-store.type';

const initialState: State = {
  signUpInitialized: false,
  signUpStep: SignUpStep.CreateAccount,
  signInStep: SignInStep.Login,
  email: null,
  password: null,
  organizationName: null,
};

const useAuthStore = create<State>()(
  devtools(
    persist(
      logger((_) => ({
        ...initialState,
      })),
      {
        name: 'auth-storage',
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => !['password'].includes(key))),
      }
    ),
    {
      name: 'Bloomfilter - Auth Store',
      enabled: isLoggingEnabled,
    }
  )
);

export { initialState, useAuthStore };
