import { GeneralViewNames, NavItem } from './side-bar.type';

const showProcessAnalysis: boolean = import.meta.env.VITE_FEATURE_FLAG_PROCESS_ANALYSIS === 'true';

const navItems: NavItem[] = [
  {
    label: GeneralViewNames.Portfolio,
    icon: 'business_center',
    path: '/application/dashboard',
    show: true,
  },
  {
    label: GeneralViewNames.Strategy,
    icon: 'ads_click',
    path: '/application/strategy',
    show: true,
  },
  {
    label: GeneralViewNames.Financials,
    icon: 'payments',
    path: `/application/financials/portfolio/`,
    show: true,
  },
  {
    label: GeneralViewNames.Process,
    icon: 'map',
    path: `/application/process/`,
    show: true,
  },
  {
    label: GeneralViewNames.ProcessAnalysis,
    icon: 'auto_graph',
    path: '/application/process-analysis',
    show: showProcessAnalysis,
  },
  {
    label: GeneralViewNames.Integrations,
    icon: 'cloud',
    path: '/application/integrations-list',
    show: true,
  },
];

export { navItems };
