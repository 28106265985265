import { Initiative } from '../../../api/initiative-client/initiative-client.type';

type SortedColumn = 'name' | 'status' | 'completion' | 'businessValue' | 'startDate' | 'endDate';

const sortInitiatives = (initiatives: Initiative[], sortBy: SortedColumn, sortDesc: boolean): Initiative[] => {
  return [...initiatives].sort((a, b) => {
    let aValue: string | number | Date = '';
    let bValue: string | number | Date = '';

    switch (sortBy) {
      case 'name':
        aValue = a.name.toLowerCase();
        bValue = b.name.toLowerCase();
        break;
      case 'status':
        aValue = a.status?.by_tasks.status || '';
        bValue = b.status?.by_tasks.status || '';
        break;
      case 'completion':
        aValue = a.percent_progress || 0;
        bValue = b.percent_progress || 0;
        break;
      case 'businessValue':
        aValue = a.relative_business_value || 0;
        bValue = b.relative_business_value || 0;
        break;
      case 'startDate':
        aValue = new Date(a.start_date || '');
        bValue = new Date(b.start_date || '');
        break;
      case 'endDate':
        aValue = new Date(a.end_date);
        bValue = new Date(b.end_date);
        break;
    }

    if (aValue < bValue) {
      return sortDesc ? 1 : -1;
    }
    if (aValue > bValue) {
      return sortDesc ? -1 : 1;
    }
    return 0;
  });
};

export { sortInitiatives };
