import { createTheme } from '@mantine/core';

export const theme = createTheme({
  fontFamily: 'Figtree',
  breakpoints: {
    xs: '480px',
    sm: '860px',
    md: '1024px',
    lg: '1366px',
    xl: '1920px',
  },
});
