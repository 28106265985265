import { QueryObserverResult, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { checkDomain, login, refreshToken, resendOTP, verifyOTP } from './auth-client';
import { DomainCheckResponse, LoginMutation, LoginResponse, OTPResponse, RefreshResponse } from './auth-client.type';

/**
 * Hook to authenticate a user with email and password
 *
 * @param {UseMutationOptions<LoginResponse, {error: {detail: string}}, {email: string | null, password: string | null}>} [options] - Additional mutation options
 * @returns {LoginMutation} Mutation result containing login response or error
 */
const useLogin = (
  options?: UseMutationOptions<
    LoginResponse,
    { error: { detail: string } },
    { email: string | null; password: string | null }
  >
): LoginMutation => {
  return useMutation({
    mutationKey: ['login'],
    mutationFn: ({ email, password }) =>
      email && password ? login({ email, password }) : Promise.reject(new Error('Email and password are required')),
    ...options,
  });
};

/**
 * Hook to check if a domain exists and get authentication method details
 *
 * @param {string} domain - Domain to check for authentication configuration
 * @param {UseQueryOptions<DomainCheckResponse>} [options] - Additional query options
 * @returns {QueryObserverResult<DomainCheckResponse>} Query result containing domain check response with auth method and redirect URL
 */
const useCheckDomain = (
  domain: string,
  options?: UseQueryOptions<DomainCheckResponse>
): QueryObserverResult<DomainCheckResponse> => {
  return useQuery({
    queryKey: ['check-domain', { domain }],
    queryFn: () => checkDomain(domain),
    enabled: !!domain,
    ...options,
  });
};

/**
 * Hook to refresh an authentication token
 *
 * @param {string} token - Token to be refreshed
 * @param {UseQueryOptions<RefreshResponse>} [options] - Additional query options
 * @returns {QueryObserverResult<RefreshResponse>} Query result containing new access and refresh tokens
 */
const useRefreshToken = (
  token: string,
  options?: UseQueryOptions<RefreshResponse>
): QueryObserverResult<RefreshResponse> => {
  return useQuery({
    queryKey: ['refresh-token', { token }],
    queryFn: () => refreshToken(token),
    enabled: !!token,
    ...options,
  });
};

/**
 * Hook to verify a one-time password (OTP) for a given email
 *
 * @param {string | null} email - Email address associated with the OTP
 * @param {string} otp - One-time password code to verify
 * @param {UseQueryOptions<OTPResponse>} [options] - Additional query options
 * @returns {QueryObserverResult<OTPResponse>} Query result containing success/error message
 */
const useVerifyOTP = (
  email: string | null,
  otp: string,
  options?: UseQueryOptions<OTPResponse>
): QueryObserverResult<OTPResponse> => {
  return useQuery({
    queryKey: ['verify-otp', { email, otp }],
    queryFn: () => (email ? verifyOTP(email, otp) : Promise.reject(new Error('Email is required'))),
    enabled: !!email,
    ...options,
  });
};

/**
 * Hook to resend a one-time password (OTP) to the specified email address
 *
 * @param {string | null} email - Email address to resend the OTP to
 * @param {UseQueryOptions<OTPResponse>} [options] - Additional query options
 * @returns {QueryObserverResult<OTPResponse>} Query result containing success/error message
 */
const useResendOTP = (
  email: string | null,
  options?: UseQueryOptions<OTPResponse>
): QueryObserverResult<OTPResponse> => {
  return useQuery({
    queryKey: ['resend-otp', { email }],
    queryFn: () => (email ? resendOTP(email) : Promise.reject(new Error('Email is required'))),
    enabled: !!email,
    ...options,
  });
};

export { useCheckDomain, useLogin, useRefreshToken, useResendOTP, useVerifyOTP };
