import { styled } from '@linaria/react';
import { Space } from '@mantine/core';
import { useContext, useRef } from 'react';
import { Sprint } from '../../api/sprints-client/sprints-client.type';
import { ViewByType } from '../../components/burn-flow/burn-flow-chart.type';
import { DaySelect } from '../../components/day-of-sprint-select/day-of-sprint-select';
import { DownloadPNGButton } from '../../components/download-png-button';
import { ViewSelect } from '../../components/view-of-sprint-select/view-of-sprint-select';
import { canvasHtmlDownload } from '../../helpers/image-downloader/image-downloader';
import { newCOLORS } from '../../styles/colors';
import { SidebarContext } from '../side-bar/side-bar.context';
import { getDownloadLabel } from './sprint-assessment.helpers';
import { SprintAssessmentChart } from './sprint-assessment.type';

type Props = {
  chartId: SprintAssessmentChart;
  sprint: Sprint;
  sprintLength: number;
  sprintCurrentDay: number;
  day: string;
  onChangeDay: (value: string) => void;
  view: ViewByType;
  onChangeView: (value: ViewByType) => void;
  children: React.ReactNode;
  title: React.ReactNode;
};

export const ChartContainer = ({
  chartId,
  sprint,
  sprintLength,
  sprintCurrentDay,
  day,
  onChangeDay,
  view,
  onChangeView,
  children,
  title,
}: Props) => {
  const { viewAreaWidth } = useContext(SidebarContext);

  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  return (
    <Container ref={downloadRef}>
      <Header viewAreaWidth={viewAreaWidth}>
        <TitleBox>{title}</TitleBox>
        <DropDownContainer ref={exceptionRef}>
          <DaySelect
            value={day}
            startDate={sprint.start_date}
            lengthOfSprint={sprintLength}
            currentDay={sprintCurrentDay}
            onChange={onChangeDay}
          />
          <Space w={30} />
          <ViewSelect
            value={view}
            onChange={(value: string) => {
              onChangeView(value as ViewByType);
            }}
          />
          <div ref={exceptionRef}>
            {
              <DownloadPNGButton
                handleDownload={() =>
                  canvasHtmlDownload(
                    `${sprint.name}_${getDownloadLabel(chartId)}_${'Day' + day}`,
                    downloadRef,
                    exceptionRef
                  )
                }
                exceptionRef={exceptionRef}
              />
            }
          </div>
        </DropDownContainer>
      </Header>
      {children}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${newCOLORS.white};
  padding: 16px 32px;
`;

const Header = styled.div<{ viewAreaWidth: number }>`
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 1200 ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${(props) => (props.viewAreaWidth >= 1200 ? 'center' : 'start')};
  margin-bottom: 32px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropDownContainer = styled.div`
  display: flex;
`;
