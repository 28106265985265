enum AllocationOfCostsCategory {
  ASSIGNEE = 'assignee',
  COMPONENTS = 'component',
  DUE_DATE = 'due_date',
  EPIC = 'epic',
  LABELS = 'label',
  POINTS = 'points',
  PRIORITY = 'priority',
  REPORTER = 'reporter',
  SPRINT = 'sprint',
  TYPE = 'type',
}

enum ViewBy {
  TASKS = 'tasks',
  POINTS = 'points',
  TASKS_IN_USD = 'tasks_in_usd',
  POINTS_IN_USD = 'points_in_usd',
}

type Spending = {
  year: number;
  month: number;
  budget: number | null;
  portfolio_id: string;
  project_id: string;
  valid?: boolean;
  autofilled?: boolean;
};

type SpendingDetail = {
  month: number;
  budget: number | null;
};

type ProjectSpending = {
  [key: string]: SpendingDetail[];
};

type SpendingBulkPostRequest = {
  portfolio_id: string;
  year: number;
  projects: ProjectSpending;
};

type SpendingBulkDeleteRequest = {
  portfolio_id: string;
  year: number;
  project_id: string;
};

type CostVsBudgetSummary = {
  cost: number;
  daily_burn: number;
  tasks_completed: number;
  tasks_committed: number;
  cost_per_task: number;
  points_completed: number;
  points_committed: number;
  cost_per_point: number;
};

type CostVsBudgetChartProjectData = {
  id: string;
  name: string;
  cost: number;
  grade: number;
  daily_burn: number;
  completed_tasks: number;
  committed_tasks: number;
  completed_points: number;
  committed_points: number;
};

type CostVsBudgetChartData = {
  month: number;
  year: number;
  grade: number;
  projects: CostVsBudgetChartProjectData[];
};

type CostVsBudgetChartResponse = {
  last: CostVsBudgetSummary;
  average: CostVsBudgetSummary;
  chart_data: CostVsBudgetChartData[];
};

type AllocationOfCostsSummary = {
  field: string;
  id: string;
  tasks: number;
  tasks_in_usd: number;
  points: number;
  points_in_usd: number;
};

type AllocationOfCostsChartData = {
  [timeAllocation: string]: {
    [factorOption: string]: {
      total: Omit<AllocationOfCostsSummary, 'field'>;
      teams: {
        [team: string]: Omit<AllocationOfCostsSummary, 'field'>;
      };
    };
  };
};

type AllocationOfCostsChartRequest = {
  portfolioId: string | null;
  teamId: string | null;
  startDate: Date | null;
  endDate: Date | null;
};

type AllocationOfCostsChartResponseByCategory = {
  [K in AllocationOfCostsCategory]: AllocationOfCostsChartResponseByCategoryDetails;
};

type AllocationOfCostsChartResponseByCategoryDetails = {
  options: {
    [factorOption: string]: {
      total: Omit<AllocationOfCostsSummary, 'field'>;
      average: Omit<AllocationOfCostsSummary, 'field'>;
    };
  };
  chart_data: AllocationOfCostsChartData;
};

type AllocationOfCostsChartResponse = {
  cost_allocation: AllocationOfCostsChartResponseByCategory;
};

type CostByInitiativeTeamData = {
  id: string;
  name: string;
  costs: { [date: string]: number };
};

type CostByInitiativeChartData = {
  initiative: { [date: string]: number };
  teams: CostByInitiativeTeamData[];
};

type CostByInitiativeResponse = {
  id: string;
  name: string;
  cost_to_date: number;
  chart_data: CostByInitiativeChartData;
};

type CostByInitiativeV2Response = {
  cost_by_initiative: CostByInitiativeResponse[];
};

export { AllocationOfCostsCategory, ViewBy };

export type {
  AllocationOfCostsChartData,
  AllocationOfCostsChartRequest,
  AllocationOfCostsChartResponse,
  AllocationOfCostsChartResponseByCategory,
  AllocationOfCostsChartResponseByCategoryDetails,
  AllocationOfCostsSummary,
  CostByInitiativeResponse,
  CostByInitiativeV2Response,
  CostVsBudgetChartData,
  CostVsBudgetChartResponse,
  CostVsBudgetSummary,
  ProjectSpending,
  Spending,
  SpendingBulkDeleteRequest,
  SpendingBulkPostRequest,
};
