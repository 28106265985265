import { styled } from '@linaria/react';

import { icons } from '../../assets/icons/icons';
import { Button } from '../../components/button/button';
import { newCOLORS } from '../../styles/colors';

type CreateNewInitiativeExplainerProps = {
  handleCreateInitiative: () => void;
};

export function CreateNewInitiativeExplainer({ handleCreateInitiative }: CreateNewInitiativeExplainerProps) {
  return (
    <CreateNewInitiativeContainer data-testid="initiative-explainer">
      <DropShadowCardContainer>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 24 }}>
          <ContentContainer>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 24,
              }}
            >
              <img src={icons.iconStrategyGradient} alt="Strategy" width={56} height={56} />
              <Title>Understand what is being worked on, by whom and how this aligns with your goals.</Title>
              <Description>
                Priorities are achieved through the relationships between Initiatives, Epics, and Teams.
              </Description>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 32 }}>
              <Explainer
                icon={icons.iconCompass}
                title="Initiatives"
                description="Strategic objectives defined in Bloomfilter, initiatives are the building blocks for planning and resource allocation."
                color={newCOLORS.blue}
              />
              <Explainer
                icon={icons.iconTarget}
                title="Epics"
                description="An epic is a broad piece of work created in a system of record like Jira, ADO, or Asana. Epics may be associated with Initiatives."
                color={newCOLORS.purple}
              />
              <Explainer
                icon={icons.iconTeam}
                title="Teams"
                description="A team is a working group of people, typically cross-functional. They complete product or project work associated with Epics."
                color={newCOLORS.orangeRed}
              />
            </div>
          </ContentContainer>
          <Button style={{ width: '250px' }} size="md" onClick={handleCreateInitiative}>
            Create Initiative
          </Button>
        </div>
      </DropShadowCardContainer>
    </CreateNewInitiativeContainer>
  );
}

type ExplainerProps = {
  icon: string;
  title: string;
  description: string;
  color: string;
};

function Explainer({ icon, title, description, color }: ExplainerProps) {
  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <img src={icon} alt={title} width={24} height={24} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <ExplainerTitle style={{ color }}>{title}</ExplainerTitle>
        <span>{description}</span>
      </div>
    </div>
  );
}

const CreateNewInitiativeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
`;

const DropShadowCardContainer = styled.div`
  background-color: ${newCOLORS.white};
  padding: 24px;
  width: 65%;
  height: fit-content;
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
`;

const Title = styled.span`
  color: ${newCOLORS.darkerGray};
  text-align: center;
  font-family: Figtree;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Description = styled.span`
  color: ${newCOLORS.black};
  text-align: center;
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;

const ExplainerTitle = styled.span`
  text-align: left;
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 32px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
