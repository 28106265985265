enum ResetPasswordError {
  EmailNotFound = 'No account found with this email address.',
  InvalidToken = 'Invalid or expired reset token.',
  ServerError = 'An error occurred. Please try again later.',
}

enum ResetPasswordView {
  Form = 'form',
  Success = 'success',
}

export { ResetPasswordError, ResetPasswordView };
