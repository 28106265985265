import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { UserInfo } from './user-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/auth/';

/**
 * Method to edit the user records
 *
 * @param body a full or partial object of UserInfo
 * @returns a updated object of UserInfo
 */
async function editMe(body: Partial<UserInfo>): Promise<UserInfo> {
  const apiURL = baseURL + 'users/me/';

  return await axiosInstance
    .put<any>(apiURL, body)
    .then((response) => response.data)
    .catch((error) => error.response);
}

/**
 * Method to fetch the user info
 *
 * @returns a object of UserInfo
 */
const fetchUserInfo = async (): Promise<UserInfo> => {
  const apiURL = baseURL + 'users/me/';
  return await axiosInstance
    .get<UserInfo>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
};

/**
 * Method to update the user name
 *
 * @param name: string
 * @returns a object of UserInfo
 */
async function updateUserName(name: string): Promise<UserInfo> {
  const apiURL = baseURL + 'users/me/';
  const body = { name: name };
  return axiosInstance
    .patch<UserInfo>(apiURL, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

export { editMe, fetchUserInfo, updateUserName };
