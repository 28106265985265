import { Chart as ChartJS, TooltipModel } from 'chart.js';
import { CostByInitiativeResponse } from '../../api/financials-client/financials-client.type';
import { newCOLORS } from '../../styles/colors';
let hideTooltipTimeout: NodeJS.Timeout | undefined;

const COST_BY_INITIATIVE_V2 = import.meta.env.VITE_FEATURE_FLAG_COST_BY_INITIATIVE_V2 === 'true';

const getOrCreateTooltip = (chart: ChartJS) => {
  if (!chart.canvas.parentNode) {
    return;
  }
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 1)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'auto';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.addEventListener('mouseenter', () => {
      clearTimeout(hideTooltipTimeout);
      if (tooltipEl) {
        tooltipEl.style.pointerEvents = 'auto';
      }
    });

    tooltipEl.addEventListener('mouseleave', () => {
      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
        tooltipEl.style.pointerEvents = 'none';
      }
    });
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const costByInitiativeTooltipHandler = (
  context: { chart: ChartJS; tooltip: TooltipModel<'bar'> },
  chartData: CostByInitiativeResponse[] | null,
  portfolioId: string | undefined
) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  clearTimeout(hideTooltipTimeout);

  const activePointExists = !!chart.getActiveElements().length;

  if (tooltip.opacity === 0 || !activePointExists) {
    hideTooltipTimeout = setTimeout(() => {
      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
        tooltipEl.style.pointerEvents = 'none';
      }
    }, 3000);
    return;
  }
  const currentDataIndex = tooltip.dataPoints[0]?.dataIndex;
  const currentChartData = tooltip.dataPoints[0];
  const currentInitiativeData = chartData?.find((data) => data.id === currentChartData.dataset.label);
  if (currentDataIndex === undefined || chartData === null || currentInitiativeData === undefined) {
    return;
  }

  if (tooltip.body) {
    const tableHead = document.createElement('thead');

    const titleString = currentInitiativeData?.name;
    const titleRow = document.createElement('tr');
    titleRow.style.borderWidth = '0';

    const titleTH = document.createElement('td');
    titleTH.style.borderWidth = '0';
    titleTH.style.fontSize = '17px';

    const text = document.createTextNode(titleString);
    titleTH.appendChild(text);
    titleRow.appendChild(titleTH);
    tableHead.appendChild(titleRow);

    const tableBody = document.createElement('tbody');

    const monthYearRow = document.createElement('tr');
    monthYearRow.style.fontSize = '12px';
    const monthYearData = document.createElement('td');
    monthYearData.style.minWidth = '130px';
    monthYearData.style.paddingRight = '50px';
    const currentDate = Object.keys(currentInitiativeData.chart_data.initiative)[currentDataIndex];
    const year = parseInt(currentDate.split('-')[0]);
    const monthStr = new Date(year, parseInt(currentDate.split('-')[1]) - 1).toLocaleString('default', {
      month: 'long',
    });
    const mydText = document.createTextNode(`${monthStr} ${year}`.toUpperCase());

    monthYearData.appendChild(mydText);
    monthYearRow.appendChild(monthYearData);

    const tasksData = document.createElement('td');
    const tasksDataText = document.createTextNode('COST');
    tasksData.appendChild(tasksDataText);
    monthYearRow.appendChild(tasksData);

    tableHead.appendChild(monthYearRow);

    const tr = document.createElement('tr');
    tr.style.backgroundColor = 'inherit';
    tr.style.borderWidth = '0';

    const th = document.createElement('td');
    th.style.borderWidth = '0';
    const thText = document.createTextNode('Total');

    const td = document.createElement('td');
    th.style.borderWidth = '0';
    const totalText = `$${new Intl.NumberFormat().format(
      Math.round(currentInitiativeData.chart_data.initiative[currentDate])
    )}`;

    const totalLinkURL = `/application/strategy/${portfolioId}/${currentInitiativeData.id}/tasks/?filter=completed_monthly&date=${currentDate}`;
    const totalLinkURL_V2 = `/application/financials/portfolio/${portfolioId}/tasks/?measure=cost_by_initiative&date=${currentDate}&factor=${
      currentInitiativeData.id
    }&name=${encodeURIComponent(currentInitiativeData.name)}`;
    const linkURL = COST_BY_INITIATIVE_V2 ? totalLinkURL_V2 : totalLinkURL;

    th.appendChild(thText);
    const totalAnchor = document.createElement('a');
    totalAnchor.setAttribute('href', linkURL);
    totalAnchor.style.color = newCOLORS.lightPurple;
    totalAnchor.style.textDecoration = 'underline';
    totalAnchor.style.textDecorationStyle = 'dashed';
    const tdText = document.createTextNode(totalText);
    totalAnchor.appendChild(tdText);
    td.appendChild(totalAnchor);

    tr.appendChild(th);
    tr.appendChild(td);
    tableBody.appendChild(tr);

    currentInitiativeData.chart_data.teams.forEach((team) => {
      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';

      const th = document.createElement('td');
      th.style.borderWidth = '0';
      const thText = document.createTextNode(team.name);

      const td = document.createElement('td');
      th.style.borderWidth = '0';

      const value = team.costs[currentDate];
      let valueText = `$${new Intl.NumberFormat().format(Math.round(value))}`;
      if (value === Infinity || value === null || isNaN(value)) {
        valueText = ' -';
      }
      th.appendChild(thText);
      const tdText = document.createTextNode(valueText);

      // link to cost details
      const projectLinkUrl = linkURL + `&projectId=${team.id}`;
      const anchor = document.createElement('a');
      anchor.setAttribute('href', projectLinkUrl);
      anchor.style.color = newCOLORS.lightPurple;
      anchor.style.textDecoration = 'underline';
      anchor.style.textDecorationStyle = 'dashed';
      anchor.appendChild(tdText);
      td.appendChild(anchor);

      tr.appendChild(th);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    if (tooltipEl) {
      const tableRoot = tooltipEl.querySelector('table');

      if (tableRoot) {
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }
    }
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  const tooltipOffsetPadding = 10;
  if (tooltipEl) {
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'auto';
    tooltipEl.style.left = tooltip.caretX + positionX - tooltipEl.offsetWidth / 2 - tooltipOffsetPadding + 'px';
    tooltipEl.style.top = tooltip.caretY + positionY - tooltipEl.offsetHeight + tooltipOffsetPadding + 'px';
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    tooltipEl.style.zIndex = '400';
  }
};
