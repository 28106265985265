import { Select } from '@mantine/core';
import { Fragment, forwardRef, useContext, useImperativeHandle } from 'react';
import { useBoardsByTeam } from '../../../api/projects-client/projects-client.hooks';
import { Board, Subproject } from '../../../api/projects-client/projects-client.type';
import { StandardText } from '../../../styles/new-shared-styled-components/new-shared-styled-components';
import { ProcessContext } from '../context/process.context';
import { useProcessScope } from '../process.hooks';

export type BoardSelectRef = {
  getBoards: () => Board[];
};

export type BoardSelectProps = {
  handleBoardSelected?: (board: Board | null) => void;
  hideLabel?: boolean;
  includeAggregate?: boolean;
};

export const BoardSelect = forwardRef<BoardSelectRef, BoardSelectProps>(({ hideLabel, handleBoardSelected }, ref) => {
  const { team, board, setScope } = useProcessScope();
  const { setStages } = useContext(ProcessContext);

  const { boards } = useBoardsByTeam(team?.id || '', {
    enabled: Boolean(team?.id),
    staleTime: 1000 * 60 * 5,
  });

  const boardSelected = (newBoard: Board | null) => {
    if (newBoard && board && newBoard.id === board.id) {
      return;
    }

    setScope({
      board: newBoard,
    });
    setStages([]);

    handleBoardSelected && handleBoardSelected(newBoard);
  };

  useImperativeHandle(ref, () => ({
    getBoards: () => boards || [],
  }));

  function refactorDropdownData(boards: Subproject[]) {
    return (boards || []).map((b) => ({ value: b.id, label: b.name }));
  }

  return (
    <Fragment>
      {!hideLabel && <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Board</StandardText>}
      <Select
        data={refactorDropdownData(boards || [])}
        // explicitly set null to clear the value from mantine select. Setting undefined will keep the value visible!
        value={board ? board.id : null}
        onChange={(value) => {
          if (value) {
            const selectedBoard = (boards || []).find((b) => b.id === value);
            if (selectedBoard) {
              boardSelected(selectedBoard);
            }
          } else {
            boardSelected(null);
          }
        }}
        clearable
        allowDeselect={false}
      />
    </Fragment>
  );
});
