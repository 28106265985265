import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSprint, fetchSprintAuditLog } from '../../api/sprints-client/sprints-client';
import { Sprint, SprintAuditLogEntry } from '../../api/sprints-client/sprints-client.type';
import { AuditLog } from '../../components/audit-log/audit-log';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const SprintAuditLog = () => {
  const { sprintId = '' } = useParams<{ sprintId: string }>();
  const [auditLog, setAuditLog] = useState<SprintAuditLogEntry[]>([]);
  const [sprint, setSprint] = useState<Sprint | null>(null);
  const auditLogQuery = useQuery(
    ['sprintId', sprintId],
    sprintId ? () => fetchSprintAuditLog(sprintId) : () => Promise.reject('Sprint ID is required'),
    {
      onSuccess: setAuditLog,
      onError: ({ error }: { error: any }) => {
        return Promise.reject(error?.response?.data || 'Error while fetching sprint audit log');
      },
    }
  );

  useQuery(
    ['sprint', sprintId],
    () => (sprintId ? fetchSprint(sprintId) : Promise.reject('Unable to resolve SprintId or SprintID is missing')),
    {
      enabled: !!sprintId,
      onSuccess: setSprint,
      onError: () => console.error('There was an issue fetching sprint data.'),
    }
  );

  return (
    <MenuDrivenContainer header={sprint?.name ? `${sprint.name} Audit Log` : 'Audit Log'}>
      <BrandedLoadingOverlay visible={auditLogQuery.isLoading} transitionDuration={300} variant="colored" />
      <AuditLog sprint={sprint} entries={auditLog} />
    </MenuDrivenContainer>
  );
};
