import { styled } from '@linaria/react';
import { useEffect, useState } from 'react';
import { skyDark } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { formatTime } from './check-your-email.helpers';

type Props = {
  initialTime: number;
  onResend: () => void;
};

export const CountdownTimer = ({ initialTime, onResend }: Props) => {
  const [time, setTime] = useState(initialTime);
  const [isCountdownFinished, setIsCountdownFinished] = useState(false);

  useEffect(() => {
    if (time === 0) {
      setIsCountdownFinished(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  const handleResend = () => {
    setTime(initialTime);
    setIsCountdownFinished(false);
    onResend();
  };

  return (
    <Text size="small" lineHeight="normal" weight="medium">
      Still waiting?{' '}
      {isCountdownFinished ? (
        <ResendLink onClick={handleResend}>Resend</ResendLink>
      ) : (
        <span style={{ color: skyDark }}>Resend code in {formatTime(time)}</span>
      )}
    </Text>
  );
};

const ResendLink = styled.span`
  color: var(--secondary-light);
  cursor: pointer;
`;
