import { createFormActions, isNotEmpty, useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useDeepCompareEffect } from 'react-use';
import { useDeletePortfolio, usePortfolios } from '../../../api/portfolio-client/portfolio-client.hooks';
import { Portfolio } from '../../../api/portfolio-client/portfolio-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { setPortfolio, setPortfolios } from '../../../store/global-store/global-store.actions';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  applyBoardsFilter,
  applyDateFilter,
  applyPortfoliosFilter,
  applyTeamsFilter,
  applyWorkPeriodsFilter,
  setIsDirtyBoards,
  setIsDirtyPortfolios,
  setIsDirtyTeams,
  setIsDirtyWorkPeriods,
  setPortfolioIds,
} from '../../../store/process-analysis-store/process-analysis-store.actions';
import { useDateRange } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { WorkPeriodType } from '../process-analysis.type';
import { BoardFormType, PortfolioFormType, TeamFormType, WorkPeriodFormType } from './scope-explorer.type';

/**
 * Returns an object containing a form for selecting portfolios and a date range, along with a function to handle form submission.
 *
 * @return {Object} An object with the following properties:
 *   - `portfolioForm`: The form for selecting portfolios and a date range, created using the `useForm` hook.
 *   - `handleSubmitPortfolioForm`: A function that handles form submission. It takes a form object as a parameter and applies the selected portfolios and date range as filters.
 */
const usePortfolioForm = (): {
  portfolioForm: PortfolioFormType;
  handleSubmitPortfolioForm: (form: PortfolioFormType) => void;
} => {
  const portfolioIds = useProcessAnalysisStore((state) => state.portfolioIds);
  const isDirtyTeams = useProcessAnalysisStore((state) => state.isDirtyTeams);
  const isDirtyBoards = useProcessAnalysisStore((state) => state.isDirtyBoards);
  const isDirtyWorkPeriods = useProcessAnalysisStore((state) => state.isDirtyWorkPeriods);
  const { startDate, endDate } = useDateRange();

  const portfolioForm = useForm({
    name: 'portfolio-form',
    initialValues: {
      portfolios: portfolioIds,
      startDate: startDate,
      endDate: endDate,
    },
    validate: {
      portfolios: isNotEmpty('Please select at least one portfolio'),
      startDate: isNotEmpty('Please select a start date'),
      endDate: isNotEmpty('Please select an end date'),
    },
  });

  useDeepCompareEffect(() => {
    const currentPortfolios = portfolioForm.getValues().portfolios;

    if (currentPortfolios[0] !== portfolioIds[0]) {
      portfolioForm.setValues({ portfolios: [portfolioIds[0]] });
      portfolioForm.resetDirty();
    }
  }, [portfolioForm, portfolioIds[0]]);

  const handleSubmitPortfolioForm = (form: PortfolioFormType) => {
    const isDirtyPortfolios = form.isDirty('portfolios');
    const isDirtyDates = form.isDirty('startDate') || form.isDirty('endDate');

    if (isDirtyPortfolios) {
      applyPortfoliosFilter({ ids: form.values.portfolios });
    }

    if (isDirtyDates) {
      applyDateFilter({
        startDate: dayjs(form.values.startDate).toISOString(),
        endDate: dayjs(form.values.endDate).toISOString(),
      });

      syncTeamFormDates(form.values.startDate, form.values.endDate, !isDirtyTeams);
      syncBoardFormDates(form.values.startDate, form.values.endDate, !isDirtyBoards);
      syncWorkPeriodFormDates(form.values.startDate, form.values.endDate, !isDirtyWorkPeriods);
    }

    form.resetDirty();
    setIsDirtyPortfolios(false);
  };

  return { portfolioForm, handleSubmitPortfolioForm };
};

const portfolioFormActions = createFormActions<PortfolioFormType>('portfolio-form');
const syncPortfolioFormDates = (startDate: Date, endDate: Date, resetForms: boolean) => {
  portfolioFormActions.setFieldValue('startDate', startDate);
  portfolioFormActions.setFieldValue('endDate', endDate);

  if (resetForms) {
    portfolioFormActions.resetDirty();
  }
};

/**
 * Returns an object containing a form for selecting teams and a date range, along with a function to handle form submission.
 *
 * @return {Object} An object with the following properties:
 *   - `teamForm`: The form for selecting teams and a date range, created using the `useForm` hook.
 *   - `handleSubmitTeamForm`: A function that handles form submission. It takes a form object as a parameter and applies the selected teams and date range as filters.
 */
const useTeamForm = (): {
  teamForm: TeamFormType;
  handleSubmitTeamForm: (form: TeamFormType) => void;
} => {
  const teamIds = useProcessAnalysisStore((state) => state.teamIds);
  const isDirtyPortfolios = useProcessAnalysisStore((state) => state.isDirtyPortfolios);
  const isDirtyBoards = useProcessAnalysisStore((state) => state.isDirtyBoards);
  const isDirtyWorkPeriods = useProcessAnalysisStore((state) => state.isDirtyWorkPeriods);
  const { startDate, endDate } = useDateRange();

  const teamForm = useForm({
    name: 'team-form',
    initialValues: {
      teams: teamIds,
      startDate: startDate,
      endDate: endDate,
    },
    validate: {
      teams: isNotEmpty('Please select at least one team'),
      startDate: isNotEmpty('Please select a start date'),
      endDate: isNotEmpty('Please select an end date'),
    },
  });

  const handleSubmitTeamForm = (form: TeamFormType) => {
    const isDirtyTeams = form.isDirty('teams');
    const isDirtyDates = form.isDirty('startDate') || form.isDirty('endDate');

    if (isDirtyTeams) {
      applyTeamsFilter({
        ids: form.values.teams,
      });
    }

    if (isDirtyDates) {
      applyDateFilter({
        startDate: dayjs(form.values.startDate).toISOString(),
        endDate: dayjs(form.values.endDate).toISOString(),
      });

      syncPortfolioFormDates(form.values.startDate, form.values.endDate, !isDirtyPortfolios);
      syncBoardFormDates(form.values.startDate, form.values.endDate, !isDirtyBoards);
      syncWorkPeriodFormDates(form.values.startDate, form.values.endDate, !isDirtyWorkPeriods);
    }

    form.resetDirty();
    setIsDirtyTeams(false);
  };

  return { teamForm, handleSubmitTeamForm };
};

const teamFormActions = createFormActions<TeamFormType>('team-form');
const syncTeamFormDates = (startDate: Date, endDate: Date, resetForms: boolean) => {
  teamFormActions.setFieldValue('startDate', startDate);
  teamFormActions.setFieldValue('endDate', endDate);

  if (resetForms) {
    teamFormActions.resetDirty();
  }
};

/**
 * Returns an object containing a form for selecting boards and a date range, along with a function to handle form submission.
 *
 * @return {Object} An object with the following properties:
 *   - `boardForm`: The form for selecting boards and a date range, created using the `useForm` hook.
 *   - `handleSubmitBoardForm`: A function that handles form submission. It takes a form object as a parameter and applies the selected boards and date range as filters.
 */
const useBoardForm = (): {
  boardForm: BoardFormType;
  handleSubmitBoardForm: (form: BoardFormType) => void;
} => {
  const boardIds = useProcessAnalysisStore((state) => state.boardIds);
  const isDirtyPortfolios = useProcessAnalysisStore((state) => state.isDirtyPortfolios);
  const isDirtyTeams = useProcessAnalysisStore((state) => state.isDirtyTeams);
  const isDirtyWorkPeriods = useProcessAnalysisStore((state) => state.isDirtyWorkPeriods);
  const { startDate, endDate } = useDateRange();

  const boardForm = useForm({
    name: 'board-form',
    initialValues: {
      boards: boardIds,
      startDate: startDate,
      endDate: endDate,
    },
    validate: {
      boards: isNotEmpty('Please select at least one board'),
      startDate: isNotEmpty('Please select a start date'),
      endDate: isNotEmpty('Please select an end date'),
    },
  });

  const handleSubmitBoardForm = (form: BoardFormType) => {
    const isDirtyBoards = form.isDirty('boards');
    const isDirtyDates = form.isDirty('startDate') || form.isDirty('endDate');

    if (isDirtyBoards) {
      applyBoardsFilter({
        ids: form.values.boards,
      });
    }

    if (isDirtyDates) {
      applyDateFilter({
        startDate: dayjs(form.values.startDate).toISOString(),
        endDate: dayjs(form.values.endDate).toISOString(),
      });

      syncPortfolioFormDates(form.values.startDate, form.values.endDate, !isDirtyPortfolios);
      syncTeamFormDates(form.values.startDate, form.values.endDate, !isDirtyTeams);
      syncWorkPeriodFormDates(form.values.startDate, form.values.endDate, !isDirtyWorkPeriods);
    }

    form.resetDirty();
    setIsDirtyBoards(false);
  };

  return { boardForm, handleSubmitBoardForm };
};

const boardFormActions = createFormActions<BoardFormType>('board-form');
const syncBoardFormDates = (startDate: Date, endDate: Date, resetForms: boolean) => {
  boardFormActions.setFieldValue('startDate', startDate);
  boardFormActions.setFieldValue('endDate', endDate);

  if (resetForms) {
    boardFormActions.resetDirty();
  }
};

const useWorkPeriodForm = () => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriods = useProcessAnalysisStore((state) => state.workPeriods);
  const workPeriodId = useProcessAnalysisStore((state) => state.workPeriod?.id || null);
  const isDirtyPortfolios = useProcessAnalysisStore((state) => state.isDirtyPortfolios);
  const isDirtyTeams = useProcessAnalysisStore((state) => state.isDirtyTeams);
  const isDirtyBoards = useProcessAnalysisStore((state) => state.isDirtyBoards);
  const { startDate, endDate } = useDateRange();

  const workPeriodForm = useForm({
    name: 'work-period-form',
    initialValues: {
      workPeriodBoardId,
      workPeriodType,
      workPeriodId,
      startDate,
      endDate,
    },
    validate: {
      workPeriodBoardId: isNotEmpty('Please select a board'),
    },
  });

  const handleSubmitWorkPeriodForm = (form: WorkPeriodFormType) => {
    if (!form.values.workPeriodBoardId) {
      return;
    }

    const isDirtyData =
      form.isDirty('workPeriodId') || form.isDirty('workPeriodType') || form.isDirty('workPeriodBoardId');
    const isDirtyDates = form.isDirty('startDate') || form.isDirty('endDate');

    const defaultPayload = {
      workPeriodBoardId: form.values.workPeriodBoardId,
      workPeriodType: form.values.workPeriodType,
    };

    const payload =
      form.values.workPeriodType === WorkPeriodType.Defined
        ? { ...defaultPayload, workPeriod: workPeriods.find((p) => p.id === form.values.workPeriodId) || null }
        : { ...defaultPayload };

    if (isDirtyData) {
      applyWorkPeriodsFilter(payload);
    }

    if (isDirtyDates) {
      applyDateFilter({
        startDate: dayjs(form.values.startDate).toISOString(),
        endDate: dayjs(form.values.endDate).toISOString(),
      });

      syncPortfolioFormDates(form.values.startDate, form.values.endDate, !isDirtyPortfolios);
      syncTeamFormDates(form.values.startDate, form.values.endDate, !isDirtyTeams);
      syncBoardFormDates(form.values.startDate, form.values.endDate, !isDirtyBoards);
    }

    form.resetDirty();
    setIsDirtyWorkPeriods(false);
  };

  return { workPeriodForm, handleSubmitWorkPeriodForm };
};

const workPeriodFormActions = createFormActions<WorkPeriodFormType>('work-period-form');
const syncWorkPeriodFormDates = (startDate: Date, endDate: Date, resetForms: boolean) => {
  workPeriodFormActions.setFieldValue('startDate', startDate);
  workPeriodFormActions.setFieldValue('endDate', endDate);

  if (resetForms) {
    workPeriodFormActions.resetDirty();
  }
};

/**
 * Custom hook for managing the create portfolio modal state and behavior.
 *
 * @return {{ createPortfolioModalOpened: boolean, createPortfolioModal: object, createPortfolioModalSubmit: (newPortfolio: Portfolio) => Promise<void> }}
 * - `createPortfolioModalOpened`: A boolean indicating whether the create portfolio modal is currently open.
 * - `createPortfolioModal`: An object containing properties for managing the create portfolio modal state and behavior.
 * - `createPortfolioModalSubmit`: A function that submits the form data for creating a new portfolio.
 */
const useCreatePortfolioModal = () => {
  const [createPortfolioModalOpened, createPortfolioModal] = useDisclosure(false);
  const { portfolioForm } = usePortfolioForm();
  const { query: portfoliosQuery } = usePortfolios({ enabled: false });

  const createPortfolioModalSubmit = async (newPortfolio: Portfolio) => {
    const { data: portfolios = [] } = await portfoliosQuery.refetch();
    createPortfolioModal.close();

    if (portfolios?.length) {
      setPortfolios(portfolios);
      setPortfolio(newPortfolio);
      setPortfolioIds([newPortfolio.id]);
      portfolioForm.resetDirty();
    }
  };

  return { createPortfolioModalOpened, createPortfolioModal, createPortfolioModalSubmit };
};

/**
 * Custom hook for managing the edit portfolio modal state and behavior.
 *
 * @return {{ editPortfolioModalOpened: boolean, editPortfolioModal: object, editPortfolioModalSubmit: (updatedPortfolio: Portfolio) => Promise<void> }}
 * - `editPortfolioModalOpened`: A boolean indicating whether the edit portfolio modal is currently open.
 * - `editPortfolioModal`: An object containing properties for managing the edit portfolio modal state and behavior.
 * - `editPortfolioModalSubmit`: A function that submits the form data for updating a portfolio.
 */
const useEditPortfolioModal = () => {
  const [editPortfolioModalOpened, editPortfolioModal] = useDisclosure(false);
  const { query: portfoliosQuery } = usePortfolios({ enabled: false });

  const editPortfolioModalSubmit = async (updatedPortfolio: Portfolio) => {
    const { data: portfolios = [] } = await portfoliosQuery.refetch();

    editPortfolioModal.close();

    if (portfolios?.length) {
      setPortfolios(portfolios);
      setPortfolio(updatedPortfolio);
    }
  };

  return { editPortfolioModalOpened, editPortfolioModal, editPortfolioModalSubmit };
};

/**
 * Custom hook for managing the delete portfolio modal state and behavior.
 *
 * @return {{ deletePortfolioModalOpened: boolean, deletePortfolioModal: object, deletePortfolioModalSubmit: () => Promise<void> }}
 * - `deletePortfolioModalOpened`: A boolean indicating whether the delete portfolio modal is currently open.
 * - `deletePortfolioModal`: An object containing properties for managing the delete portfolio modal state and behavior.
 * - `deletePortfolioModalSubmit`: A function that submits the form data for deleting a portfolio.
 */
const useDeletePortfolioModal = () => {
  const [deletePortfolioModalOpened, deletePortfolioModal] = useDisclosure(false);

  const organization = useGlobalStore((state) => state.organization);
  const portfolios = useGlobalStore((state) => state.portfolios);
  const portfolioIds = useProcessAnalysisStore((state) => state.portfolioIds);

  const { query: portfoliosQuery } = usePortfolios({ enabled: false });
  const deletePortfolio = useDeletePortfolio();

  const deletePortfolioModalSubmit = async () => {
    // Only allow deleting of a single portfolio
    if (portfolioIds.length !== 1) {
      return;
    }

    const portfolioId = portfolioIds[0];
    const portfolio = portfolios.find((p) => p.id === portfolioId);
    if (!portfolio) {
      return;
    }

    await deletePortfolio.mutateAsync(portfolio);
    deletePortfolioModal.close();

    // get another portfolio to set as the current one
    const { data: newPortfolios = [] } = await portfoliosQuery.refetch();
    const filteredPortfolios = newPortfolios.filter((p) => p.organization.name === organization?.name);
    const newPortfolio = filteredPortfolios[0] || null;

    setPortfolios(newPortfolios);
    setPortfolio(newPortfolio);
    setPortfolioIds(newPortfolio ? [newPortfolio.id] : []);
  };

  return { deletePortfolioModalOpened, deletePortfolioModal, deletePortfolioModalSubmit };
};

export {
  boardFormActions,
  portfolioFormActions,
  teamFormActions,
  useBoardForm,
  useCreatePortfolioModal,
  useDeletePortfolioModal,
  useEditPortfolioModal,
  usePortfolioForm,
  useTeamForm,
  useWorkPeriodForm,
  workPeriodFormActions,
};
