/**
 * Formats an error message for invalid OTP attempts based on remaining tries
 *
 * @param {number} tries_left - Number of remaining attempts before account is disabled
 * @returns {string} Formatted error message string indicating remaining attempts
 */
const formatInvalidOTPError = (tries_left: number): string => {
  if (tries_left === 1) {
    return 'The code you entered is invalid. You have 1 final chance to enter the correct code or your account will be temporarily disabled.';
  }

  return `The code you entered is invalid. You have ${tries_left} attempts remaining.`;
};

/**
 * Formats a time in seconds into a MM:SS string format
 *
 * @param {number} time - Time in seconds to format
 * @returns {string} Time formatted as "MM:SS" with leading zeros
 */
const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export { formatInvalidOTPError, formatTime };
