import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { ActionIcon, Loader, Paper, Text, TextInput, Tooltip, UnstyledButton } from '@mantine/core';
import { useClipboard, useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { canUseAI } from '../../api/agents-client/agent-client';
import { UseAIResult } from '../../api/agents-client/agent-client.class';
import { icons } from '../../assets/icons/icons';
import { ChatHistoryPanel } from '../../components/chat-history-panel/chat-history-panel';
import { useOrganizationId } from '../../helpers/auth-helpers/auth.hooks';
import { addOpacityToColor } from '../../helpers/string-helpers/string-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { setUseAI as setUseAIInGlobalStore } from '../../store/global-store/global-store.actions';
import { newCOLORS } from '../../styles/colors';
import { useWebsocketHandlers } from './insights.hooks';
import { ChatHistory } from './insights.type';

export function Insights() {
  const { useAI } = useGlobalStore();
  const orgId = useOrganizationId();
  const [isShowChat, { toggle: toggleShowChat }] = useDisclosure(false);
  const [isActiveQuestion, setIsActiveQuestion] = useState(false);
  const [messages, setMessages] = useState<string[]>(['']);
  const [question, setQuestion] = useState('');
  const [threadId, setThreadId] = useState('');
  const [questions, setQuestions] = useState<string[]>([]);
  const [isHistoryOpen, { open: openHistory, close: closeHistory }] = useDisclosure(false);
  const [chatHistories, setChatHistories] = useState<ChatHistory[]>([]);
  const [isWaitingForFirstResponse, setIsWaitingForFirstResponse] = useState(false);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const clipboard = useClipboard();

  const handleNewChat = useCallback(() => {
    setThreadId('');
    setQuestions([]);
    setMessages(['']);
  }, [setThreadId, setQuestions, setMessages]);

  const { runAgent, fetchChatHistories, handleDeleteHistory, handleEditHistory } = useWebsocketHandlers(
    orgId,
    useAI,
    threadId,
    setThreadId,
    setChatHistories,
    setIsActiveQuestion,
    setIsWaitingForFirstResponse,
    setMessages,
    handleNewChat
  );

  useQuery(['canUseAI'], () => canUseAI(), {
    onSuccess: (response: UseAIResult) => {
      setUseAIInGlobalStore(response.canUseAI);
    },
    onError: () => console.error('There was an issue determining if the user can use AI'),
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    const cleanupFetch = fetchChatHistories();
    if (cleanupFetch) {
      return cleanupFetch;
    }
  }, [isHistoryOpen, fetchChatHistories]);

  useEffect(() => {
    if (threadId && chatHistories.length > 0) {
      const selectedHistory = chatHistories.find((history) => history.id === threadId);
      if (selectedHistory) {
        const previousQuestions = selectedHistory.messages
          .filter((msg) => msg.role === 'user')
          .map((msg) => msg.content);
        const previousAnswers = selectedHistory.messages
          .filter((msg) => msg.role === 'assistant')
          .map((msg) => msg.content);

        setQuestions(previousQuestions);
        setMessages(previousAnswers.concat(['']));
      }
    }
  }, [threadId, chatHistories]);

  const autoScroll = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    autoScroll();
  }, [messages, questions]);

  const submitQuestion = async () => {
    if (question) {
      const cleanupAgent = runAgent(question);
      setQuestions([...questions, question]);
      setQuestion('');
      if (cleanupAgent) {
        return cleanupAgent;
      }
    }
  };

  return (
    <div>
      {useAI ? (
        <UnstyledButton onClick={toggleShowChat} style={{}}>
          <img src={icons.iconBloomfilter} width={32} height={32} />
        </UnstyledButton>
      ) : null}
      {useAI && isShowChat ? (
        <FloraContainer>
          <Header>
            <TitleSection>
              <img src={icons.iconBloomfilter} width={32} height={32} style={{ marginRight: 10 }} />
              <h1 style={{ fontSize: 30, fontWeight: 600, marginTop: 10 }}>Flora - AI Assistant</h1>
            </TitleSection>
            <ControlsSection>
              <Tooltip label={isActiveQuestion ? 'Please wait for response...' : 'New Chat'}>
                <ControlButton onClick={handleNewChat} disabled={isActiveQuestion}>
                  <Icon icon="material-symbols:add" />
                </ControlButton>
              </Tooltip>
              <Tooltip label={isActiveQuestion ? 'Please wait for response...' : 'Chat History'}>
                <ControlButton onClick={isHistoryOpen ? closeHistory : openHistory} disabled={isActiveQuestion}>
                  <Icon icon="material-symbols:history" />
                </ControlButton>
              </Tooltip>
              <Tooltip label="Close Flora">
                <ControlButton onClick={toggleShowChat}>
                  <Icon icon="fluent:panel-right-contract-24-regular" />
                </ControlButton>
              </Tooltip>
            </ControlsSection>
          </Header>
          <MessageContainer>
            {questions.map((question, index) => (
              <MessageWrapper key={index}>
                <QuestionPaper shadow="sm" p="md">
                  <Text size="md">{question}</Text>
                </QuestionPaper>
                {messages[index] && (
                  <AnswerPaper shadow="sm" p="md">
                    <Tooltip label={clipboard.copied ? 'Copied!' : 'Copy to clipboard'}>
                      <CopyButton onClick={() => clipboard.copy(messages[index])}>
                        <Icon
                          icon={clipboard.copied ? 'material-symbols:check' : 'material-symbols:content-copy-outline'}
                          style={{
                            color: newCOLORS.black,
                            width: 20,
                            height: 20,
                          }}
                        />
                      </CopyButton>
                    </Tooltip>
                    <Markdown>{messages[index]}</Markdown>
                  </AnswerPaper>
                )}
              </MessageWrapper>
            ))}
            {isWaitingForFirstResponse && (
              <LoadingWrapper>
                <Loader size="sm" color={newCOLORS.purple2} />
                <LoadingText>Flora is thinking...</LoadingText>
              </LoadingWrapper>
            )}
            <div ref={messagesEndRef} />
          </MessageContainer>
          <TextInput
            size="lg"
            style={{
              border: '0px',
            }}
            onChange={(e) => {
              setQuestion(e.currentTarget.value);
              if (isHistoryOpen) {
                closeHistory();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isActiveQuestion) {
                submitQuestion();
              }
            }}
            value={question}
            placeholder="Ask a question about your data...."
            rightSectionWidth={42}
            leftSection={<Icon icon="material-symbols:chat" />}
            rightSection={
              <ActionIcon
                disabled={isActiveQuestion}
                size={32}
                radius="xl"
                color={newCOLORS.purple2}
                variant="filled"
                onClick={submitQuestion}
              >
                <Icon icon="material-symbols:arrow-right-alt" />
              </ActionIcon>
            }
          />
        </FloraContainer>
      ) : null}
      {isShowChat && isHistoryOpen && (
        <ChatHistoryPanel
          histories={chatHistories}
          closeHistory={closeHistory}
          setThreadId={setThreadId}
          onDeleteHistory={handleDeleteHistory}
          onEditHistory={handleEditHistory}
        />
      )}
    </div>
  );
}

const FloraContainer = styled.div`
  position: fixed;
  bottom: 40px;
  right: 30px;
  width: 500px;
  height: 83.7vh;
  max-height: 1000px;
  overflow: hidden;
  border: 1px solid black;
  background: ${() => addOpacityToColor(newCOLORS.white, 0.95)};
  box-shadow: 0px 40px 40px 8px ${() => addOpacityToColor(newCOLORS.black, 0.5)};
  border-radius: 10px;
  padding: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ControlsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const MessageContainer = styled.div`
  flex: 1;
  overflow: auto;
  margin-bottom: 20px;
`;

export const ControlButton = styled(UnstyledButton)`
  font-size: 21px;
  color: ${newCOLORS.black};
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    color: ${newCOLORS.gray};
    cursor: not-allowed;
  }
`;

const QuestionPaper = styled(Paper)`
  max-width: 80%;
  margin-left: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 10px;
  background-color: ${newCOLORS.purple2};
  color: white;
`;

const AnswerPaper = styled(Paper)`
  max-width: 80%;
  margin-right: auto;
  margin-top: 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 0;
  background-color: ${newCOLORS.darkestGray};
  position: relative;
`;

const CopyButton = styled(ActionIcon)`
  float: right;
  background-color: transparent;

  &:hover {
    background-color: transparent !important;
  }
`;

const MessageWrapper = styled.div`
  margin-bottom: 16px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  max-width: 80%;
  margin-right: auto;
`;

const LoadingText = styled(Text)`
  color: ${newCOLORS.gray};
  font-size: 14px;
  font-style: italic;
`;
