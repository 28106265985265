import { styled } from '@linaria/react';
import { Fragment } from 'react/jsx-runtime';
import { TaskFlowHistoryType } from '../../api/sprints-client/sprints-client.type';
import { MenuDrivenContainer } from '../../containers/menu-driven-container/menu-driven-container';
import { BrandedLoadingOverlay } from '../loader/branded-loader';
import { TaskFlowBoard } from '../task-flow-history/task-flow-history-board';

type TaskFlowProps = {
  taskFlowData: TaskFlowHistoryType | undefined;
  startDate: Date;
  endDate: Date;
  isFetching?: boolean;
};

export const TaskFlow = ({ taskFlowData, startDate, endDate, isFetching }: TaskFlowProps) => {
  return (
    <Fragment>
      <MenuDrivenContainer header="Task Flow">
        <TaskFlowContainer>
          <div>
            <BrandedLoadingOverlay visible={isFetching || false} transitionDuration={30} variant="colored" />
            {taskFlowData && startDate && endDate && (
              <TaskFlowBoard history={taskFlowData} startDate={startDate} endDate={endDate} />
            )}
          </div>
        </TaskFlowContainer>
      </MenuDrivenContainer>
    </Fragment>
  );
};

const TaskFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
