import { styled } from '@linaria/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { MenuDrivenContainer } from '../../containers/menu-driven-container/menu-driven-container';
import { TasksTable } from '../../containers/tasks-table/tasks-table';
import { TaskRecord } from '../../containers/tasks-table/tasks-table.type';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { BrandedLoadingOverlay } from '../loader/branded-loader';
import { PageHeaderMessage } from '../page-header-message/page-header-message';
import { Title } from '../title/title';
import { formatTasks, makeTitle, useFinancialsTasksScope } from './financials-tasks.helpers';
import { useAllocationOfCostsTasks, useCostByInitiativeTasks, useCostVsOutputTasks } from './financials-tasks.hooks';

export const FinancialsTasks = () => {
  useDocumentTitle('Task Details - Bloomfilter');
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure');

  const useTasksQuery =
    measure === 'cost_allocation'
      ? useAllocationOfCostsTasks
      : measure === 'cost_by_initiative'
      ? useCostByInitiativeTasks
      : useCostVsOutputTasks;

  const scope = useFinancialsTasksScope(portfolioId);
  const { projectId, date, factor, factorOption, startDate, endDate } = scope;

  const { data: tasksQueryData = [], query: tasksQuery } = useTasksQuery(
    { portfolioId, projectId, date, factor, factorOption, startDate, endDate },
    {
      enabled: !!(portfolioId && startDate && endDate && date && factor),
    }
  );

  const showLocalError = !tasksQuery.isFetching && !tasksQueryData.length;

  const header = <Title title={makeTitle(scope)} />;

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={tasksQuery.isLoading} transitionDuration={30} variant="colored" />
      <TasksContainer>
        {showLocalError && <PageHeaderMessage message="There are no related tasks for this selection." color="red" />}
        <TasksTable tasks={formatTasks(tasksQueryData as TaskRecord[])} />
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
