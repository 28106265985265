import { useState } from 'react';
import { ResetPasswordForm } from './reset-password-form';
import { ResetPasswordSuccess } from './reset-password-success';
import { ResetPasswordView } from './reset-password.type';

export const ResetPassword = () => {
  const [view, setView] = useState<ResetPasswordView>(ResetPasswordView.Form);

  if (view === ResetPasswordView.Success) {
    return <ResetPasswordSuccess />;
  }

  return <ResetPasswordForm setView={setView} />;
};
