enum ValidationError {
  InvalidEmail = 'This email address is not valid. Please check and re-enter.',
  EmailExists = 'An account with this email already exists. Consider logging in or using another email.',
  PasswordTooShort = 'This password is too short. It must contain at least 8 characters.',
  PasswordMissingLowercase = 'Please ensure your password contains at least one lowercase letter.',
  PasswordMissingUppercase = 'Please ensure your password contains at least one uppercase letter.',
  PasswordMissingNumber = 'Please ensure your password contains at least one number.',
  PasswordMissingSpecial = 'Please ensure your password contains at least one special character.',
  PasswordContainsSpaces = 'Please ensure your password does not have any spaces.',
  PasswordMismatch = 'Please re-enter your passwords to ensure they match.',
  InvalidCredentials = 'Invalid email or password. Please try again or create a new account.',
  NameTooLong = `Name cannot be more than 256 characters long.`,
  InvalidNameCharacters = `Name can only contain letters, numbers, hyphens (-), underscores (_), plus signs (+), at signs (@), spaces, apostrophes ('), periods (.) or commas (,). It cannot begin or end with a period, hyphen, or space.`,
  ConsecutivePeriods = `Name cannot contain consecutive periods.`,
  SSOInvalidAuthentication = 'Invalid Authentication Provided. Please contact your administrator.',
  SSOInvalidResponse = 'Invalid Response From Provider. Please contact your administrator.',
}

export { ValidationError };
