import { Flex } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { useResendOTP } from '../../../../api/auth-client/auth-client.hooks';
import { useAuthStore } from '../../../../store/auth-store/auth-store';
import { fontSizeSmall, redBase, secondaryLight } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { CountdownTimer } from './countdown-timer';

type Props = {
  accountDisabled: boolean;
  otpExpired: boolean;
  setOTPExpired: (value: boolean) => void;
  error: React.ReactNode;
};

export const VerificationStatus = ({ accountDisabled, otpExpired, setOTPExpired, error }: Props) => {
  const { email } = useAuthStore();
  const resendOTPQuery = useResendOTP(email, { enabled: false });

  const resendOTP = async () => {
    try {
      await resendOTPQuery.refetch();
      setOTPExpired(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (accountDisabled) {
    return (
      <Flex h={40} direction="column" align="flex-end">
        <Text size="small" lineHeight="tight" weight="medium" color={redBase} style={{ textAlign: 'right' }}>
          Your account is disabled after five incorrect verification attempts.
        </Text>
        <Text size="small" lineHeight="tight" weight="medium" style={{ textAlign: 'right' }}>
          Contact our Customer Success Team for help.
        </Text>
      </Flex>
    );
  }

  if (otpExpired) {
    return (
      <Flex h={40} direction="column" align="flex-end">
        <Text size="small" lineHeight="tight" color={redBase} style={{ textAlign: 'right' }}>
          Your verification code has expired.
        </Text>
        <span
          style={{ fontSize: fontSizeSmall, color: secondaryLight, textAlign: 'right', cursor: 'pointer' }}
          onClick={() => resendOTP()}
        >
          Request a new code to continue.
        </span>
      </Flex>
    );
  }

  return (
    <Flex h={40} direction="column" align="flex-end">
      <Text size="small" lineHeight="tight" color={redBase} style={{ textAlign: 'right' }}>
        {error}
      </Text>
      <CountdownTimer initialTime={import.meta.env.VITE_APP_OTP_RESEND_TIMEOUT} onResend={() => resendOTP()} />
    </Flex>
  );
};
