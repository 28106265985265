import { AppShell } from '@mantine/core';
import { ReactNode, useContext } from 'react';
import { HeaderBar } from '../header/header';
import { SideBar } from '../side-bar/side-bar';
import { SidebarContext } from '../side-bar/side-bar.context';

type MenuDrivenContainerProps = {
  children: ReactNode;
  header?: string | ReactNode;
};

export const MenuDrivenContainer = ({ children, header }: Partial<MenuDrivenContainerProps>) => {
  const { sidebarOpen } = useContext(SidebarContext);

  return (
    <AppShell
      layout="alt"
      header={{ height: 76 }}
      navbar={{ width: sidebarOpen ? 280 : 70, breakpoint: 'sm' }}
      padding={16}
      withBorder={false}
      transitionDuration={0}
    >
      <HeaderBar header={header} />
      <SideBar />
      <AppShell.Main style={{ boxSizing: 'border-box' }}>{children}</AppShell.Main>
    </AppShell>
  );
};
