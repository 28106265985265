import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { OutOfBloom } from '../../components/out-of-bloom/out-of-bloom';
import { ProtectedRoute } from '../../components/protected-route/protected-route';
import { Setup } from '../../containers/setup/setup';
import { GlobalProvider, TeamProvider, UserProvider } from '../../contexts';
import { GlobalFilterProvider } from '../../contexts/global-filter';
import { theme } from '../../styles/theme';
import { IntegrationsProvider } from '../integrations/context/integrations.context';
import { NavigationContainer } from '../navigation/navigation';
import { CreatePassword } from '../onboarding/reset-password/create-password';
import { ResetPassword } from '../onboarding/reset-password/reset-password';
import { SignIn as SignInV2 } from '../onboarding/sign-in/sign-in';
import { SignUp } from '../onboarding/sign-up/sign-up';
import { ProcessProvider } from '../process/context/process.context';
import { SidebarProvider } from '../side-bar/side-bar.context';
import { SignIn as SignInV1 } from '../sign-in/sign-in';
import { ViewName } from '../sign-in/sign-in.type';
import { StrategyProvider } from '../strategy/context/strategy.context';

const showNewOnboarding = import.meta.env.VITE_FEATURE_FLAG_ONBOARDING_V2 === 'true';

export function App() {
  return (
    <Router>
      <ToastContainer theme="colored" />
      <MantineProvider theme={theme} defaultColorScheme="light">
        <QueryClientProvider
          client={
            new QueryClient({
              defaultOptions: {
                queries: {
                  retry: parseInt(import.meta.env.REACT_QUERY_RETRY_TIMES as string),
                  refetchOnWindowFocus: false,
                },
              },
            })
          }
        >
          <UserProvider>
            <GlobalProvider>
              <GlobalFilterProvider>
                <TeamProvider>
                  <IntegrationsProvider>
                    <StrategyProvider>
                      <ProcessProvider>
                        <SidebarProvider>
                          <Routes>
                            <Route path="/" element={showNewOnboarding ? <SignInV2 /> : <SignInV1 />} />
                            {showNewOnboarding && <Route path="/sign-up" element={<SignUp />} />}
                            {showNewOnboarding && <Route path="/reset-password" element={<ResetPassword />} />}
                            <Route
                              path="/password/reset/confirm/:uid/:token/:email"
                              element={
                                showNewOnboarding ? <CreatePassword /> : <SignInV1 view={ViewName.ResetPassword} />
                              }
                            />
                            <Route
                              path="/application/setup"
                              element={
                                <ProtectedRoute>
                                  <Setup />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="/application/*"
                              element={
                                <ProtectedRoute>
                                  <NavigationContainer />
                                </ProtectedRoute>
                              }
                            />
                            <Route path="*" element={<OutOfBloom />} />
                          </Routes>
                        </SidebarProvider>
                      </ProcessProvider>
                    </StrategyProvider>
                  </IntegrationsProvider>
                </TeamProvider>
              </GlobalFilterProvider>
            </GlobalProvider>
          </UserProvider>
        </QueryClientProvider>
      </MantineProvider>
    </Router>
  );
}
