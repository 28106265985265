import { styled } from '@linaria/react';
import { Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import * as Sentry from '@sentry/browser';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../../../api/auth-client/auth-client';
import { checkValidEmail } from '../../../../helpers';
import { trackEvent } from '../../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../../helpers/analytics-event/analytics-event.type';
import { setCredentials, setSignUpInitialized, setSignUpStep } from '../../../../store/auth-store/auth-store.actions';
import { SignUpStep } from '../../../../store/auth-store/auth-store.type';
import { HEIGHT_BREAKPOINTS } from '../../../../styles/breakpoints';
import { baseWhite, inkLight, secondaryBase } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { H3, Text } from '../../../../ui-library/typography/typography';
import { Layout } from '../../layout';
import { ValidationError } from '../sign-up.type';
import { CreateAccountForm } from './create-account-form';
import { checkName, checkPassword } from './create-account.helpers';

export const CreateAccount = () => {
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      email: (value) => (checkValidEmail(value) ? null : ValidationError.InvalidEmail),
      password: (value) => checkPassword(value),
      name: (value) => checkName(value),
      confirmPassword: (value, values) => (value === values.password ? null : ValidationError.PasswordMismatch),
    },
  });

  const { email, name, password } = form.getValues();

  const signUp = useMutation(['signup'], () => signup({ email, name, password }), {
    onSuccess: () => {
      setCredentials(email, password);
      setSignUpInitialized(true);
      setSignUpStep(SignUpStep.CheckYourEmail);
    },
    onError: ({ error }: { error: { email?: string[]; name?: string[]; password?: string[] } }) => {
      if (!error) {
        form.setErrors({ form: 'An unexpected error occurred' });
        return;
      }
      const { email, name, password } = error;
      const emailError = email?.pop();
      const nameError = name?.pop();
      const passwordError = password?.pop();
      if (emailError) {
        form.setFieldError('email', emailError);
      }
      if (nameError) {
        form.setFieldError('name', nameError);
      }
      if (passwordError) {
        form.setFieldError('password', passwordError);
      }
    },
  });

  const handleSubmit = async () => {
    trackEvent(AnalyticsEventType.SignUpTapped, { userContext: { email: email } });

    try {
      await signUp.mutateAsync();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Layout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <FormStack>
          <HeaderGroup>
            <Icon name="person_add_alt" size={32} />
            <StyledH3>Create Account</StyledH3>
          </HeaderGroup>
          <CreateAccountForm form={form} />
          <Divider />
          <ButtonStack>
            <Button
              type="submit"
              loaderProps={{ size: 'xs', color: secondaryBase }}
              w="100%"
              radius="xl"
              rightSection={<Icon name="arrow_forward" size={18} color={baseWhite} />}
            >
              Create Account
            </Button>
            <Text size="small" weight="medium">
              Already have an account?
            </Text>
            <Button
              variant="outline"
              onClick={() => navigate('/')}
              w="100%"
              radius="xl"
              rightSection={<Icon name="login" size={18} color={inkLight} />}
            >
              Log in to Bloomfilter
            </Button>
          </ButtonStack>
        </FormStack>
      </form>
    </Layout>
  );
};

const FormStack = styled(Stack)`
  gap: 24px;
  min-height: 400px;

  @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
    gap: 16px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    gap: 12px;
    overflow-y: auto;
  }
`;

const HeaderGroup = styled(Group)`
  gap: 16px;

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    gap: 12px;
  }
`;

const StyledH3 = styled(H3)`
  @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
    font-size: 20px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    font-size: 18px;
  }
`;

const ButtonStack = styled(Stack)`
  gap: 24px;
  align-items: center;

  @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
    gap: 16px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    gap: 12px;

    button {
      height: 36px;
      font-size: 14px;
    }
  }
`;
