import { Group, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../../store/auth-store/auth-store';
import { resetAuthStore } from '../../../../store/auth-store/auth-store.actions';
import { baseWhite, primaryBase } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { H3, Text } from '../../../../ui-library/typography/typography';
import { Layout } from '../../layout';

export const Success = () => {
  const navigate = useNavigate();
  const organizationName = useAuthStore((state) => state.organizationName);

  return (
    <Layout>
      <Stack gap={32}>
        <Group gap={16}>
          <Icon name="check_circle" size={32} color={primaryBase} variant="filled" />
          <H3>Success!</H3>
        </Group>
        <Text>
          Your <b>{organizationName}</b> organization has been created. You can update its information anytime in the
          settings.
        </Text>
        <Divider />
        <Button
          onClick={() => {
            resetAuthStore();
            navigate('/application/integrations');
          }}
          w="100%"
          radius="xl"
          rightSection={<Icon name="arrow_forward" size={18} color={baseWhite} />}
        >
          Start Your First Integration
        </Button>
      </Stack>
    </Layout>
  );
};
