import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getAllocationOfCostsChartData } from './financials-client';
import { AllocationOfCostsChartRequest, AllocationOfCostsChartResponseByCategory } from './financials-client.type';

export function useAllocationOfCostsMeasures(
  { portfolioId, teamId, startDate, endDate }: AllocationOfCostsChartRequest,
  options?: UseQueryOptions<AllocationOfCostsChartResponseByCategory>,
  successCallback?: (data: AllocationOfCostsChartResponseByCategory) => void
) {
  const query = useQuery({
    queryKey: ['allocation-of-costs', portfolioId, teamId, startDate, endDate],
    queryFn: () =>
      (portfolioId || teamId) && startDate && endDate
        ? getAllocationOfCostsChartData(portfolioId, startDate, endDate, teamId)
        : Promise.reject('Unable to fetch allocation of costs; missing required parameters'),
    ...options,
  });

  if (query.isSuccess && query.data && successCallback) {
    successCallback(query.data);
  }

  return { chartData: query.data, query };
}
