type SidebarContextType = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navItems: NavItem[];
  windowViewportWidth: number;
  setWindowViewportWidth: React.Dispatch<React.SetStateAction<number>>;
  viewAreaWidth: number;
  setViewAreaWidth: React.Dispatch<React.SetStateAction<number>>;
};

type NavItem = {
  label: GeneralViewNames;
  icon: string;
  path: string;
  show: boolean;
};

enum GeneralViewNames {
  Portfolio = 'Portfolio',
  Strategy = 'Strategy',
  Financials = 'Financials',
  Process = 'Process Map',
  ProcessAnalysis = 'Process Analysis',
  Integrations = 'Integrations',
}

export { GeneralViewNames };
export type { NavItem, SidebarContextType };
