import { Size } from './avatar.type';

/**
 * Returns the size in pixels as a string based on the given size.
 *
 * @param {Size} size - The size of the element.
 * @return {string} The size in pixels.
 */
const getSize = (size: Size): string => {
  switch (size) {
    case 'xs':
      return '16px';
    case 'sm':
      return '26px';
    case 'md':
      return '38px';
    case 'lg':
      return '56px';
    case 'xl':
      return '84px';
    default:
      return '38px';
  }
};

/**
 * Returns the CSS border-radius variable based on the given radius value.
 *
 * @param {Size} radius - The radius value.
 * @return {string} The CSS border-radius variable.
 */
const getRadius = (radius: Size): string => {
  switch (radius) {
    case 'xs':
      return 'var(--border-radius-none)';
    case 'sm':
      return 'var(--border-radius-xtiny)';
    case 'md':
      return 'var(--border-radius-xxsmall)';
    case 'lg':
      return 'var(--border-radius-small)';
    case 'xl':
      return 'var(--border-radius-xlarge)';
    default:
      return 'var(--border-radius-xxsmall)';
  }
};

/**
 * Returns the size of the icon in pixels based on the given size.
 *
 * @param {Size} size - The size of the element.
 * @return {number} The size of the icon in pixels.
 */
const getIconSize = (size: Size): number => {
  switch (size) {
    case 'xs':
      return 12;
    case 'sm':
      return 16;
    case 'md':
      return 24;
    case 'lg':
      return 32;
    case 'xl':
      return 48;
    default:
      return 18;
  }
};

export { getIconSize, getRadius, getSize };
