enum SignUpStep {
  CreateAccount = 'create-account',
  TermsAndConditions = 'terms-and-conditions',
  CheckYourEmail = 'check-your-email',
  CreateOrganization = 'create-organization',
  Success = 'success',
}

enum SignInStep {
  Login = 'login',
  SelectOrganization = 'select-organization',
}

type State = {
  signUpInitialized: boolean;
  signUpStep: SignUpStep;
  signInStep: SignInStep;
  email: string | null;
  password: string | null;
  organizationName: string | null;
};

export { SignInStep, SignUpStep };
export type { State };
