import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSprint } from '../../api/sprints-client/sprints-client';
import { Sprint } from '../../api/sprints-client/sprints-client.type';
import { fetchTaskAuditLog } from '../../api/tasks-client/tasks-client';
import { AuditLog } from '../../components/audit-log/audit-log';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const TaskAuditLog = () => {
  const { taskId = '' } = useParams<{ taskId: string }>();
  const { sprintId = '' } = useParams<{ sprintId: string }>();
  const [sprint, setSprint] = useState<Sprint | null>(null);

  const auditLogQuery = useQuery(
    ['taskId', taskId],
    taskId ? () => fetchTaskAuditLog(taskId) : () => Promise.reject('Task ID is required'),
    {
      onError: ({ error }: { error: any }) => {
        return Promise.reject(error?.response?.data || 'Error whiel fetching task audit log');
      },
    }
  );
  useQuery(
    ['sprint', sprintId],
    () => (sprintId ? fetchSprint(sprintId) : Promise.reject('Unable to resolve SprintId or SprintID is missing')),
    {
      enabled: !!sprintId,
      onSuccess: setSprint,
      onError: () => console.error('There was an issue fetching sprint data.'),
    }
  );

  return (
    <MenuDrivenContainer header="Task Audit Log">
      <BrandedLoadingOverlay visible={auditLogQuery.isLoading} transitionDuration={300} variant="colored" />
      {auditLogQuery.data && <AuditLog sprint={sprint} entries={auditLogQuery.data} isTaskLog={true} />}
    </MenuDrivenContainer>
  );
};
