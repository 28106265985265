import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { Measure } from '../../api/work-periods-client/work-periods-client.type';
import { formatTasks } from '../../components/financials-tasks/financials-tasks.helpers';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { Title } from '../../components/title/title';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { useDateRange, useTimeAllocations } from '../../store/process-analysis-store/process-analysis-store.hooks';
import { SubtitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { TasksTable } from '../tasks-table/tasks-table';
import { TaskRecord } from '../tasks-table/tasks-table.type';
import { formatTitle, mapSubprojectIdsToNames } from './process-analysis-tasks.helpers';
import { useTasksData, useTimeAllocationsData } from './process-analysis.hooks';
import { Tab, WorkPeriodType } from './process-analysis.type';
import { useWorkPeriodForm } from './scope-explorer/scope-explorer.hooks';

export const ProcessAnalysisTasks = () => {
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure');
  const date = searchParams.get('date') || null;
  const factor = searchParams.get('factor') || null;

  const teams = useGlobalStore((state) => state.teams);
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);

  const { workPeriodType, workPeriod } = useProcessAnalysisStore((state) => ({
    workPeriodType: state.workPeriodType,
    workPeriod: state.workPeriod,
  }));
  const { startDate, endDate } = useDateRange();

  const idNameMap = mapSubprojectIdsToNames(teams);
  const { workPeriodForm } = useWorkPeriodForm();
  const currentBoardId = workPeriodForm.getValues().workPeriodBoardId;
  const currentBoardName = idNameMap[currentBoardId as string];

  useTimeAllocationsData();
  const timeAllocations = useTimeAllocations();
  const foundAllocation = timeAllocations.find((allocation) => allocation.id === date);
  const formattedStartDate = dayjs(startDate).format('MM/DD/YYYY');
  const formattedEndDate = dayjs(endDate).format('MM/DD/YYYY');

  const taskFilter = date && factor ? `${date},${factor}` : date || factor;
  const { data: tasksQueryData = [], isFetching } = useTasksData(measure as Measure, taskFilter);

  const showLocalError = !isFetching && !tasksQueryData.length;
  const titleMeasure = measure === Measure.FlowByPhase ? factor || '' : measure || '';

  const header = <Title title={formatTitle(titleMeasure, workPeriodType, workPeriod, currentBoardName, activeTab)} />;

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={30} variant="colored" />
      <TasksContainer>
        <SubtitleHeadingTag>
          {date == null ? (
            <>
              {workPeriodType === WorkPeriodType.Defined
                ? `${dayjs(workPeriod?.start_date).format('MM/DD/YYYY')} - ${dayjs(workPeriod?.end_date).format(
                    'MM/DD/YYYY'
                  )}`
                : `${formattedStartDate} - ${formattedEndDate}`}
            </>
          ) : activeTab === Tab.WorkPeriods ? (
            `${dayjs(date).format('MM/DD/YYYY')}`
          ) : (
            `${dayjs(foundAllocation?.start).format('MM/DD/YYYY')} - ${dayjs(foundAllocation?.end).format(
              'MM/DD/YYYY'
            )}`
          )}
        </SubtitleHeadingTag>
        {showLocalError && <PageHeaderMessage message="There are no related tasks for this selection." color="red" />}
        <TasksTable tasks={formatTasks(tasksQueryData as TaskRecord[])} />
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 16px;
  padding-left: 16px;
`;
