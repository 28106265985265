import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const InitiativeCompletionUnrolledPieChart: React.FC<{ data: { label: string; value: number; color: string }[] }> = ({
  data,
}) => {
  const chartData = {
    labels: [''],
    datasets: data.map((segment) => ({
      label: segment.label,
      data: [segment.value],
      backgroundColor: segment.color,
      barThickness: 5, // Adjust to control bar height
    })),
  };

  const options = {
    maintainAspectRatio: false, // Disable the default aspect ratio
    indexAxis: 'y' as const,
    layout: {
      padding: {
        bottom: 10,
      },
    },
    scales: {
      x: {
        stacked: true,
        max: 100,
        display: false, // Hide the X-axis
        grid: {
          display: false, // Hide X-axis grid lines
        },
      },
      y: {
        stacked: true,
        display: false, // Hide the Y-axis
        grid: {
          display: false, // Hide Y-axis grid lines
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed.x;
            return `${label}: ${value}%`;
          },
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  return (
    <div style={{ height: '25px', width: '100%' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export { InitiativeCompletionUnrolledPieChart };
