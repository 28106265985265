import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { storeAuthenticationObject } from '../../helpers/storage/storage';
import { LoginResult, RefreshResult, ResetPasswordResult, SetPasswordResult } from './auth-client.class';
import {
  DomainCheckResponse,
  LoginRequest,
  LoginResponse,
  OTPResponse,
  RefreshResponse,
  ResetPasswordResponse,
  SetPasswordResponse,
  SignUpPayload,
} from './auth-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/auth/';

/**
 * Logs a user in. Requires a email / password in the result.
 * @param body
 * @returns LoginResult(data)
 */
async function login(body: LoginRequest) {
  const apiURL = baseURL + 'jwt/create/';
  const postBody = { email: body.email.toLowerCase(), password: body.password };
  return await axiosInstance
    .post<LoginResponse>(apiURL, postBody)
    .then((response) => {
      storeAuthenticationObject(response.data);
      return new LoginResult(response.data);
    })
    .catch((error) => {
      const errorMessage = error.response.data;
      return errorMessage;
    });
}

/**
 * Checks the domain for a user / organization
 * @param body
 * @returns payload with whether or not to do an redirect or not
 */
async function checkDomain(domain: string): Promise<DomainCheckResponse> {
  const apiURL = import.meta.env.VITE_APP_API_URL + `/api/openid/check_domain/?domain=${domain}`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => error.response.data);
}

/**
 * Registers a new user.
 *
 * @param {SignUpPayload} payload - The signup details including email and password.
 * @returns {Promise<SignUpPayload>} A promise that resolves with the signup payload if successful.
 *          Rejects with an error object if the request fails.
 */
async function signup(payload: SignUpPayload): Promise<SignUpPayload> {
  const apiURL = baseURL + 'users/';
  return await axiosInstance
    .post(apiURL, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Sets password for given email and code, with new password
 * @param email - email address to resend code to
 * @param code - verification code
 * @param newPassword - new password to set for user
 * @returns ResendCodeResult(data)
 */
async function setPassword(email: string, code: string, newPassword: string) {
  const apiURL = baseURL + 'users/reset_password_confirm/';
  const postBody = { uid: email.toLowerCase(), token: code, new_password: newPassword, re_new_password: newPassword };
  return await axiosInstance
    .post<SetPasswordResponse>(apiURL, postBody)
    .then((response) => {
      return new SetPasswordResult(response.status);
    })
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Sends password reset code to provided email address
 * @param email - email address to resend code to
 * @returns ResetPasswordResult(data)
 */
async function resetPassword(email: string) {
  const apiURL = baseURL + 'users/reset_password/';
  const postBody = { email: email.toLowerCase() };
  return await axiosInstance
    .post<ResetPasswordResponse>(apiURL, postBody)
    .then((response) => {
      return new ResetPasswordResult(response.status);
    })
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Refreshes an auth token
 * @param token - token to be refreshed
 * @returns RefreshResult(data)
 */
async function refreshToken(token: string) {
  const apiURL = baseURL + 'jwt/refresh/';
  const postBody = { refresh: token };
  return await axiosInstance
    .post<RefreshResponse>(apiURL, postBody)
    .then((response) => {
      return new RefreshResult(response.data);
    })
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Verifies a one-time password (OTP) for a given email
 *
 * @param {string} email - Email address associated with the OTP
 * @param {string} otp - One-time password code to verify
 * @returns {Promise<OTPResponse>} Promise containing success/error message
 */
async function verifyOTP(email: string, otp: string): Promise<OTPResponse> {
  const apiURL = `${import.meta.env.VITE_APP_API_URL}/api/otp/verify`;
  const params = { email, otp };

  return await axiosInstance
    .get(apiURL, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

/**
 * Resends a one-time password (OTP) to the specified email address
 *
 * @param {string} email - Email address to resend the OTP to
 * @returns {Promise<OTPResponse>} Promise containing success/error message
 */
async function resendOTP(email: string): Promise<OTPResponse> {
  const apiURL = `${import.meta.env.VITE_APP_API_URL}/api/otp/resend`;
  const params = { email };

  return await axiosInstance
    .get(apiURL, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response.data }));
}

export { checkDomain, login, refreshToken, resendOTP, resetPassword, setPassword, signup, verifyOTP };
