import { ReactNode, createContext, useState } from 'react';
import { Team } from '../api/projects-client/projects-client.type';
import { TeamContextType } from './scope.type';

export const TeamContext = createContext({} as TeamContextType);

export const TeamProvider = ({ children }: { children: ReactNode }) => {
  const [team, setTeam] = useState<Team | null>(null);

  return <TeamContext.Provider value={{ team, setTeam }}>{children}</TeamContext.Provider>;
};
