import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse, Switch, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useContext } from 'react';
import { Initiative, InitiativeStatus } from '../../../api/initiative-client/initiative-client.type';
import { icons } from '../../../assets/icons/icons';
import { newCOLORS } from '../../../styles/colors';
import { StrategyContext } from '../context/strategy.context';
import { Section } from '../context/strategy.context.type';
import { InitiativeCompletionUnrolledPieChart } from './initiative-completion-unrolled-pie-chart';
import { isActiveInitiative } from './initiatives-list.helpers';
import { InitiativesTable } from './initiatives-table';

type Props = {
  initiatives: Initiative[];
  portfolioId: string;
};

export function InitiativesList({ initiatives, portfolioId }: Props) {
  return (
    <PageContainer>
      <CollapsibleSection
        id={Section.ActiveInitiatives}
        title="Active Initiatives"
        initiatives={initiatives.filter((initiative) => isActiveInitiative(initiative))}
        portfolioId={portfolioId}
      />
      <CollapsibleSection
        id={Section.InactiveInitiatives}
        title="Inactive Initiatives"
        initiatives={initiatives.filter((initiative) => !isActiveInitiative(initiative))}
        portfolioId={portfolioId}
      />
    </PageContainer>
  );
}

const CollapsibleSection = ({ id, title, initiatives, portfolioId }: Props & { id: Section; title: string }) => {
  const { sectionConfig, toggleSection } = useContext(StrategyContext);
  const [showEmptyInitiatives, { toggle }] = useDisclosure(false);
  const opened = sectionConfig[id];

  const collapsibleIcon = opened ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one';
  const titleIcon = id === Section.InactiveInitiatives ? icons.iconCompassGrey : icons.iconCompass;

  const initiativesToShow = showEmptyInitiatives
    ? initiatives
    : initiatives.filter((initiative) => initiative.status?.by_tasks?.status);

  const emptyInitiatives = initiatives.filter((initiative) => !initiative.status?.by_tasks?.status);

  const percentOnTrack = initiativesToShow.length
    ? (initiativesToShow.filter((initiative) => initiative.status?.by_tasks?.status === InitiativeStatus.OnTrack)
        .length /
        initiativesToShow.length) *
      100
    : 0;
  const percentAtRisk = initiativesToShow.length
    ? (initiativesToShow.filter((initiative) => initiative.status?.by_tasks?.status === InitiativeStatus.AtRisk)
        .length /
        initiativesToShow.length) *
      100
    : 0;
  const percentOffTrack = initiativesToShow.length
    ? (initiativesToShow.filter((initiative) => initiative.status?.by_tasks?.status === InitiativeStatus.OffTrack)
        .length /
        initiativesToShow.length) *
      100
    : 0;

  const percentEmpty = initiatives.length ? (emptyInitiatives.length / initiatives.length) * 100 : 0;

  const chartData = showEmptyInitiatives
    ? [
        { label: 'Off Track', value: Math.round(percentOffTrack), color: newCOLORS.red },
        { label: 'At Risk', value: Math.round(percentAtRisk), color: newCOLORS.darkYellow },
        { label: 'On Track', value: Math.round(percentOnTrack), color: newCOLORS.green },
        { label: 'Empty', value: Math.round(percentEmpty), color: newCOLORS.lightGray },
      ]
    : [
        { label: 'Off Track', value: Math.round(percentOffTrack), color: newCOLORS.red },
        { label: 'At Risk', value: Math.round(percentAtRisk), color: newCOLORS.darkYellow },
        { label: 'On Track', value: Math.round(percentOnTrack), color: newCOLORS.green },
      ];

  return (
    <SectionContainer>
      <HeaderTitleContainer>
        <FlexSpacer>
          <HeaderTitle onClick={() => toggleSection(id)}>
            <CollapseIcon>
              <Icon
                icon={collapsibleIcon}
                width={16}
                height={16}
                color={newCOLORS.indigo}
                style={{ pointerEvents: 'none' }}
              />
            </CollapseIcon>
            <img src={titleIcon} alt={title} width={24} height={24} />
            <HeaderTitleText>{title}:</HeaderTitleText>
            <HeaderTitleCount>{initiativesToShow.length}</HeaderTitleCount>
            {emptyInitiatives.length > 0 && !showEmptyInitiatives && (
              <HeaderTitleCountEmpty>(+{emptyInitiatives.length} Empty)</HeaderTitleCountEmpty>
            )}
          </HeaderTitle>
          {0 < percentEmpty && opened && (
            <Tooltip
              label={showEmptyInitiatives ? 'Hide empty initiatives' : 'Show empty initiatives'}
              refProp="rootRef"
            >
              <StyledSwitch checked={showEmptyInitiatives} onChange={toggle} />
            </Tooltip>
          )}
        </FlexSpacer>
      </HeaderTitleContainer>
      <Collapse in={opened} style={{ width: '100%' }}>
        <InitiativeCompletionUnrolledPieChart data={chartData} />
        <InitiativesTable initiatives={initiativesToShow} portfolioId={portfolioId} />
      </Collapse>
    </SectionContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  width: 100%;
  margin-top: 8px;
`;

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background-color: ${newCOLORS.white};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
`;

const FlexSpacer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const HeaderText = styled.span`
  font-family: Figtree;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const HeaderTitleText = styled(HeaderText)`
  color: ${newCOLORS.darkerGray};
`;

const HeaderTitleCount = styled(HeaderText)`
  color: ${newCOLORS.darkGray};
`;

const HeaderTitleCountEmpty = styled.span`
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${newCOLORS.darkGray};
`;

const StyledSwitch = styled(Switch)`
  .mantine-Switch-track {
    height: 25px;
    width: 45px;
    padding: 2px 8px;
    border-radius: 100px;
    border: 2px solid rgba(29, 27, 32, 0.12);
    background: rgba(231, 224, 236, 0.12);
    cursor: pointer;
    box-sizing: border-box;
  }

  .mantine-Switch-thumb {
    height: 14px;
    width: 14px;
    border-radius: 24px;
    margin-left: 2px;
    opacity: 0.38;
    background: #1d1b20;
  }

  input {
    &:checked + .mantine-Switch-track {
      border: ${newCOLORS.blue};
      background: ${newCOLORS.blue};

      .mantine-Switch-thumb {
        height: 16px;
        width: 16px;
        background: ${newCOLORS.white};
        z-index: 1;
        left: 22px;
        opacity: 1;
      }
    }
  }
`;
