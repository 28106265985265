import { Group, Stack } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from '../../../../api/organization-client/organization-client.hooks';
import { usePortfolios } from '../../../../api/portfolio-client/portfolio-client.hooks';
import { DEFAULT_ROUTE } from '../../../../constants';
import { getRequestToken } from '../../../../helpers/storage/storage';
import { resetAuthStore } from '../../../../store/auth-store/auth-store.actions';
import { setOrganization, setPortfolio } from '../../../../store/global-store/global-store.actions';
import { Icon } from '../../../../ui-library/icon/icon';
import { H3 } from '../../../../ui-library/typography/typography';
import { Layout } from '../../layout';
import { OrganizationList } from './select-organization-list';

export const SelectOrganization = () => {
  const navigate = useNavigate();

  const isValidAccess = Boolean(getRequestToken());

  const { organizations = [] } = useOrganizations({ enabled: isValidAccess });
  const { portfolios = [] } = usePortfolios({ enabled: isValidAccess });

  const filteredOrganizations = organizations.filter((organization) =>
    portfolios.some((portfolio) => portfolio.organization.id === organization.id)
  );

  const handleSelect = (organizationId: string) => {
    try {
      const defaultPortfolio = portfolios.find((portfolio) => portfolio.organization.id === organizationId) || null;

      setOrganization(organizations?.find((o) => o.id === organizationId) || null);
      setPortfolio(defaultPortfolio || null);
      resetAuthStore();
      navigate(DEFAULT_ROUTE);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Layout>
      <Stack gap={32}>
        <Group gap={16}>
          <Icon name="home_work" size={32} />
          <H3>Select Organization</H3>
        </Group>
        <OrganizationList organizations={filteredOrganizations} onSelect={handleSelect} />
      </Stack>
    </Layout>
  );
};
