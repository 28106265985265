import { Divider, Group, PinInput, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import * as Sentry from '@sentry/browser';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogin, useVerifyOTP } from '../../../../api/auth-client/auth-client.hooks';
import { useUser } from '../../../../api/user-client/user-client.hooks';
import { UserContext } from '../../../../contexts';
import { useAuthStore } from '../../../../store/auth-store/auth-store';
import { setSignUpStep } from '../../../../store/auth-store/auth-store.actions';
import { SignUpStep } from '../../../../store/auth-store/auth-store.type';
import { baseWhite, secondaryBase, skyDark } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { H3, Text } from '../../../../ui-library/typography/typography';
import { Layout } from '../../layout';
import { formatInvalidOTPError } from './check-your-email.helpers';
import { ErrorResponse, OTPError } from './check-your-email.type';
import { VerificationStatus } from './verification-status';

export const CheckYourEmail = () => {
  const navigate = useNavigate();
  const [accountDisabled, setAccountDisabled] = useState(false);
  const [otpExpired, setOTPExpired] = useState(false);
  const { email, password } = useAuthStore();
  const { setUser } = useContext(UserContext);

  const form = useForm({
    initialValues: {
      code: '',
    },
  });

  const verifyOTPQuery = useVerifyOTP(email, form.values.code, { enabled: false });

  useEffect(() => {
    if (verifyOTPQuery.isError) {
      const { error } = verifyOTPQuery.error as ErrorResponse;

      if (!error?.error?.message) {
        return;
      }

      const { message } = error.error;

      if (message === OTPError.UserDisabled) {
        setAccountDisabled(true);
        form.setFieldValue('code', '');
      } else if (message === OTPError.InvalidOTP) {
        form.setFieldError('code', formatInvalidOTPError(error.error.tries_left));
      } else if (message === OTPError.ExpiredOTP) {
        setOTPExpired(true);
      } else {
        form.setFieldError('code', message);
      }
    }
  }, [verifyOTPQuery.isError, verifyOTPQuery.error, form]);

  const { query: userInfoQuery } = useUser({ enabled: false });

  const loginMutation = useLogin({
    onSuccess: async (data) => {
      if (data?.access) {
        const { data: user } = await userInfoQuery.refetch();

        if (!user) {
          return;
        }

        setUser(user);
        Sentry.setUser(user);
        Sentry.setContext('user', user);
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      navigate('/', { replace: true });
    },
  });

  const handleLogin = useCallback(() => loginMutation.mutate({ email, password }), [loginMutation, email, password]);

  useEffect(() => {
    if (verifyOTPQuery.isSuccess) {
      const proceedToNextStep = async () => {
        await handleLogin();
        setSignUpStep(SignUpStep.TermsAndConditions);
      };

      proceedToNextStep();
    }
  }, [verifyOTPQuery.isSuccess, handleLogin]);

  const handleSubmit = async () => {
    setOTPExpired(false);
    await verifyOTPQuery.refetch();
  };

  return (
    <Layout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap={32}>
          <Group gap={16}>
            <Icon name="mark_email_unread" size={32} />
            <H3>Check Your Email</H3>
          </Group>
          <Text>
            We’ve sent a temporary login link and code to <b>{email}</b>
          </Text>
          <Divider />
          <Stack gap={4}>
            <Text size="small" lineHeight="tight" weight="medium">
              Enter Code
            </Text>
            <PinInput
              {...form.getInputProps('code')}
              length={6}
              gap={16}
              placeholder="●"
              styles={{
                root: { width: '100%', display: 'flex', justifyContent: 'space-between' },
                pinInput: { width: 53, height: 42 },
                input: { height: '100%' },
              }}
              disabled={accountDisabled}
            />
          </Stack>
          <VerificationStatus
            accountDisabled={accountDisabled}
            otpExpired={otpExpired}
            setOTPExpired={setOTPExpired}
            error={form.errors.code}
          />
          <Divider />
          {accountDisabled ? (
            <Button
              variant="secondary"
              w="100%"
              radius="xl"
              rightSection={<Icon name="email" size={18} color={baseWhite} />}
              onClick={() => {
                window.location.href = `mailto: support@bloomfilter.app`;
              }}
            >
              Customer Success
            </Button>
          ) : (
            <Button
              type="submit"
              loaderProps={{ size: 'xs', color: secondaryBase }}
              w="100%"
              radius="xl"
              rightSection={<Icon name="arrow_forward" size={18} color={form.values.code ? baseWhite : skyDark} />}
              disabled={!form.values.code}
            >
              Verify Your Code
            </Button>
          )}
        </Stack>
      </form>
    </Layout>
  );
};
