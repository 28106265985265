import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { NumberInput, Select } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteSpendingByParams } from '../../api/financials-client/financials-client';
import { Spending } from '../../api/financials-client/financials-client.type';
import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { TeamContext } from '../../contexts';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import {
  SmallerHeading,
  StandardText,
  StyledButton,
  UppercaseCallout,
} from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { checkDisabled, checkWhetherBudgetIsFilled, getDatumBudget } from './edit-financial-data.helper';
import { EditFinancialDataProps } from './edit-financial-data.type';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const EditFinancialDataForm = ({
  setSpending,
  spending,
  persistedSpending,
  projects,
  year,
  setYear,
}: EditFinancialDataProps) => {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const teamContext = useContext(TeamContext);

  const navigate = useNavigate();
  const today: Date = new Date();
  const [team, setTeam] = useState<ProjectsResponse | null>(null);
  const [currentFinancialDatum, setCurrentFinancialDatum] = useState<Spending | null>();
  const { portfolioId = '' } = useParams<{ portfolioId: string }>();
  const [disableAdd, setDisableAdd] = useState<boolean>(false);

  useEffect(() => {
    setDisableAdd(team ? false : true);
  }, [team]);

  useEffect(() => {
    if (portfolio) {
      setTeam(null);
      navigate(`/application/financials/portfolio/${portfolio.id}/add-edit-financials`);
    }
  }, [portfolio, navigate]);

  const getSelectedTeams = () => {
    const selectedTeamsWithPersistedSpending = new Array(...new Set(persistedSpending.map((item) => item.project_id)));
    const selectedTeamsWithSpending = new Array(...new Set(spending.map((item) => item.project_id)));

    const selectedTeams = [];
    for (const project of projects.sort((a, b) => a.name.localeCompare(b.name))) {
      if (selectedTeamsWithPersistedSpending.includes(project.id) && selectedTeamsWithSpending.includes(project.id)) {
        selectedTeams.push(project);
      }
    }

    if (
      teamContext.team &&
      teamContext.team.id !== 'aggregate' &&
      !selectedTeams.find((tm) => tm.id === teamContext?.team?.id)
    ) {
      const addedTeam = projects.find((project) => project.id === teamContext?.team?.id);
      if (addedTeam) {
        selectedTeams.push(addedTeam);
      }
    } else {
      for (const selectedTeamWithSpending of selectedTeamsWithSpending) {
        if (
          !selectedTeamsWithPersistedSpending.includes(selectedTeamWithSpending) &&
          !selectedTeams.map((tm) => tm.id).includes(selectedTeamWithSpending)
        ) {
          const addedTeam: ProjectsResponse | undefined = projects.find(
            (project) => project.id === selectedTeamWithSpending
          );
          if (addedTeam) {
            selectedTeams.push(addedTeam);
          }
        }
      }
    }

    return selectedTeams;
  };

  const updateFinancialData = (value: number | '', teamId: string, month: number) => {
    const newFinancialData = [];

    let financialDatum: Spending | undefined = undefined;

    for (const datum of spending) {
      const newDatum = { ...datum, autofilled: false } as Spending;
      if (datum.project_id === teamId && datum.month === month) {
        newDatum.budget = value === '' ? null : value;
        if (newDatum.valid === false) {
          newDatum.valid = value === '' ? false : true;
        }
        financialDatum = newDatum;
      }
      newFinancialData.push(newDatum);
    }
    if (!financialDatum) {
      for (let i = 1; i < 13; i++) {
        // we can now have empty months so we need to check better
        let needsNewDatum = true;
        for (const datum of newFinancialData) {
          if (datum.project_id === teamId && datum.month === i) {
            needsNewDatum = false;
          }
        }

        if (needsNewDatum) {
          const datum = {
            year,
            month: i,
            budget: null,
            portfolio_id: portfolioId,
            project_id: teamId,
            autofilled: false,
          } as Spending;
          if (datum.month === month) {
            datum.budget = value === '' ? null : value;
            financialDatum = datum;
          }
          newFinancialData.push(datum);
        }
      }
    }
    setSpending(newFinancialData);
    setCurrentFinancialDatum(financialDatum);
  };

  const handleAutoFill = () => {
    if (!currentFinancialDatum) {
      return;
    }
    const { project_id, year, budget } = currentFinancialDatum;
    const newFinancialData = spending.map((item) => {
      if (
        item.project_id === project_id &&
        item.year === year &&
        item.budget === null &&
        !checkDisabled(year, today, item.month - 1)
      ) {
        return {
          ...item,
          budget,
          autofilled: true,
        };
      }
      return item;
    });
    setSpending(newFinancialData);
  };

  const handleUndoAutoFill = () => {
    const revertedData = spending.map((item) => {
      if (item.autofilled) {
        return {
          ...item,
          budget: null,
          autofilled: false,
        };
      }
      return item;
    });

    setSpending(revertedData);
  };

  const onAddClicked = () => {
    setDisableAdd(true);
    if (team) {
      const newData = [];
      for (let i = 1; i < 13; i++) {
        newData.push({
          year,
          month: i,
          budget: null,
          portfolio_id: portfolioId,
          project_id: team.id,
        });
      }
      const updatedFinancialData = [...spending, ...newData];
      setSpending(updatedFinancialData);
    }
  };

  const deleteSpendingQuery = useMutation(
    ['deleteSpendingByParams'],
    (spendingDelete: { portfolioId: string; projectId: string; year: number }) =>
      deleteSpendingByParams(spendingDelete.portfolioId, spendingDelete.projectId, spendingDelete.year)
  );

  const deleteFinancialsForTeam = (team: ProjectsResponse) => {
    const updatedFinancialData = spending.filter((datum) => datum.project_id !== team.id);
    setSpending(updatedFinancialData);
    deleteSpendingQuery.mutate({ portfolioId: portfolioId, projectId: team.id, year: year });
  };

  const yearsArray: string[] = Array.from({ length: new Date().getFullYear() - 1999 }, (_, index) =>
    (new Date().getFullYear() - index).toString()
  );

  return (
    <EditFinancialDataContainer>
      <EditFinancialDataHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SmallerHeading>Development spend</SmallerHeading>
          <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Year</StandardText>
          <Select
            label=""
            placeholder="Pick year"
            data={yearsArray}
            size="xs"
            style={{ width: 120 }}
            value={String(year)}
            onChange={(value) => setYear(Number(value))}
            allowDeselect={false}
          />
        </div>
        <StandardText>in $USD</StandardText>
      </EditFinancialDataHeader>

      <EditFinancialDataContent>
        <EditFormLabels>
          <UppercaseCallout style={{ color: newCOLORS.black, width: 160 }}>Team</UppercaseCallout>
          <EditFormLabelsMonths style={{ width: 'calc(100% - 200px)' }}>
            {MONTHS.map((month: string, index: number) => {
              if (checkDisabled(year, today, index)) {
                return (
                  <UppercaseCallout key={month} style={{ color: newCOLORS.gray, width: 80, textAlign: 'center' }}>
                    {month}
                  </UppercaseCallout>
                );
              } else {
                return (
                  <UppercaseCallout key={month} style={{ color: newCOLORS.darkGray, width: 80, textAlign: 'center' }}>
                    {month}
                  </UppercaseCallout>
                );
              }
            })}
          </EditFormLabelsMonths>
        </EditFormLabels>
        {getSelectedTeams().map((selectedTeam, teamIndex) => (
          <EditTeamDataForm key={selectedTeam.id}>
            <StandardText style={{ color: newCOLORS.black, width: 160 }}>{selectedTeam.name}</StandardText>
            <EditTeamDataFormInputs style={{ width: 'calc(100% - 200px)' }}>
              {Array.from({ length: 12 }, (_, monthIndex: number) => (
                <NumberInput
                  key={`${selectedTeam.id}-${monthIndex}`}
                  autoFocus={
                    monthIndex === 0 &&
                    teamIndex === getSelectedTeams().length - 1 &&
                    !checkWhetherBudgetIsFilled(spending, selectedTeam, year)
                  }
                  hideControls
                  size="xs"
                  style={{
                    width: 80,
                    textAlign: 'right',
                    boxSizing: 'border-box',
                    borderRadius: '0.3rem',
                  }}
                  disabled={checkDisabled(year, today, monthIndex)}
                  value={getDatumBudget(spending, selectedTeam, monthIndex)}
                  onChange={(value) => updateFinancialData(value as number, selectedTeam.id, monthIndex + 1)}
                />
              ))}
            </EditTeamDataFormInputs>
            <div style={{ marginLeft: '15px' }} aria-label="Delete Financials For Team">
              <Icon
                icon="mdi:trash-can"
                width={16}
                height={16}
                color={newCOLORS.indigo}
                style={{ marginRight: 10, cursor: 'pointer' }}
                onClick={() => deleteFinancialsForTeam(selectedTeam)}
              />
            </div>
          </EditTeamDataForm>
        ))}

        <AutofillContainer>
          {currentFinancialDatum &&
          currentFinancialDatum.budget !== null &&
          spending.some(
            (item) =>
              item.budget === null &&
              item.project_id === currentFinancialDatum.project_id &&
              !checkDisabled(year, today, item.month - 1)
          ) ? (
            <AutofillButton onClick={handleAutoFill}>+ Add this amount to each open month</AutofillButton>
          ) : null}

          {currentFinancialDatum &&
          currentFinancialDatum.budget !== null &&
          spending.some(
            (item) =>
              item.autofilled === true &&
              item.project_id === currentFinancialDatum.project_id &&
              !checkDisabled(year, today, item.month - 1)
          ) ? (
            <AutofillButton onClick={handleUndoAutoFill}>- Undo this change to each open month</AutofillButton>
          ) : null}
        </AutofillContainer>

        {getSelectedTeams().length < projects.length && (
          <AddTeam key={getSelectedTeams().length}>
            <Select
              label=""
              placeholder="Select Team"
              data={projects
                .filter((project) => !getSelectedTeams().includes(project))
                .map((project) => ({ value: project.id, label: project.name }))}
              size="xs"
              style={{ width: 150 }}
              value={team?.id}
              onChange={(value) => setTeam(projects.find((project) => project.id == value) as ProjectsResponse)}
            />
            <StyledButton
              size={'small'}
              type={'primary'}
              firstColor={newCOLORS.indigo}
              secondColor={newCOLORS.white}
              disable={disableAdd}
              style={{ marginLeft: 8 }}
              onClick={() => onAddClicked()}
            >
              Add
            </StyledButton>
          </AddTeam>
        )}
      </EditFinancialDataContent>
    </EditFinancialDataContainer>
  );
};

const EditFinancialDataContainer = styled.div`
  padding: 16px 24px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
`;

const EditFinancialDataHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const EditFinancialDataContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditFormLabels = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const EditFormLabelsMonths = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditTeamDataForm = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const EditTeamDataFormInputs = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    text-align: right;
  }
`;

const AddTeam = styled.div`
  display: flex;
  align-items: center;
`;

const AutofillContainer = styled.div`
  display: flex;
  padding-left: 160px;
`;

const AutofillButton = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
  color: ${newCOLORS.indigo};
  &:hover {
    text-decoration: underline;
  }
`;
