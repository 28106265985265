enum OTPError {
  InvalidOTP = 'Invalid OTP.',
  ExpiredOTP = 'OTP expired.',
  UserDisabled = 'The user is temporarily disabled.',
}

type InvalidOTPError = {
  error: {
    message: OTPError.InvalidOTP;
    tries_left: number;
  };
};

type ExpiredOTPError = {
  error: {
    message: OTPError.ExpiredOTP;
  };
};

type UserDisabledError = {
  error: {
    message: OTPError.UserDisabled;
  };
};

type ErrorResponse = {
  error: InvalidOTPError | UserDisabledError | ExpiredOTPError;
};

export { OTPError };
export type { ErrorResponse, ExpiredOTPError, InvalidOTPError, UserDisabledError };
