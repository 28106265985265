import { styled } from '@linaria/react';
import { Checkbox, Divider } from '@mantine/core';
import { formatAllocationOfCostTitle } from '../../helpers';
import { formatCurrency } from '../../helpers/financial/financial';
import { newCOLORS } from '../../styles/colors';
import { StandardText, StandardTextBold } from '../../styles/new-shared-styled-components/new-shared-styled-components';

type AllocationOfCostsItemProps = {
  title: string;
  color: string;
  current: number;
  average: number;
  checked: boolean;
  currency: boolean;
  disabled: boolean;
  updateShowChartLines: (checked: boolean, field: string, color: string) => void;
};

export const AllocationOfCostsItem = ({
  title,
  color,
  current,
  average,
  checked,
  currency,
  disabled,
  updateShowChartLines,
}: AllocationOfCostsItemProps) => {
  return (
    <AllocationOfCostsContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Divider orientation="vertical" size="md" style={{ marginRight: 4 }} color={color} />
        <Checkbox
          style={{ margin: 4 }}
          size="xs"
          checked={checked}
          onChange={(event) => updateShowChartLines(event.currentTarget.checked, title, color)}
          disabled={disabled}
        />
        <div style={{ margin: 4 }}>
          <StandardText style={{ color: checked ? newCOLORS.black : newCOLORS.darkerGray }}>
            {formatAllocationOfCostTitle(title)}
          </StandardText>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <StandardTextBold style={{ width: 125, textAlign: 'right', color: checked ? newCOLORS.black : newCOLORS.gray }}>
          {currency ? formatCurrency(current) : current}
        </StandardTextBold>
        <StandardText style={{ width: 125, textAlign: 'right', color: checked ? newCOLORS.black : newCOLORS.gray }}>
          {currency ? formatCurrency(average) : average}
        </StandardText>
      </div>
    </AllocationOfCostsContainer>
  );
};

const AllocationOfCostsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin: 8px 0px;
`;
