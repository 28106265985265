import { Flex } from '@mantine/core';
import { MouseEventHandler } from 'react';
import { useUIStore } from '../../store/ui-store/ui-store';
import { setIsHoveredLogo } from '../../store/ui-store/ui-store.actions';
import { iconBlackLogo, iconColorLogo, iconTextBlack, iconTextHover, iconTextWhite, iconWhiteLogo } from './assets';

type Props = {
  variant?: 'colored' | 'white' | 'black';
  width?: number;
  height?: number;
  onClick?: MouseEventHandler;
  showText?: boolean;
  animate?: boolean;
  textProps?: {
    width?: number;
    height?: number;
  };
};

export const Logo = ({
  variant = 'colored',
  width,
  height,
  onClick,
  showText = true,
  animate = true,
  textProps,
}: Props) => {
  const isHovered = useUIStore((state) => state.isHoveredLogo);

  const getLogo = () => {
    switch (variant) {
      case 'black':
        return iconBlackLogo;
      case 'white':
        return iconWhiteLogo;
      default:
        return iconColorLogo;
    }
  };

  const logoIcon = (
    <img
      alt="Bloomfilter Logo"
      src={getLogo()}
      width={width || 54}
      height={height || 54}
      style={{
        cursor: animate ? 'pointer' : 'default',
        transition: 'transform 1s',
        transform: isHovered && animate ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    />
  );

  if (!showText) {
    return logoIcon;
  }

  const textIcon = variant === 'white' ? iconTextWhite : iconTextBlack;

  return (
    <Flex
      direction="row"
      align="center"
      gap={8}
      onClick={onClick}
      onMouseEnter={() => setIsHoveredLogo(true)}
      onMouseLeave={() => setIsHoveredLogo(false)}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      role="button"
      aria-label="Bloomfilter logo"
    >
      {logoIcon}
      {showText && (
        <img
          src={isHovered ? iconTextHover : textIcon}
          alt="Bloomfilter Text Logo"
          width={textProps?.width || 120}
          height={textProps?.height || 24}
          {...textProps}
        />
      )}
    </Flex>
  );
};
