import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { Epic, MinifiedTask, Task, TaskAuditLogEntry } from './task-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

const fetchTasks = async ({
  sprintId,
  filter,
  day,
}: {
  sprintId: string;
  filter: string;
  day: string;
}): Promise<Task[]> => {
  const url = new URL(baseURL + `/api/tasks/`);
  const params = new URLSearchParams(url.search);
  params.append('sprint_id', sprintId);
  params.append('filter', filter);
  params.append('day', day);
  url.search = params.toString();

  return await axiosInstance
    .get<Task[]>(url.toString())
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the tasks');
    });
};

const fetchProjectsTasks = async ({
  portfolioId,
  projectId,
  filter,
  startDate,
  endDate,
  category,
}: {
  portfolioId: string | undefined;
  projectId: string | undefined;
  category: string | undefined;
  filter: string;
  startDate: string;
  endDate: string;
}): Promise<MinifiedTask[]> => {
  const url = new URL(baseURL + `/api/tasks/`);
  const params = new URLSearchParams(url.search);
  if (portfolioId) {
    params.append('portfolio_id', portfolioId);
  }
  if (projectId) {
    params.append('project_id', projectId);
  }
  if (category) {
    params.append('category', category);
  }
  params.append('filter', filter);
  params.append('start_date', startDate);
  params.append('end_date', endDate);
  url.search = params.toString();

  return await axiosInstance
    .get<MinifiedTask[]>(url.toString())
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the tasks');
    });
};

async function getEpicsForProjects(projectIds: string[]): Promise<Epic[]> {
  const url = new URL(baseURL + `/api/epics/`);
  const params = new URLSearchParams(url.search);
  params.append('project_ids', projectIds.join(','));
  url.search = params.toString();

  return await axiosInstance
    .get(url.toString())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching epics'));
}

const fetchTaskAuditLog = async (taskId: string): Promise<TaskAuditLogEntry[]> => {
  const apiURL = baseURL + `/api/tasks/${taskId}/audit-log/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response || 'There was a problem fetching the sprint audit log'));
};

/**
 * Export tasks as a CSV file
 *
 * @param taskIds - An array of task IDs to export
 * @returns A Blob containing the CSV file
 */
async function exportTasksAsCsv(taskIds: string[]): Promise<Blob> {
  const url = new URL(baseURL + `/api/tasks/export/`);

  return await axiosInstance
    .post(url.toString(), { task_ids: taskIds }, { responseType: 'blob' })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while exporting tasks'));
}

export { exportTasksAsCsv, fetchProjectsTasks, fetchTaskAuditLog, fetchTasks, getEpicsForProjects };
