import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Text } from '@mantine/core';
import { ControlButton } from '../../containers/insights/insights';
import { ChatHistory } from '../../containers/insights/insights.type';
import { addOpacityToColor } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';
import { ChatHistoryItem } from './chat-history-item';
import { groupHistoriesByDate } from './chat-history-panel.helpers';

type ChatHistoryPanelProps = {
  histories: ChatHistory[];
  closeHistory: () => void;
  setThreadId: React.Dispatch<React.SetStateAction<string>>;
  onDeleteHistory: (id: string) => void;
  onEditHistory: (id: string, newDescription: string) => void;
};

export const ChatHistoryPanel = ({
  histories,
  closeHistory,
  setThreadId,
  onDeleteHistory,
  onEditHistory,
}: ChatHistoryPanelProps) => {
  const groupedHistories = groupHistoriesByDate(histories);

  return (
    <ChatHistoryPanelContainer>
      <Header>
        <Title>Chat History</Title>
        <ControlButton onClick={closeHistory}>
          <Icon icon="material-symbols:close" />
        </ControlButton>
      </Header>
      <HistoryList>
        {groupedHistories.map((group) => (
          <HistoryGroup key={group.label}>
            <GroupLabel>{group.label}</GroupLabel>
            {group.histories.map((history) => (
              <ChatHistoryItem
                key={history.id}
                history={history}
                onClick={() => {
                  setThreadId(history.id);
                  closeHistory();
                }}
                onDelete={onDeleteHistory}
                onEdit={onEditHistory}
              />
            ))}
          </HistoryGroup>
        ))}
      </HistoryList>
    </ChatHistoryPanelContainer>
  );
};

const ChatHistoryPanelContainer = styled.div`
  position: fixed;
  bottom: 40px;
  right: 575px;
  width: 350px;
  height: 83.7vh;
  max-height: 1000px;
  overflow: hidden;
  border: 1px solid black;
  background: ${() => addOpacityToColor(newCOLORS.white, 0.95)};
  border-radius: 10px;
  padding: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const HistoryList = styled.div`
  flex: 1;
  overflow: auto;
`;

const Title = styled(Text)`
  font-size: 24px;
  font-weight: 600;
`;

const HistoryGroup = styled.div`
  margin-bottom: 16px;
`;

const GroupLabel = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  color: ${newCOLORS.gray2};
  text-transform: uppercase;
  padding: 0 8px;
  margin-bottom: 8px;
`;
