import { styled } from '@linaria/react';
import { newCOLORS } from './colors';

const HeadingTag = styled.div`
  font-family: 'Figtree-bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const ContentTag = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const TitleHeadingTag = styled.div`
  font-family: 'Figtree-bold';
  font-size: xx-large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 5px;
`;

const SubtitleHeadingTag = styled.div`
  font-size: medium;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 5px;
  padding-bottom: 10px;
`;

const SubSectionTag = styled.div`
  font-size: medium;
  font-family: 'Figtree-bold';
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const OuterPaddingDiv = styled.div`
  padding: 30px;
  padding-bottom: 10px;
  padding-top: 15px;
`;

const HorizontalFlexGrid = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MenuDrivenContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  background-color: ${newCOLORS.lighterGray};
`;

const DashboardContentLayout = styled(MenuDrivenContent)`
  padding-top: 3em;
  margin: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;

const IssueCardWrapper = styled.div`
  flex-basis: 0.5;
  cursor: pointer;
  @media (max-width: 768) {
    width: 100%;
  }
`;

const IssueGridContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  background-color: ${newCOLORS.lightishGray};
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-rows: 1fr;
`;

const HealthBarContainer = styled.div`
  background-color: ${newCOLORS.white};
  padding: 16px;
  padding-bottom: 30px;
  width: 420px;
  max-height: 400px;
`;

const LeftAlignedInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5em;
`;

const SubpageHeadingTag = styled.div`
  font-family: 'Figtree-bold';
  font-size: x-large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 10px;
`;

export {
  ContentTag,
  DashboardContentLayout,
  HeaderContainer,
  HeadingTag,
  HealthBarContainer,
  HorizontalFlexGrid,
  IssueCardWrapper,
  IssueGridContainer,
  LeftAlignedInputContainer,
  MenuDrivenContent,
  OuterPaddingDiv,
  SubpageHeadingTag,
  SubSectionTag,
  SubtitleHeadingTag,
  TitleHeadingTag,
};
