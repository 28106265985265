import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ReactFlowProvider, useNodesInitialized, useReactFlow } from 'reactflow';
import { useProcessGraph, useProcessMapping } from '../../api/process-client/process-client.hooks';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { filterProcessMapping } from './process.helpers';
import { useProcessScope } from './process.hooks';
import { PageContainer } from './process.styled';
import { SectionGraph } from './section-graph';
import { SectionSummary } from './section-summary';

export function ProcessPage() {
  useDocumentTitle('Process Map - Bloomfilter');

  const globalPortfolio = useGlobalStore((state) => state.portfolio);
  const { portfolio, team, board, taskType, startDate, endDate, setScope } = useProcessScope();

  const { fitView } = useReactFlow();
  const nodesInitialized = useNodesInitialized();

  const { mapping } = useProcessMapping(team?.id, { staleTime: 1000 * 60 * 5 });

  const { graphData, query } = useProcessGraph(
    {
      teamId: team?.id,
      boardId: board ? board.id : undefined,
      taskType: taskType || undefined,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    },
    {
      enabled: Boolean(team?.id),
      staleTime: 1000 * 60 * 5,
    }
  );

  // There are two contexts that can change the portfolio: the global context and the local context
  // whenever one is updated, we need to update the other
  useEffect(() => {
    if (globalPortfolio?.id && globalPortfolio?.id !== portfolio?.id) {
      setScope({ portfolio: globalPortfolio });
    }
  }, [portfolio, setScope, globalPortfolio]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    if (graphData && nodesInitialized) {
      timeoutId = setTimeout(fitView);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [fitView, graphData, nodesInitialized]);

  const [init, setInit] = useState<boolean>(false);
  useEffectOnce(() => setInit(true));

  return (
    <MenuDrivenContainer header="Process Map">
      <BrandedLoadingOverlay visible={query.isFetching} transitionDuration={30} variant="colored" />
      {init && (
        <PageContainer>
          <SectionGraph graphData={graphData} mapping={filterProcessMapping(mapping)} />
          <SectionSummary completedTasks={graphData?.task_count || 0} />
        </PageContainer>
      )}
    </MenuDrivenContainer>
  );
}

export function Process() {
  return (
    <ReactFlowProvider>
      <ProcessPage />
    </ReactFlowProvider>
  );
}
