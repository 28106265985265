import { Flex, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../api/user-client/user-client.hooks';
import { checkValidEmail } from '../../../../helpers';
import { resetAuthStore } from '../../../../store/auth-store/auth-store.actions';
import { baseWhite, inkLight, secondaryBase, secondaryLight } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { H3, Text } from '../../../../ui-library/typography/typography';
import { Layout } from '../../layout';
import { ValidationError } from '../../sign-up/sign-up.type';
import { LoginForm } from './login-form';
import { useAuthCheckDomain, useAuthParams, useAuthTokenRefresh, useLoginMutation } from './login.hooks';

export const Login = () => {
  const navigate = useNavigate();
  const { query: userInfoQuery } = useUser({ enabled: false });

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (checkValidEmail(value) ? null : ValidationError.InvalidEmail),
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  useAuthTokenRefresh(() => userInfoQuery.refetch());
  useAuthParams((message) => form.setFieldError('email', message));

  const { checkDomainRefetch } = useAuthCheckDomain({
    email: form.values.email,
    onPasswordAuth: () => setShowPassword(true),
  });

  const { loginMutation } = useLoginMutation(form);

  const handleSubmit = async () => {
    if (!showPassword) {
      return await checkDomainRefetch();
    }

    try {
      await loginMutation.mutateAsync({ email: form.values.email, password: form.values.password });
    } catch (error) {
      const defaultError = 'An error occurred during sign in';
      if (error instanceof Error) {
        form.setFieldError('password', error.message || defaultError);
      } else {
        form.setFieldError('password', defaultError);
      }
      Sentry.captureException(error);
    }
  };

  const handleForgotPassword = () => {
    resetAuthStore();
    navigate('/reset-password');
  };

  return (
    <Layout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap={32}>
          <Group gap={16}>
            <Icon name="person_outline" size={32} />
            <H3>Log in to Bloomfilter</H3>
          </Group>
          <Stack gap={24}>
            <LoginForm form={form} showPassword={showPassword} />
            {showPassword && (
              <Flex justify="flex-end">
                <Text size="small" style={{ color: secondaryLight, cursor: 'pointer' }} onClick={handleForgotPassword}>
                  Forgot your password?
                </Text>
              </Flex>
            )}
          </Stack>
          <Divider />
          <Stack gap={24} align="center">
            <Button
              type="submit"
              loaderProps={{ size: 'xs', color: secondaryBase }}
              w="100%"
              radius="xl"
              rightSection={<Icon name="arrow_forward" size={18} color={baseWhite} />}
            >
              {showPassword ? 'Log in' : 'Check User'}
            </Button>
            <Text size="small" weight="medium">
              Don't have an account?
            </Text>
            <Button
              variant="outline"
              onClick={() => navigate('/sign-up', { replace: true })}
              w="100%"
              radius="xl"
              rightSection={<Icon name="add_circle_outline" size={18} color={inkLight} />}
            >
              Create Bloomfilter Account
            </Button>
          </Stack>
        </Stack>
      </form>
    </Layout>
  );
};
