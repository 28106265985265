import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Table, Tooltip, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntegrationRefresh } from '../../../api/integrations-client/integrations-client.hooks';
import { IntegrationEntry, IntegrationType } from '../../../api/integrations-client/integrations-client.type';
import { icons } from '../../../assets/icons/icons';
import { Button } from '../../../components/button/button';
import { UserContext } from '../../../contexts';
import { useRoles } from '../../../helpers/auth-helpers/auth.hooks';
import { newCOLORS } from '../../../styles/colors';
import { setIntegrationForReauth } from '../integrations.helpers';
import {
  getIntegrationProjects,
  getIntegrationUnit,
  getLastRefresh,
  getRefreshDuration,
  getRefreshMessage,
} from './integrations-list.helpers';
import { useIntegrationAuth } from './integrations-list.hooks';
import { Integration, IntegrationProject, IntegrationStatus } from './integrations-list.type';

type SortedColumn = 'project' | 'integrator' | 'status' | 'last_refresh' | 'refresh_duration' | 'action';

type Props = {
  activeIntegration: Integration;
  integrationEntries: IntegrationEntry[];
  setShowRefreshMessage: (value: boolean) => void;
};

export function AvailableIntegrationsTable({ activeIntegration, integrationEntries, setShowRefreshMessage }: Props) {
  const [sortBy, setSortBy] = useState<SortedColumn>('project');
  const [sortDesc, setSortDesc] = useState<boolean>(false);

  const projects = useMemo(() => getIntegrationProjects(integrationEntries), [integrationEntries]);

  const setSorting = useCallback(
    (field: SortedColumn) => {
      const desc = field === sortBy ? !sortDesc : false;
      setSortDesc(desc);
      setSortBy(field);
    },
    [sortBy, sortDesc]
  );

  const [refreshIntegrationId, setRefreshIntegrationId] = useState<string | null>(null);
  const [refreshStatuses, setRefreshStatuses] = useState<Record<string, number>>({});

  const { data, query } = useIntegrationRefresh(refreshIntegrationId, { enabled: !!refreshIntegrationId });

  useEffect(() => {
    if (query.isFetched && data?.status && refreshIntegrationId) {
      setRefreshIntegrationId(null);
      setRefreshStatuses({ ...refreshStatuses, [refreshIntegrationId]: data.status });
      setShowRefreshMessage(true);
    }
  }, [data?.status, query.isFetched, refreshStatuses, refreshIntegrationId, setRefreshStatuses, setShowRefreshMessage]);

  const { authenticate } = useIntegrationAuth(activeIntegration.name);

  const { isAdmin } = useRoles();

  return (
    <>
      <StyledTable verticalSpacing="md" horizontalSpacing="xs">
        <Colgroup isAdmin={isAdmin} />
        <Table.Thead>
          <Table.Tr>
            <SortingTableHeader
              onSort={() => setSorting('project')}
              isActive={sortBy === 'project'}
              desc={sortDesc}
              tooltip={`${getIntegrationUnit(activeIntegration)} integrated with Bloomfilter`}
            >
              {getIntegrationUnit(activeIntegration)}
            </SortingTableHeader>
            <SortingTableHeader
              onSort={() => setSorting('integrator')}
              isActive={sortBy === 'integrator'}
              desc={sortDesc}
              tooltip="Person who set up the integration with Bloomfilter"
            >
              Integrator
            </SortingTableHeader>
            <SortingTableHeader
              onSort={() => setSorting('status')}
              isActive={sortBy === 'status'}
              desc={sortDesc}
              tooltip="Operational status of the integration"
            >
              Status
            </SortingTableHeader>
            {isAdmin && (
              <SortingTableHeader
                onSort={() => setSorting('last_refresh')}
                isActive={sortBy === 'last_refresh'}
                desc={sortDesc}
                tooltip="Date when data was last refreshed for this integration"
              >
                Last Refresh
              </SortingTableHeader>
            )}
            {isAdmin && (
              <SortingTableHeader
                onSort={() => setSorting('refresh_duration')}
                isActive={sortBy === 'refresh_duration'}
                desc={sortDesc}
                tooltip="Time needed to update data during the last refresh"
              >
                Refresh Duration
              </SortingTableHeader>
            )}
            {isAdmin && (
              <SortingTableHeader
                onSort={() => setSorting('action')}
                isActive={sortBy === 'action'}
                desc={sortDesc}
                tooltip="Queues up either a token or data refresh and communicates status of refresh once initiated"
                style={{ textAlign: 'center' }}
              >
                Action
              </SortingTableHeader>
            )}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {sortProjects(projects, { sortBy, sortDesc }).map((project) => (
            <ProjectRow
              key={`${project.id} ${project.project_id} ${project.name}`}
              project={project}
              authenticate={authenticate}
              refresh={setRefreshIntegrationId}
              refreshStatus={refreshStatuses[project.id]}
            />
          ))}
        </Table.Tbody>
      </StyledTable>
      {!projects.length && <NoIntegrationsLabel>Add a project to establish this integration</NoIntegrationsLabel>}
    </>
  );
}

const Colgroup = ({ isAdmin }: { isAdmin: boolean }) => {
  if (isAdmin) {
    return (
      <colgroup>
        <col style={{ width: '32%' }} />
        <col style={{ width: '16%' }} />
        <col style={{ width: '16%' }} />
        <col style={{ width: '16%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '8%' }} />
      </colgroup>
    );
  }

  return (
    <colgroup>
      <col style={{ width: '60%' }} />
      <col style={{ width: '20%' }} />
      <col style={{ width: '20%' }} />
    </colgroup>
  );
};

const SortingTableHeader = ({
  children,
  tooltip,
  isActive,
  onSort,
  desc,
  style,
}: {
  children: React.ReactNode;
  tooltip: string;
  isActive: boolean;
  onSort: () => void;
  desc: boolean;
  style?: React.CSSProperties;
}) => (
  <Table.Th style={style}>
    <UnstyledButton component="div" onClick={onSort}>
      {children}
      <Tooltip label={tooltip}>
        <Icon icon="mdi:information-outline" width={12} height={12} color={newCOLORS.blue} />
      </Tooltip>
      <SortableColumnIndicator isActive={isActive} desc={desc} />
    </UnstyledButton>
  </Table.Th>
);

export function SortableColumnIndicator({ isActive, desc }: { isActive: boolean; desc: boolean }) {
  if (!isActive) {
    return <Icon icon="prime:sort-alt" width={16} height={16} color={newCOLORS.gray} />;
  }

  return <Icon icon={desc ? 'octicon:sort-desc-24' : 'octicon:sort-asc-24'} width={16} height={16} />;
}

const ProjectRow = ({
  project,
  authenticate,
  refresh,
  refreshStatus,
}: {
  project: IntegrationProject;
  authenticate: () => void;
  refresh: (id: string) => void;
  refreshStatus?: number;
}) => {
  const [opened, { toggle }] = useDisclosure(false);

  const hasSubprojects = project.subprojects.length > 1;
  const projectProps = hasSubprojects ? { isOpen: opened, toggleExpand: toggle } : {};
  const commonProps = { project, authenticate, refresh, refreshStatus };

  return (
    <>
      <Row key={`${project.id} ${project.name}`} name={project.name} isParentRow {...commonProps} {...projectProps} />
      {hasSubprojects &&
        opened &&
        project.subprojects.map((subproject) => (
          <Row key={`${project.id} ${subproject.name}`} name={subproject.name} {...commonProps} />
        ))}
    </>
  );
};

const Row = ({
  name,
  project,
  isParentRow,
  isOpen,
  toggleExpand,
  authenticate,
  refresh,
  refreshStatus,
}: {
  name: string;
  project: IntegrationProject;
  isParentRow?: boolean;
  isOpen?: boolean;
  toggleExpand?: () => void;
  authenticate: () => void;
  refresh: (id: string) => void;
  refreshStatus?: number;
}) => {
  const { isAdmin } = useRoles();

  return (
    <Table.Tr>
      <ProjectName
        name={name}
        project={project}
        isParentRow={isParentRow}
        isOpen={isOpen}
        toggleExpand={toggleExpand}
      />
      <Table.Td>{project.user_name}</Table.Td>
      <Table.Td style={{ color: newCOLORS.darkerGray }}>
        <Status status={project.status} />
      </Table.Td>
      {isAdmin && <Table.Td>{getLastRefresh(project.last_completed_at)}</Table.Td>}
      {isAdmin && <Table.Td>{getRefreshDuration(project.last_run_time)}</Table.Td>}
      {isAdmin && (
        <Table.Td>
          <Action project={project} authenticate={authenticate} refresh={refresh} refreshStatus={refreshStatus} />
        </Table.Td>
      )}
    </Table.Tr>
  );
};

const statusMapping = {
  [IntegrationStatus.Operational]: {
    text: 'Operational',
    icon: icons.iconCheckmark,
  },
  [IntegrationStatus.Stale]: {
    text: 'Data Stale',
    icon: icons.iconYellowAlert,
  },
  [IntegrationStatus.Expired]: {
    text: 'Token Expired',
    icon: icons.iconRedWarning,
  },
};

const ProjectName = ({
  name,
  project,
  isParentRow,
  isOpen,
  toggleExpand,
}: {
  name: string;
  project: IntegrationProject;
  isParentRow?: boolean;
  isOpen?: boolean;
  toggleExpand?: () => void;
}) => {
  const isCollapsible = isParentRow && project.subprojects.length > 1;

  const projectName =
    project.integration_type === IntegrationType.JIRA && isParentRow ? (
      <span style={{ color: newCOLORS.indigo }}>{name}</span>
    ) : (
      name
    );

  return (
    <Table.Td
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: 19,
        marginLeft: isParentRow ? 0 : 80,
        lineHeight: '32px',
        cursor: 'default',
      }}
    >
      {isCollapsible && (
        <CollapseIcon>
          <Icon
            icon={isOpen ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one'}
            width={16}
            height={16}
            color={newCOLORS.indigo}
            onClick={toggleExpand}
          />
        </CollapseIcon>
      )}
      {projectName}
    </Table.Td>
  );
};

const Status = ({ status }: { status: IntegrationStatus }) => {
  return (
    <div>
      <img src={statusMapping[status].icon} width={20} height={20} />
      {statusMapping[status].text}
    </div>
  );
};

const Action = ({
  project,
  authenticate,
  refresh,
  refreshStatus,
}: {
  project: IntegrationProject;
  authenticate: () => void;
  refresh: (id: string) => void;
  refreshStatus?: number;
}) => {
  const refreshMessage = getRefreshMessage(project, refreshStatus);

  if (refreshMessage) {
    return <RefreshText>{refreshMessage}</RefreshText>;
  }

  return <ActionButton project={project} authenticate={authenticate} refresh={refresh} />;
};

const ActionButton = ({
  project,
  authenticate,
  refresh,
}: {
  project: IntegrationProject;
  authenticate: () => void;
  refresh: (id: string) => void;
}) => {
  const { user } = useContext(UserContext);

  const buttonColors = {
    [IntegrationStatus.Stale]: '#FFB340',
    [IntegrationStatus.Expired]: '#E7004C',
  } as Record<IntegrationStatus, string>;

  const isExpired = project.status === IntegrationStatus.Expired;
  const isIntegrator = project.user_name === user?.name;

  const getText = useCallback(() => {
    if (isExpired) {
      return isIntegrator ? 'Re-Authenticate' : 'Contact Integrator';
    }

    return 'Refresh';
  }, [isExpired, isIntegrator]);

  const onClick = useCallback(() => {
    if (isExpired && isIntegrator) {
      setIntegrationForReauth(project.id);
      return authenticate();
    }

    return refresh(project.id);
  }, [isExpired, isIntegrator, authenticate, refresh, project.id]);

  return (
    <StyledButton
      variant={project.status === IntegrationStatus.Operational ? 'outline' : 'filled'}
      color={buttonColors[project.status]}
      disabled={project.status === IntegrationStatus.Expired && project.user_name !== user?.name}
      onClick={onClick}
      style={{ fontSize: isExpired && !isIntegrator ? 12 : 14, width: 145, padding: 0 }}
    >
      {getText()}
    </StyledButton>
  );
};

/**
 * Sorts the given array of integration entries based on the specified sorting criteria.
 *
 * @param {IntegrationEntry[]} integrations - The array of integration entries to be sorted.
 * @param {Object} options - The sorting options.
 * @param {string} options.sortBy - The column to sort by ('project', 'integrator', 'status', 'action', 'last_refresh', 'refresh_duration').
 * @param {boolean} options.sortDesc - Indicates whether to sort in descending order.
 * @returns {IntegrationProject[]} - The sorted array of integration projects.
 */
const sortProjects = (
  projects: IntegrationProject[],
  { sortBy, sortDesc }: { sortBy: SortedColumn; sortDesc: boolean }
): IntegrationProject[] => {
  return (projects || []).toSorted((a, b) => {
    if (sortBy === 'project') {
      return sortDesc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
    }

    if (sortBy === 'integrator') {
      return sortDesc ? b.user_name.localeCompare(a.user_name) : a.user_name.localeCompare(b.user_name);
    }

    if (['status', 'action'].includes(sortBy)) {
      const statusOrder = {
        [IntegrationStatus.Operational]: 0,
        [IntegrationStatus.Stale]: 1,
        [IntegrationStatus.Expired]: 2,
      };

      const aStatusOrder = statusOrder[a.status];
      const bStatusOrder = statusOrder[b.status];

      return sortDesc ? bStatusOrder - aStatusOrder : aStatusOrder - bStatusOrder;
    }

    if (sortBy === 'last_refresh') {
      const aValue = dayjs(a.last_completed_at);
      const bValue = dayjs(b.last_completed_at);

      return sortDesc ? bValue.diff(aValue) : aValue.diff(bValue);
    }

    if (sortBy === 'refresh_duration') {
      const aValue = dayjs(a.last_run_time, 'HH:mm:ss');
      const bValue = dayjs(b.last_run_time, 'HH:mm:ss');

      return sortDesc ? bValue.diff(aValue) : aValue.diff(bValue);
    }

    return 0;
  });
};

const StyledTable = styled(Table)`
  border: none;

  thead > tr {
    height: 21px;
    align-self: stretch;
    border: none;
  }

  thead > tr > th {
    border: none;
    padding: 0;
  }

  thead > tr > th > div {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: ${newCOLORS.darkGray};
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .mantine-Tooltip-tooltip {
    display: flex;
    padding: 16px;
    max-width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    text-transform: none;
    text-align: left;
    white-space: normal;
  }

  tbody {
    border-bottom: 1px solid #dee2e6;
  }

  tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
    color: ${newCOLORS.darkGray};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  tbody > tr > td > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const StyledButton = styled(Button)`
  height: 34px;
  font-family: Figtree;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const NoIntegrationsLabel = styled.div`
  color: #595959;
  text-align: center;
  font-family: Figtree;
  font-size: 19px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  padding: 16px 0;
  border-bottom: 1px solid #c4c4c4;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
  cursor: pointer;
`;

const RefreshText = styled.div`
  width: 145px;
  color: ${newCOLORS.indigo};
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
