import { Flex } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import { StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { H2 } from '../../ui-library/typography/typography';

type Props = {
  nullExperience: boolean;
};

export const FinancialsHeader = ({ nullExperience }: Props) => {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const navigate = useNavigate();

  if (nullExperience) {
    return (
      <Flex gap={16}>
        <H2>Financials</H2>
      </Flex>
    );
  }

  return (
    <Flex gap={16}>
      <H2>Financials</H2>
      <StyledButton
        size={'medium'}
        type={'primary'}
        firstColor={newCOLORS.indigo}
        secondColor={newCOLORS.white}
        disable={false}
        onClick={() => {
          if (!portfolio?.id) {
            return;
          }

          navigate(`/application/financials/portfolio/${portfolio.id}/add-edit-financials`, {
            state: { from: 'application' },
          });
        }}
      >
        Edit financial data
      </StyledButton>
    </Flex>
  );
};
