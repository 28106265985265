import { styled } from '@linaria/react';
import { useNavigate } from 'react-router-dom';
import financials from '../../assets/images/financials.svg';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import { SmallerHeading, StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';

export const AddFinancialData = () => {
  const navigate = useNavigate();
  const portfolio = useGlobalStore((state) => state.portfolio);

  return (
    <AddFinancialDataContainer>
      <AddFinancialDataImageContainer>
        <img alt="Add Financials Icon" src={financials} width={125} height={75} />
        <SmallerHeading style={{ textAlign: 'center', fontWeight: 600, margin: 8 }}>
          Bridge the gap between software development and the financial side of the organization by understanding work
          being done in dollars.
        </SmallerHeading>
        <StyledButton
          size={'medium'}
          type={'primary'}
          firstColor={newCOLORS.indigo}
          secondColor={newCOLORS.white}
          disable={false}
          onClick={() => {
            if (!portfolio) {
              return;
            }

            navigate(`/application/financials/portfolio/${portfolio.id}/add-edit-financials`, {
              state: { from: 'application' },
            });
          }}
          style={{ margin: 8 }}
        >
          Add financial data
        </StyledButton>
      </AddFinancialDataImageContainer>
    </AddFinancialDataContainer>
  );
};

const AddFinancialDataContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 120px;
`;

const AddFinancialDataImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 760px;
  padding: 16px;
  background-color: ${newCOLORS.white};
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
`;
