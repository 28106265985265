import { formatDate } from '../../helpers';
import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { MinifiedTask } from '../tasks-client/task-client.type';
import {
  AllocationOfCostsChartResponse,
  AllocationOfCostsChartResponseByCategory,
  CostByInitiativeResponse,
  CostByInitiativeV2Response,
  CostVsBudgetChartResponse,
  ProjectSpending,
  Spending,
  SpendingBulkDeleteRequest,
  SpendingBulkPostRequest,
} from './financials-client.type';
const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;
const COST_BY_INITIATIVE_V2 = import.meta.env.VITE_FEATURE_FLAG_COST_BY_INITIATIVE_V2 === 'true';

/**
 * Method to fetch the spending data for a portfolio
 * @param portfolioId portfolio id
 * @param years years for which the spending data is to be fetched
 * @returns spending data for the portfolio
 */
async function getSpending(portfolioId: string, years?: string): Promise<Spending[]> {
  let apiURL = baseURL + `/api/financials/spending/?portfolio_id=${portfolioId}`;
  if (years !== undefined && years !== null && years !== '') {
    apiURL += `&years=${years}`;
  }
  return await axiosInstance
    .get<Spending[]>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Method for upserting the spending data for a portfolio
 * @param spending list of spending data to be upserted
 * @returns updated spending data
 */
async function bulkUpsertSpending(spending: Spending[]): Promise<Spending[]> {
  const apiURL = baseURL + `/api/financials/spending/`;
  const postData: SpendingBulkPostRequest | { [key: string]: string | number | ProjectSpending } = {};
  postData.projects = {};
  for (const item of spending) {
    postData.portfolio_id = item.portfolio_id;
    postData.year = item.year;
    postData.projects[item.project_id] = postData.projects[item.project_id] || [];
    postData.projects[item.project_id].push({
      month: item.month,
      budget: item.budget,
    });
  }
  return await axiosInstance
    .post(apiURL, postData as SpendingBulkPostRequest)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Method to delete the spending data
 * @param portfolio_id portfolio id
 * @param project_id project id
 * @param year year
 * @returns deleted spending data for reference
 */
async function deleteSpendingByParams(portfolio_id: string, project_id: string, year: number): Promise<Spending[]> {
  const apiURL = baseURL + `/api/financials/spending/delete-by-params/`;
  const data: SpendingBulkDeleteRequest = {
    portfolio_id,
    project_id,
    year,
  };

  return await axiosInstance
    .delete(apiURL, { data })
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Method to fetch data for cost vs output chart
 * @param portfolioId portfolio id
 * @param startDate start date
 * @param endDate end date
 * @param projectId project id
 * @returns an object of cost vs output data
 */
async function getCostVsOutputChartData(
  startDate: Date,
  endDate: Date,
  portfolioId?: string | null,
  projectId?: string
): Promise<CostVsBudgetChartResponse> {
  const start_date = formatDate(startDate);
  const end_date = formatDate(endDate);
  let apiURL =
    baseURL +
    `/api/work-periods/measure/?measure_name=cost_vs_output&portfolio_id=${portfolioId}&start_date=${start_date}&end_date=${end_date}&time_allocation_type=monthly`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }
  return await axiosInstance
    .get<CostVsBudgetChartResponse>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch data for allocation of costs chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param projectId project id if any selected
 * @returns dictionary of spending data
 */
async function getAllocationOfCostsChartData(
  portfolioId: string | null,
  startDate: Date,
  endDate: Date,
  projectId?: string | null
): Promise<AllocationOfCostsChartResponseByCategory> {
  const start_date = formatDate(startDate);
  const end_date = formatDate(endDate);
  const params = new URLSearchParams({
    measure_name: 'cost_allocation',
    start_date: start_date,
    end_date: end_date,
    time_allocation_type: 'monthly',
  });

  // Only add portfolio_id if projectId is not provided
  if (projectId) {
    params.append('project_id', projectId);
  } else if (portfolioId) {
    params.append('portfolio_id', portfolioId);
  }

  const apiURL = `${baseURL}/api/work-periods/measure/?${params.toString()}`;
  return await axiosInstance
    .get<AllocationOfCostsChartResponse>(apiURL)
    .then((response) => response.data.cost_allocation)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch data for cost by initiative chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param category selected category to show the spending for
 * @param projectId (OPTIONAL) project id if any selected
 * @returns dictionary of spending data
 */
async function getCostByInitiativeChartData(
  portfolioId: string,
  startDate: Date,
  endDate: Date,
  projectId?: string
): Promise<CostByInitiativeResponse[]> {
  const start_date = formatDate(startDate);
  const end_date = formatDate(endDate);

  const versionURL = COST_BY_INITIATIVE_V2
    ? `/api/work-periods/measure/?measure_name=cost_by_initiative&portfolio_id=${portfolioId}&start_date=${start_date}&end_date=${end_date}&time_allocation_type=monthly`
    : `/api/financials/spending/cost-by-initiative/?portfolio_id=${portfolioId}&start_date=${start_date}&end_date=${end_date}`;
  let apiURL = baseURL + versionURL;
  if (projectId) {
    apiURL += `&project_id=${projectId}`;
  }
  if (COST_BY_INITIATIVE_V2) {
    return await axiosInstance
      .get<CostByInitiativeV2Response>(apiURL)
      .then((response) => response.data.cost_by_initiative)
      .catch((error) => Promise.reject({ error: error.response }));
  } else {
    return await axiosInstance
      .get<CostByInitiativeResponse[]>(apiURL)
      .then((response) => response.data)
      .catch((error) => Promise.reject({ error: error.response }));
  }
}

/**
 * Endpoint to fetch task details for allocation of costs chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param date selected date to filter the tasks
 * @param factor selected factor to filter the tasks
 * @param factorOption selected factor option to filter the tasks
 * @param projectId (OPTIONAL) project id if any selected
 * @returns list of task details
 */
async function getAllocationOfCostsTaskDetails(
  portfolioId: string,
  startDate: string,
  endDate: string,
  date: string,
  factor: string,
  factorOption: string,
  projectId?: string
): Promise<MinifiedTask[]> {
  let apiURL =
    baseURL +
    `/api/work-periods/tasks/?measure_name=cost_allocation&portfolio_id=${portfolioId}&start_date=${startDate}&end_date=${endDate}&measure_filter=${date},${factor},${factorOption}&time_allocation_type=monthly`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }

  return await axiosInstance
    .get<MinifiedTask[]>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch task details for cost vs output chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param date selected date to filter the tasks
 * @param factor selected factor to filter the tasks
 * @param projectId (OPTIONAL) project id if any selected
 * @returns list of task details
 */
async function getCostVsOutputTaskDetails(
  portfolioId: string,
  startDate: string,
  endDate: string,
  date: string,
  factor: string,
  projectId?: string
): Promise<MinifiedTask[]> {
  let apiURL =
    baseURL +
    `/api/work-periods/tasks/?measure_name=cost_vs_output&portfolio_id=${portfolioId}&start_date=${startDate}&end_date=${endDate}&measure_filter=${date},${factor}&time_allocation_type=monthly`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }

  return await axiosInstance
    .get<MinifiedTask[]>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

/**
 * Endpoint to fetch task details for cost by initiative chart
 * @param portfolioId selected portfolio id
 * @param startDate selected start date
 * @param endDate selected end date
 * @param factor selected factor to filter the tasks (Initiative ID)
 * @param projectId (OPTIONAL) project id if any selected
 * @returns list of task details
 */

async function getCostByInitiativeTaskDetails(
  portfolioId: string,
  startDate: string,
  endDate: string,
  factor: string,
  projectId?: string
): Promise<MinifiedTask[]> {
  let apiURL =
    baseURL +
    `/api/work-periods/tasks/?measure_name=cost_by_initiative&portfolio_id=${portfolioId}&start_date=${startDate}&end_date=${endDate}&measure_filter=${factor}&time_allocation_type=monthly`;
  if (projectId !== undefined) {
    apiURL += `&project_id=${projectId}`;
  }

  return await axiosInstance
    .get<MinifiedTask[]>(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject({ error: error.response }));
}

export {
  bulkUpsertSpending,
  deleteSpendingByParams,
  getAllocationOfCostsChartData,
  getAllocationOfCostsTaskDetails,
  getCostByInitiativeChartData,
  getCostByInitiativeTaskDetails,
  getCostVsOutputChartData,
  getCostVsOutputTaskDetails,
  getSpending,
};
