import { ChatHistory } from '../../containers/insights/insights.type';

type GroupedHistories = {
  label: string;
  histories: ChatHistory[];
};

/**
 * Groups chat histories by date and returns them with descriptive labels.
 *
 * @param histories - Array of chat histories to be grouped
 * @returns Array of grouped histories with labels like "Today", "Yesterday", "X days ago", etc.
 */
const groupHistoriesByDate = (histories: ChatHistory[]): GroupedHistories[] => {
  const now = new Date();
  const groups: GroupedHistories[] = [];

  const sortedHistories = [...histories].sort(
    (a, b) => new Date(b.last_accessed).getTime() - new Date(a.last_accessed).getTime()
  );

  sortedHistories.forEach((history) => {
    const date = new Date(history.last_accessed + 'Z'); // Append Z to parse as UTC
    const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
    const label = getLabelForDateDifference(diffInDays, date);

    const existingGroup = groups.find((g) => g.label === label);
    if (existingGroup) {
      existingGroup.histories.push(history);
    } else {
      groups.push({ label, histories: [history] });
    }
  });

  return groups;
};

/**
 * Gets a human-readable label describing how long ago a date was.
 *
 * @param daysDiff - Number of days between the date and now
 * @param date - The date to get the label for
 * @returns A string label like "Today", "Yesterday", "X days ago", "Last week", etc.
 */
const getLabelForDateDifference = (daysDiff: number, date: Date): string => {
  if (daysDiff === 0) {
    return 'Today';
  } else if (daysDiff === 1) {
    return 'Yesterday';
  } else if (daysDiff < 7) {
    return `${daysDiff} days ago`;
  } else if (daysDiff < 30) {
    const weeksDiff = Math.floor(daysDiff / 7);
    return weeksDiff === 1 ? 'Last week' : `${weeksDiff} weeks ago`;
  } else if (daysDiff < 365) {
    const monthsDiff = Math.floor(daysDiff / 30);
    return monthsDiff === 1 ? 'Last month' : `${monthsDiff} months ago`;
  } else {
    return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  }
};

export { getLabelForDateDifference, groupHistoriesByDate };
