import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchSprint, fetchSprintBurnsAndFlows } from '../../api/sprints-client/sprints-client';
import { Sprint, SprintBurnsAndFlows } from '../../api/sprints-client/sprints-client.type';
import { MinifiedTask } from '../../api/tasks-client/task-client.type';
import { fetchTasks } from '../../api/tasks-client/tasks-client';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { newCOLORS } from '../../styles/colors';
import { TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { SidebarContext } from '../side-bar/side-bar.context';
import { TaskList } from './task-list';
import { sortTasks } from './task-list-header';
import { makeTitle } from './tasks.helpers';

export const Tasks = () => {
  const { sprintId } = useParams<{ sprintId: string }>();
  const [searchParams] = useSearchParams();
  const { viewAreaWidth } = useContext(SidebarContext);
  const [sprint, setSprint] = useState<Sprint | null>(null);
  const [sprintBurnsAndFlows, setSprintBurnsAndFlows] = useState<SprintBurnsAndFlows | null>(null);
  const [tasks, setTasks] = useState<MinifiedTask[]>([]);
  const day = searchParams.get('day');
  const filter = searchParams.get('filter');

  useQuery(
    ['sprint', sprintId],
    () => (sprintId ? fetchSprint(sprintId) : Promise.reject('Unable to resolve SprintId or SprintID is missing')),
    {
      enabled: !!sprintId,
      onSuccess: (sprintQueryResponse: Sprint) => {
        setSprint(sprintQueryResponse as Sprint);
      },
      onError: () => console.error('There was an issue fetching sprint data.'),
    }
  );

  useQuery(
    ['sprintBurnsAndFlows', sprintId],
    () => (sprintId ? fetchSprintBurnsAndFlows(sprintId) : Promise.reject('SprintId is required')),
    {
      enabled: !!sprintId,
      onSuccess: (data: SprintBurnsAndFlows) => {
        setSprintBurnsAndFlows(data);
      },
      onError: () => console.error('There was an issue fetching sprint burns and flows.'),
    }
  );

  // fetchTasks
  const tasksQuery = useQuery(
    ['tasks', sprintId],
    () =>
      sprintId && day && filter
        ? fetchTasks({ sprintId, filter, day })
        : Promise.reject('Unable to resolve SprintId or SprintID is missing'),
    {
      enabled: !!sprintId,
      onSuccess: (taskQueryResponse: MinifiedTask[]) => {
        setTasks(sortTasks('default', taskQueryResponse));
      },
      onError: () => console.error('There was an issue fetching tasks.'),
    }
  );

  return (
    <MenuDrivenContainer>
      <BrandedLoadingOverlay visible={tasksQuery.isLoading} transitionDuration={30} variant="colored" />
      <TasksContainer>
        <HeaderContainer viewAreaWidth={viewAreaWidth}>
          <TitleHeadingTag>{makeTitle(sprint, day, sprintBurnsAndFlows, filter)}</TitleHeadingTag>
        </HeaderContainer>
        <RelatedTasksContainer>
          <TaskList tasks={tasks} setTasks={setTasks} />
        </RelatedTasksContainer>
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const HeaderContainer = styled.div<{ viewAreaWidth: number }>`
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 1200 ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
`;

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RelatedTasksContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  margin-top: 16px;
  border-radius: 4px;
  background: ${newCOLORS.white};
  box-shadow: 0px 2px 3px 0px ${newCOLORS.lightGray};
`;
